"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const consts_1 = require("~/store/account/consts");
function canUserModifyProject(user, project) {
    var _a;
    const userType = (_a = user.userType) === null || _a === void 0 ? void 0 : _a.name;
    const userDistributorId = user.distributorId;
    if (project.user_id === user.id || userType === consts_1.UserRoles.admin) {
        return true;
    }
    if (userType === consts_1.UserRoles.customer) {
        return project.shared && project.customer_id === user.id;
    }
    if (userType === consts_1.UserRoles.distributor) {
        return project.distributorId === userDistributorId &&
            (project.createdByUserType === consts_1.UserRoles.distributor || project.shared === true);
    }
    return false;
}
exports.default = canUserModifyProject;
