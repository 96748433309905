"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const routes_1 = require("~/consts/routes");
const actions_1 = require("~/store/actions");
const consts_1 = require("~/store/consts");
const utils_1 = require("~/utils/utils");
function SignOut() {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const { dispatchLogout, dispatchSetCurrentTabId, dispatchToggleQuote, dispatchToggleWidget, dispatchResetConnectionFasteners, } = (0, redux_1.bindActionCreators)({
        dispatchLogout: actions_1.logout,
        dispatchSetCurrentTabId: actions_1.setCurrentTabId,
        dispatchToggleQuote: actions_1.toggleQuote,
        dispatchToggleWidget: actions_1.toggleWidget,
        dispatchResetConnectionFasteners: actions_1.resetConnectionFasteners,
    }, (0, react_redux_1.useDispatch)());
    (0, react_1.useEffect)(() => {
        // NavigateFunction can not be used inside of render function. It has to be wrapped within an useEffect or called in an event handler.
        navigate(routes_1.ROUTE_HOME, { replace: true });
        (0, utils_1.resetScene)();
        dispatchSetCurrentTabId(undefined);
        dispatchLogout();
        dispatchToggleQuote(false);
        dispatchToggleWidget({
            widgetName: consts_1.WIDGETS.BOM_WIDGET,
            show: false,
        });
        dispatchResetConnectionFasteners();
        dispatchToggleWidget({
            widgetName: consts_1.WIDGETS.DEFAULT_FASTENER_WIDGET,
            show: false,
        });
        dispatchToggleWidget({
            widgetName: consts_1.WIDGETS.CURRENT_FASTENER_WIDGET,
            show: false,
        });
    }, []);
    return react_1.default.createElement("div", null);
}
exports.default = SignOut;
