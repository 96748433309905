"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestIncludedCompatibleFastenersFail = exports.requestIncludedCompatibleFastenersSuccess = exports.requestIncludedCompatibleFasteners = exports.requestCatalogMSFail = exports.requestCatalogFail = exports.requestCatalogMSSuccess = exports.requestCatalogSuccess = exports.requestCatalog = void 0;
const typesafe_actions_1 = require("typesafe-actions");
const consts_1 = require("./consts");
exports.requestCatalog = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_CATALOG)();
exports.requestCatalogSuccess = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_CATALOG_SUCCESS)();
exports.requestCatalogMSSuccess = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_CATALOG_MS_SUCCESS)();
exports.requestCatalogFail = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_CATALOG_FAIL)();
exports.requestCatalogMSFail = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_CATALOG_MS_FAIL)();
exports.requestIncludedCompatibleFasteners = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_INCLUDED_FASTENERS)();
exports.requestIncludedCompatibleFastenersSuccess = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_INCLUDED_FASTENERS_SUCCESS)();
exports.requestIncludedCompatibleFastenersFail = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_INCLUDED_FASTENERS_FAIL)();
