"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customerUserTabs = exports.isDirectAccountUserTabs = exports.distributorUserTabs = exports.accountTabs = exports.TAB_IDS = void 0;
const assets_1 = require("~/assets");
const routes_1 = require("./routes");
exports.TAB_IDS = {
    PROFILE: 1,
    PROJECTS: 2,
    TEAM: 3,
    CUSTOMERS: 4,
    LOGOUT: 5,
};
exports.accountTabs = [
    {
        id: exports.TAB_IDS.PROFILE,
        icon: assets_1.svgQuoteProfileDetails,
        title: 'dropdown-menu.my-profile',
        handlerType: 'default',
        route: routes_1.ROUTE_PROFILE,
    },
    {
        id: exports.TAB_IDS.PROJECTS,
        icon: assets_1.svgQuoteProjectsHistoryIcon,
        title: 'dropdown-menu.projects',
        handlerType: 'default',
        route: routes_1.ROUTE_PROJECTS,
    },
    {
        id: exports.TAB_IDS.LOGOUT,
        icon: assets_1.svgSignOutIco,
        title: 'dropdown-menu.sign-out',
        handlerType: 'logout',
        route: '',
    },
];
exports.distributorUserTabs = [
    {
        id: exports.TAB_IDS.TEAM,
        icon: assets_1.svgQuoteTeamManagement,
        title: 'dropdown-menu.team',
        handlerType: 'default',
        route: routes_1.ROUTE_TEAM,
    },
    {
        id: exports.TAB_IDS.CUSTOMERS,
        icon: assets_1.svgDiscountManagement,
        title: 'dropdown-menu.customers',
        handlerType: 'default',
        route: routes_1.ROUTE_CUSTOMERS,
    },
];
exports.isDirectAccountUserTabs = [
    {
        id: exports.TAB_IDS.TEAM,
        icon: assets_1.svgQuoteTeamManagement,
        title: 'dropdown-menu.team',
        handlerType: 'default',
        route: routes_1.ROUTE_TEAM,
    },
];
exports.customerUserTabs = [];
