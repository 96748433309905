"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROJECT_STATUS = void 0;
// eslint-disable-next-line no-shadow
var PROJECT_STATUS;
(function (PROJECT_STATUS) {
    PROJECT_STATUS["IN_PROGRESS"] = "In progress";
    PROJECT_STATUS["UNDER_REVIEW"] = "Under review";
    PROJECT_STATUS["ORDER_ENTERED"] = "Order entered";
    PROJECT_STATUS["ARCHIVED"] = "Archived";
})(PROJECT_STATUS = exports.PROJECT_STATUS || (exports.PROJECT_STATUS = {}));
