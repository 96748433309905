"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTopPoint = exports.getGasketNormal = exports.getCoverPoint = exports.getIntersectedObjects = exports.placeCoverOnSlot = exports.getBasisMatrix = exports.getCoverNormal = exports.copyLeftAndRightPoints = exports.getSnappingPoint = exports.getRectanglePoints = exports.getMinPoint = void 0;
const three_1 = require("three");
// eslint-disable-next-line import/no-cycle
const utils_1 = require("../../utils");
const utils_3d_1 = require("../../utils/utils.3d");
const consts_1 = require("../../consts");
const tempVector = new three_1.Vector3();
function getMinPoint(points, direction) {
    const startPoint = new three_1.Vector3(0, 0, 0);
    return points.reduce((prev, curr) => {
        return curr.clone().projectOnVector(direction).sub(startPoint).length() <
            prev.clone().projectOnVector(direction).sub(startPoint).length()
            ? curr
            : prev;
    });
}
exports.getMinPoint = getMinPoint;
function getRectanglePoints(cover) {
    let points = null;
    const rectangles = cover.getRectangles();
    if (rectangles) {
        points = rectangles[0].points;
    }
    else if (!rectangles && cover.mesh.geometry.boundingBox) {
        const { boundingBox } = cover.mesh.geometry;
        const boxCorners = utils_1.Utils.getBox3Corners(boundingBox);
        const yCorners = boxCorners.filter((point) => point.y === boundingBox.max.y);
        cover.updateMatrixWorld();
        points = yCorners.map((point) => point.applyMatrix4(cover.matrixWorld));
    }
    else {
        throw new Error(`The component ${cover.partDetails.itemNumber} is missing required metadata.`);
    }
    return points;
}
exports.getRectanglePoints = getRectanglePoints;
function getSnappingPoint(cover) {
    const points = getRectanglePoints(cover);
    const point1 = utils_1.Utils.getPointOnMinCoord(points, cover.directionNormal);
    const point2 = utils_1.Utils.getPointOnMinCoord(points.filter((point) => {
        return !utils_1.Utils.areVectorsEqual(point, point1);
    }), cover.directionNormal);
    return new three_1.Vector3((point1.x + point2.x) / 2, (point1.y + point2.y) / 2, (point1.z + point2.z) / 2);
}
exports.getSnappingPoint = getSnappingPoint;
function copyLeftAndRightPoints(fromPoints, toPoints) {
    const leftPoint = fromPoints.filter((p) => p.bottomPos === 'L')[0];
    const rightPoint = fromPoints.filter((p) => p.bottomPos === 'R')[0];
    if (leftPoint) {
        const lPoint = toPoints.filter((p) => utils_1.Utils.areVectorsEqual(leftPoint, p))[0];
        lPoint.bottomPos = 'L';
    }
    if (rightPoint) {
        const rPoint = toPoints.filter((p) => utils_1.Utils.areVectorsEqual(rightPoint, p))[0];
        rPoint.bottomPos = 'R';
    }
}
exports.copyLeftAndRightPoints = copyLeftAndRightPoints;
function getCoverNormal(cover) {
    const points = getRectanglePoints(cover);
    let leftMinPoint = points.find((p) => p.bottomPos === 'L');
    let rightMinPoint = points.find((p) => p.bottomPos === 'R');
    let widthDirection;
    if (!leftMinPoint || !rightMinPoint) {
        const firstMinPoint = utils_1.Utils.getPointOnMinCoord(points, cover.directionNormal);
        const minPoints = [
            firstMinPoint,
            utils_1.Utils.getPointOnMinCoord(points.filter((point) => utils_1.Utils.areVectorsNotEqual(firstMinPoint, point)), cover.directionNormal),
        ];
        widthDirection = tempVector.subVectors(minPoints[0], minPoints[1]).normalize();
        const coordinateIndex = utils_1.Utils.getWorldAxisIndex(widthDirection);
        const centerCoordinate = utils_1.Utils.getAABBoxCenter(cover).getComponent(coordinateIndex);
        leftMinPoint = minPoints[0].getComponent(coordinateIndex) > centerCoordinate ? minPoints[0] : minPoints[1];
        rightMinPoint = leftMinPoint === minPoints[0] ? minPoints[1] : minPoints[0];
    }
    widthDirection = new three_1.Vector3().subVectors(leftMinPoint, rightMinPoint).normalize();
    const coverNormal = new three_1.Vector3().crossVectors(cover.directionNormal, widthDirection);
    return coverNormal.normalize();
}
exports.getCoverNormal = getCoverNormal;
function getBasisMatrix(cover) {
    const xDirection = cover.directionNormal;
    const yDirection = getCoverNormal(cover); // ?? Utils.invertVector(xDirection);
    const zDirection = new three_1.Vector3().copy(xDirection).cross(yDirection);
    return new three_1.Matrix4().makeBasis(xDirection, yDirection, zDirection);
}
exports.getBasisMatrix = getBasisMatrix;
function placeCoverOnSlot(cover, startPos, coverLength) {
    cover.setSizeZ(coverLength);
    const moveVector = startPos.clone().sub(getSnappingPoint(cover));
    cover.translate(moveVector);
}
exports.placeCoverOnSlot = placeCoverOnSlot;
function getIntersectedObjects(ray, detectItems) {
    return ray.intersectObjects(detectItems);
}
exports.getIntersectedObjects = getIntersectedObjects;
function getCoverPoint(panelPoint, direction, extrusion) {
    const extrusionEndPoint = (0, utils_3d_1.getObjectEndPoint)(extrusion, direction);
    const shift = tempVector.subVectors(extrusionEndPoint, panelPoint).projectOnVector(direction);
    if (shift.dot(direction) >= 0) {
        return panelPoint;
    }
    return panelPoint.add(shift);
}
exports.getCoverPoint = getCoverPoint;
function getGasketNormal(gasket) {
    const { points } = gasket.getRectangles()[0];
    const gasketBox = (0, utils_3d_1.getAABBox)(gasket).expandByScalar(consts_1.consts.TWO_FLOAT_EPSILON);
    const outPoints = points.filter((point) => !gasketBox.containsPoint(point));
    const normalPoints = [
        outPoints[0],
        (0, utils_3d_1.getNearestPoint)(outPoints[0], points.filter((point) => !outPoints.includes(point))),
    ];
    return new three_1.Vector3().subVectors(normalPoints[0], normalPoints[1]).normalize();
}
exports.getGasketNormal = getGasketNormal;
function getTopPoint(points, direction) {
    const coords = points.map((point) => point.dot(direction));
    const coord = Math.max(...coords);
    return points.filter((point) => point.dot(direction) === coord)[0];
}
exports.getTopPoint = getTopPoint;
