"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const isEmail = (string) => {
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types, prefer-regex-literals
    const emailRegexp = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z0-9][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i);
    return emailRegexp.test(string);
};
exports.default = isEmail;
