"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetScaleCommand = void 0;
const three_1 = require("three");
// eslint-disable-next-line import/no-cycle
const types_1 = require("~/store/types");
// eslint-disable-next-line import/no-cycle
const Command_1 = require("./Command");
// eslint-disable-next-line import/no-cycle
const events_1 = require("../../../utils/events");
// eslint-disable-next-line import/no-cycle
const utils_1 = require("../utils");
// eslint-disable-next-line import/prefer-default-export
class SetScaleCommand extends Command_1.ViewerCommand {
    constructor(object, viewer, historyService) {
        super();
        this.type = 'SetScaleCommand';
        this.name = 'Set Scale';
        this.updatable = true;
        this.objects = object;
        this.viewer = viewer;
        this.historyService = historyService;
        if (object !== undefined) {
            this.oldPosition = object.positionOnDown.clone();
            this.newPosition = object.position.clone();
            this.oldScale = object.scaleOnDown.clone();
            this.newScale = object.scale.clone();
        }
    }
    execute(appliedFromHistory) {
        this.historyService.emit(`${this.objects.type}Scaled`, this.objects);
        const baseObject = this.objects;
        baseObject.userData.noMoveJoinedComps = true;
        baseObject.setPosition(this.newPosition);
        baseObject.oldCoord = baseObject.position.clone();
        if (appliedFromHistory &&
            utils_1.Utils.areVectorsNotEqual(this.oldPosition, this.newPosition) &&
            baseObject.type === types_1.ITEM_TYPE.EXTRUSION) {
            const offset = this.newPosition.clone().sub(this.oldPosition);
            baseObject.translateComponentsBeforeScaling(offset);
        }
        delete baseObject.userData.noMoveJoinedComps;
        baseObject.setScale(this.newScale);
        baseObject.updateMatrixWorld(true);
        this.viewer.collisionProcessor.notifyObjectUpdated(baseObject);
        const connectionsPromises = this.viewer.getDesignProMain().updateConnectionsAndShowNotifications(baseObject);
        Promise.all(connectionsPromises).then((connectionObjects) => {
            var _a, _b;
            this.viewer.collisionProcessor.updateCollisionsForObjectsAndConnections([baseObject], connectionObjects.filter((connectionObject) => connectionObject !== null));
            const actionType = baseObject.type === types_1.ITEM_TYPE.PANEL ? types_1.ACTION_TYPE.SCALE2D : types_1.ACTION_TYPE.SCALE1D;
            const connections = this.viewer.getDesignProMain().connectionManager.getRelatedConnections(baseObject);
            document.body.dispatchEvent((0, events_1.updateItemsToBOMEvent)([
                {
                    actionType,
                    size: baseObject.getLength(),
                    itemNumber: (_a = baseObject.partDetails) === null || _a === void 0 ? void 0 : _a.itemNumber,
                    itemType: baseObject.type,
                    position: baseObject.position,
                    connections,
                    uuid: baseObject.uuid,
                    slicePlanes: baseObject.slicePlanes,
                    geomkernelPanelSize: baseObject.getSize(),
                    panelSize: (_b = baseObject.originalPanelSize) !== null && _b !== void 0 ? _b : baseObject.getSize(),
                    rotation: {
                        x: baseObject.quaternion.x,
                        y: baseObject.quaternion.y,
                        z: baseObject.quaternion.z,
                        w: baseObject.quaternion.w,
                    },
                },
            ]));
        });
    }
    undo() {
        this.historyService.emit(`${this.objects.type}Scaled`, this.objects);
        const baseObject = this.objects;
        baseObject.userData.noMoveJoinedComps = true;
        baseObject.setPosition(this.oldPosition);
        if (utils_1.Utils.areVectorsNotEqual(this.oldPosition, this.newPosition) && baseObject.type === types_1.ITEM_TYPE.EXTRUSION) {
            const offset = this.oldPosition.clone().sub(this.newPosition);
            baseObject.translateComponentsBeforeScaling(offset);
        }
        delete baseObject.userData.noMoveJoinedComps;
        baseObject.setScale(this.oldScale);
        baseObject.updateMatrixWorld(true);
        this.viewer.collisionProcessor.notifyObjectUpdated(baseObject);
        const connectionsPromises = this.viewer.getDesignProMain().updateConnectionsAndShowNotifications(baseObject);
        Promise.all(connectionsPromises).then((connectionObjects) => {
            var _a;
            this.viewer.collisionProcessor.updateCollisionsForObjectsAndConnections([baseObject], connectionObjects.filter((connectionObject) => connectionObject !== null));
            const actionType = baseObject.type === types_1.ITEM_TYPE.PANEL ? types_1.ACTION_TYPE.SCALE2D : types_1.ACTION_TYPE.SCALE1D;
            const connections = this.viewer.getDesignProMain().connectionManager.getRelatedConnections(baseObject);
            document.body.dispatchEvent((0, events_1.updateItemsToBOMEvent)([
                {
                    actionType,
                    size: baseObject.getLength(),
                    itemNumber: (_a = baseObject.partDetails) === null || _a === void 0 ? void 0 : _a.itemNumber,
                    itemType: baseObject.type,
                    position: baseObject.position,
                    connections,
                    uuid: baseObject.uuid,
                    slicePlanes: baseObject.slicePlanes,
                    panelSize: baseObject.getSize(),
                    rotation: {
                        x: baseObject.quaternion.x,
                        y: baseObject.quaternion.y,
                        z: baseObject.quaternion.z,
                        w: baseObject.quaternion.w,
                    },
                },
            ]));
        });
    }
    update(command) {
        this.newPosition.copy(command.newPosition);
        this.newScale.copy(command.newScale);
    }
    toJSON() {
        return {
            type: this.type,
            id: this.id,
            name: this.name,
            objectUuid: this.objects.uuid,
            oldPosition: this.oldPosition.toArray(),
            newPosition: this.newPosition.toArray(),
            oldScale: this.oldScale.toArray(),
            newScale: this.newScale.toArray(),
        };
    }
    fromJSON(json) {
        this.getFromJSON(json);
        this.objects = this.viewer.getObjectById(json.objectUuid);
        this.oldPosition = new three_1.Vector3().fromArray(json.oldPosition);
        this.newPosition = new three_1.Vector3().fromArray(json.newPosition);
        this.oldScale = new three_1.Vector3().fromArray(json.oldScale);
        this.newScale = new three_1.Vector3().fromArray(json.newScale);
    }
}
exports.SetScaleCommand = SetScaleCommand;
