"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable @typescript-eslint/no-non-null-assertion */
const effects_1 = require("redux-saga/effects");
const events_1 = require("~/utils/events");
const utils_1 = require("~/utils/utils");
const actions_1 = require("../actions");
const api_1 = require("../api");
const consts_1 = require("./consts");
function* tryChangeUserPassword({ payload }) {
    var _a, _b;
    const accessToken = yield (0, effects_1.call)(utils_1.getToken);
    try {
        const token = (_a = payload.token) !== null && _a !== void 0 ? _a : accessToken;
        const response = yield (0, effects_1.call)(api_1.changeUserPassword, payload.password, token, payload.oldPassword);
        yield (0, effects_1.put)((0, actions_1.setActionStatus)(response.action));
        if (((_b = response.action) === null || _b === void 0 ? void 0 : _b.status) === 'success') {
            yield (0, effects_1.put)((0, actions_1.handleAuthResponse)(response.data));
            yield (0, effects_1.put)((0, actions_1.loginSuccess)());
            yield (0, effects_1.call)(events_1.showNotification, {
                message: 'Password was changed successfully',
                type: 'success',
            });
        }
    }
    catch (error) {
        yield (0, effects_1.put)((0, actions_1.handleAuthResponse)({ isUserLogged: false }));
        yield (0, effects_1.call)(events_1.showNotification, {
            message: 'Your session has expired. Please login to continue',
            type: 'error',
        });
    }
}
function* tryChangeUserEmail({ payload }) {
    try {
        const response = yield (0, effects_1.call)(api_1.changeUserEmail, payload);
        yield (0, effects_1.put)((0, actions_1.handleAuthResponse)(response.authData));
        if (response.authData.isUserLogged) {
            yield (0, effects_1.put)((0, actions_1.loginSuccess)());
            yield (0, effects_1.call)(events_1.showNotification, {
                message: `New email has been confirmed`,
                type: 'success',
            });
        }
        else {
            yield (0, effects_1.call)(events_1.showNotification, {
                message: response.message,
                type: 'error',
            });
        }
    }
    catch (error) {
        yield (0, effects_1.call)(events_1.showNotification, {
            message: 'Your session has expired. Please login to continue',
            type: 'error',
        });
    }
}
function* rootSaga() {
    yield (0, effects_1.all)([(0, effects_1.takeLatest)(consts_1.CHANGE_PASSWORD, tryChangeUserPassword)]);
    yield (0, effects_1.all)([(0, effects_1.takeLatest)(consts_1.CHANGE_EMAIL, tryChangeUserEmail)]);
}
exports.default = rootSaga;
