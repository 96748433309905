"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.widgetDefaultState = exports.widgetMinHeight = exports.widgetMinWidth = void 0;
exports.widgetMinWidth = 336;
exports.widgetMinHeight = 307;
exports.widgetDefaultState = {
    height: exports.widgetMinHeight,
    width: exports.widgetMinWidth,
    x: 0,
    y: 0,
};
