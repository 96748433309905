"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserDiscounts = exports.resendInvitationByUserEmail = exports.sendInvitationsByUsersEmail = exports.transferOwnershipUserId = exports.deleteUserByUserId = exports.fetchDistributorCustomers = exports.updateDistributorInfo = exports.updateUserInfo = exports.getUserData = void 0;
const axiosInstance_1 = require("~/store/api/axiosInstance");
const getUserData = () => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance.get(`/users/info`).then(({ data }) => data);
});
exports.getUserData = getUserData;
const updateUserInfo = (userInfo) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .put(`/users/info`, { userInfo })
        .then(({ data }) => data)
        .catch((error) => error.response.data);
});
exports.updateUserInfo = updateUserInfo;
const updateDistributorInfo = (distributorInfo) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .put(`/users/distributor/info`, { distributorInfo })
        .then(({ data }) => data)
        .catch((error) => error.response.data);
});
exports.updateDistributorInfo = updateDistributorInfo;
const fetchDistributorCustomers = (distributorId) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance.get(`/users/distributor/${distributorId}/customers`).then(({ data }) => {
        return data.data;
    });
});
exports.fetchDistributorCustomers = fetchDistributorCustomers;
const deleteUserByUserId = (userId, token, refreshToken) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .delete(`/users/${userId}`, {
        headers: { 'x-access-token': token, 'x-refresh-token': refreshToken },
    })
        .then(({ data }) => data)
        .catch((error) => {
        return error.response.data;
    });
});
exports.deleteUserByUserId = deleteUserByUserId;
const transferOwnershipUserId = (userId, token, refreshToken) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .put(`/users/${userId}/role`, {}, {
        headers: { 'x-access-token': token, 'x-refresh-token': refreshToken },
    })
        .then(({ data }) => data)
        .catch((error) => {
        return error.response.data;
    });
});
exports.transferOwnershipUserId = transferOwnershipUserId;
const sendInvitationsByUsersEmail = (emails, distributorId, token, refreshToken) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .put(`/users/distributor/${distributorId}/invite`, { emails }, {
        headers: { 'x-access-token': token, 'x-refresh-token': refreshToken },
    })
        .then(({ data }) => data)
        .catch((error) => {
        return error.response.data;
    });
});
exports.sendInvitationsByUsersEmail = sendInvitationsByUsersEmail;
const resendInvitationByUserEmail = (emails, distributorId, token, refreshToken) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .put(`/users/distributor/${distributorId}/invite/resend`, { emails }, {
        headers: { 'x-access-token': token, 'x-refresh-token': refreshToken },
    })
        .then(({ data }) => data)
        .catch((error) => {
        return error.response.data;
    });
});
exports.resendInvitationByUserEmail = resendInvitationByUserEmail;
const getUserDiscounts = () => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .get(`/users/discounts`)
        .then(({ data }) => data)
        .catch((error) => {
        return error.response.data;
    });
});
exports.getUserDiscounts = getUserDiscounts;
