"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INPUT_ERRORS = exports.QUOTE_FIELDS = void 0;
// eslint-disable-next-line no-shadow
var QUOTE_FIELDS;
(function (QUOTE_FIELDS) {
    QUOTE_FIELDS["projectName"] = "projectName";
    QUOTE_FIELDS["firstName"] = "firstName";
    QUOTE_FIELDS["lastName"] = "lastName";
    QUOTE_FIELDS["companyName"] = "companyName";
    QUOTE_FIELDS["email"] = "email";
    QUOTE_FIELDS["phoneNumber"] = "phoneNumber";
    QUOTE_FIELDS["isDistributor"] = "isDistributor";
    QUOTE_FIELDS["distributorId"] = "distributorId";
    QUOTE_FIELDS["addressLine"] = "addressLine";
    QUOTE_FIELDS["addressLine2"] = "addressLine2";
    QUOTE_FIELDS["city"] = "city";
    QUOTE_FIELDS["state"] = "state";
    QUOTE_FIELDS["zip"] = "zip";
    QUOTE_FIELDS["countryId"] = "countryId";
    QUOTE_FIELDS["comment"] = "comment";
})(QUOTE_FIELDS = exports.QUOTE_FIELDS || (exports.QUOTE_FIELDS = {}));
// eslint-disable-next-line no-shadow
var INPUT_ERRORS;
(function (INPUT_ERRORS) {
    INPUT_ERRORS["required"] = "required";
    INPUT_ERRORS["invalid"] = "invalid";
    INPUT_ERRORS["none"] = "none";
})(INPUT_ERRORS = exports.INPUT_ERRORS || (exports.INPUT_ERRORS = {}));
