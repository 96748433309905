"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CUSTOMER_REQUEST_SUCCESS = exports.SET_CUSTOMER_DATA = exports.SET_CUSTOMER_EXIST = exports.GET_CUSTOMER = exports.UPDATE_CUSTOMER = exports.ADD_CUSTOMER = exports.INVITE_CUSTOMER = void 0;
exports.INVITE_CUSTOMER = 'INVITE_CUSTOMER';
exports.ADD_CUSTOMER = 'ADD_CUSTOMER';
exports.UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
exports.GET_CUSTOMER = 'GET_CUSTOMER';
exports.SET_CUSTOMER_EXIST = 'SET_CUSTOMER_EXIST';
exports.SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';
exports.CUSTOMER_REQUEST_SUCCESS = 'CUSTOMER_REQUEST_SUCCESS';
