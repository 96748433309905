"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line import/no-cycle
const events_1 = require("~/utils/events");
const consts_1 = require("~/store/consts");
const _1 = require(".");
const utils_1 = require("../utils");
class SelectionToolHelper {
    constructor(viewer, designProMain) {
        this.selectedObjects = [];
        this.viewer = viewer;
        this.designProMain = designProMain;
    }
    get selectedObjectsUuids() {
        return this.selectedObjects.map((so) => so.uuid);
    }
    addToSelection(object) {
        const index = this.selectedObjects.findIndex((obj) => obj.uuid === object.uuid);
        if (index === -1) {
            object.select();
            this.selectedObjects.push(object);
        }
    }
    removeFromSelection(object) {
        const index = this.selectedObjects.indexOf(object);
        if (index > -1) {
            object.unselect();
            this.selectedObjects.splice(index, 1);
        }
    }
    clearSelection() {
        this.selectedObjects.forEach((object) => {
            object.unselect();
        });
        this.selectedObjects = [];
    }
    selectObjects(objects) {
        objects.forEach((object) => {
            const index = this.selectedObjects.findIndex((obj) => obj.uuid === object.uuid);
            if (index > -1) {
                this.removeFromSelection(object);
            }
            else {
                this.addToSelection(object);
            }
        });
        document.body.dispatchEvent((0, events_1.selectedObjectsChangedEvent)(this.selectedObjectsUuids));
        if (this.selectedObjects.length >= 1) {
            this.activateTransformModes();
        }
        else if (!this.designProMain.transformControlsInitial.dragging) {
            this.designProMain.removeTransformControlsFromScene();
        }
    }
    unselectObject(object) {
        this.removeFromSelection(object);
        document.body.dispatchEvent((0, events_1.selectedObjectsChangedEvent)(this.selectedObjectsUuids));
        if (this.selectedObjects.length >= 1) {
            this.designProMain.transformControlsInitial.visible = true;
            this.activateTransformModes();
        }
        else if (!this.designProMain.transformControlsInitial.dragging) {
            this.designProMain.removeTransformControlsFromScene();
        }
    }
    selectAll() {
        const sceneObjects = this.viewer
            .getScene()
            .children.filter((child) => {
            var _a;
            return child instanceof _1.BaseObject3D &&
                child.isCustomObject &&
                child.uuid !== ((_a = this.designProMain.scalingTool.getObject()) === null || _a === void 0 ? void 0 : _a.uuid) &&
                !this.selectedObjects.includes(child);
        });
        if (sceneObjects.length === 0) {
            return;
        }
        this.selectObjects(sceneObjects);
    }
    unselectAll() {
        this.clearSelection();
        document.body.dispatchEvent((0, events_1.selectedObjectsChangedEvent)([]));
        this.designProMain.removeTransformControlsFromScene();
    }
    getMasterPart() {
        const selectedObjects = this.selectedObjects.filter((item) => item instanceof _1.BaseObject3D && item.isCustomObject);
        const masterPart = selectedObjects.find((item) => item.partDetails.itemType === 'Extrusion' && item.scalingAngle === 0);
        return masterPart || selectedObjects[0];
    }
    activateTransformModes() {
        this.viewer.props.setSelectedTransformTool(consts_1.TRANSFORM_MODE.TRANSLATE);
        this.designProMain.dimensioningTool.displayDistances(this.selectedObjects.length > 0 &&
            utils_1.ConnectionUtils.isObjectSnapped(this.selectedObjects[0], this.viewer));
    }
    getIsScalableRotatableMovableParams() {
        let isScalable = true;
        let isRotatable = true;
        let isMovable = true;
        if (!this.selectedObjects || !this.selectedObjects.length) {
            return [isScalable, isRotatable, isMovable];
        }
        const selectedObjects = this.selectedObjects;
        const transformChildObjects = selectedObjects.map((object) => object.getTransformChildObjects()).flat();
        selectedObjects.forEach((selectedObject) => {
            if (transformChildObjects.find((childObject) => childObject.uuid === selectedObject.uuid)) {
                return;
            }
            if (!selectedObject.isRotatable) {
                isRotatable = false;
            }
            if (!selectedObject.isMovable) {
                isMovable = false;
            }
        });
        isScalable = selectedObjects.length === 1 && selectedObjects[0].isScalable;
        return [isScalable, isRotatable, isMovable];
    }
}
exports.default = SelectionToolHelper;
