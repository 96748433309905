"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewerCommand = void 0;
class ViewerCommand {
    constructor() {
        this.id = -1;
        this.inMemory = false;
        this.updatable = false;
        this.type = '';
        this.name = '';
    }
    getFromJSON(json) {
        this.inMemory = true;
        this.type = json.type;
        this.id = json.id;
        this.name = json.name;
    }
}
exports.ViewerCommand = ViewerCommand;
