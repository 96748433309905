"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectLengthField = exports.selectHeightField = exports.selectWidthField = exports.selectPanelField = exports.selectLatchField = exports.selectHingesField = exports.selectHandleField = exports.selectExtrusionField = exports.selectProfileTypeField = exports.selectSeriesField = exports.selectSeriesTypeField = exports.selectFormFields = exports.selectIsLoadedWidget = exports.selectFormState = exports.selectVisibleFrame = void 0;
const reselect_1 = require("reselect");
const types_1 = require("~/store/types");
const lodash_1 = require("lodash");
const consts_1 = require("./consts");
const types_2 = require("./types");
exports.selectVisibleFrame = (0, reselect_1.createSelector)((state) => state.preconfiguredFrames, (preconfiguredItemState) => preconfiguredItemState.visibleFrame);
exports.selectFormState = (0, reselect_1.createSelector)((state) => state.preconfiguredFrames, (preconfiguredItemState) => {
    if (preconfiguredItemState.visibleFrame === types_2.IVisibleFrameType.DEFAULT) {
        return {};
    }
    const stateKey = consts_1.STATE_KEY_BY_VISIBLE_FRAME[preconfiguredItemState.visibleFrame];
    return preconfiguredItemState[stateKey];
});
exports.selectIsLoadedWidget = (0, reselect_1.createSelector)((state) => state.preconfiguredFrames, (preconfiguredItemState) => preconfiguredItemState.isLoaded);
exports.selectFormFields = (0, reselect_1.createSelector)((state) => ({
    visibleFrame: state.preconfiguredFrames.visibleFrame,
}), ({ visibleFrame }) => {
    return consts_1.FIELD_DEFINITIONS_BY_VISIBLE_FRAME[visibleFrame];
});
exports.selectSeriesTypeField = (0, reselect_1.createSelector)((state) => ({
    preconfiguredFrames: state.preconfiguredFrames,
    catalogMeta: state.catalogMeta,
    catalog: state.catalog,
}), ({ preconfiguredFrames, catalogMeta, catalog, }) => {
    var _a, _b;
    const stateKey = consts_1.STATE_KEY_BY_VISIBLE_FRAME[preconfiguredFrames.visibleFrame];
    const formState = preconfiguredFrames[stateKey] || {};
    const options = ((_a = catalogMeta.data) === null || _a === void 0 ? void 0 : _a.seriesTypes)
        ? (_b = catalogMeta.data) === null || _b === void 0 ? void 0 : _b.seriesTypes.map((seriesType) => {
            return {
                label: seriesType.name,
                value: seriesType.id.toString(),
            };
        })
        : [{}];
    const optionsSort = (0, lodash_1.sortBy)(options, ['label']);
    return {
        name: types_2.FieldName.seriesType,
        type: types_2.FieldType.SELECT,
        label: 'Series Type',
        placeholder: 'Select Series Type',
        options: optionsSort,
        value: formState[types_2.FieldName.seriesType],
    };
});
exports.selectSeriesField = (0, reselect_1.createSelector)((state) => ({
    preconfiguredFrames: state.preconfiguredFrames,
    catalogMeta: state.catalogMeta,
}), ({ preconfiguredFrames, catalogMeta, }) => {
    var _a, _b;
    const stateKey = consts_1.STATE_KEY_BY_VISIBLE_FRAME[preconfiguredFrames.visibleFrame];
    const formState = preconfiguredFrames[stateKey] || {};
    const selectedSeriesType = (_a = catalogMeta.data) === null || _a === void 0 ? void 0 : _a.seriesTypes.find((seriesType) => {
        return formState.seriesType === seriesType.id;
    });
    const options = selectedSeriesType && (selectedSeriesType === null || selectedSeriesType === void 0 ? void 0 : selectedSeriesType.series)
        ? (_b = selectedSeriesType.series) === null || _b === void 0 ? void 0 : _b.map((series) => {
            return {
                label: series.name,
                value: series.id.toString(),
            };
        })
        : [{}];
    const optionsSort = (0, lodash_1.sortBy)(options, ['label']);
    return {
        name: types_2.FieldName.series,
        type: types_2.FieldType.SELECT,
        label: 'Series',
        placeholder: 'Select Series',
        options: optionsSort,
        value: formState[types_2.FieldName.series],
    };
});
exports.selectProfileTypeField = (0, reselect_1.createSelector)((state) => ({
    preconfiguredFrames: state.preconfiguredFrames,
    catalogMeta: state.catalogMeta,
    catalog: state.catalog,
}), ({ preconfiguredFrames, catalogMeta, catalog, }) => {
    const stateKey = consts_1.STATE_KEY_BY_VISIBLE_FRAME[preconfiguredFrames.visibleFrame];
    const formState = preconfiguredFrames[stateKey] || {};
    let options = [{}];
    if (window.data !== null) {
        const selectedProfileType = window.data.filter((item) => { var _a; return formState.series === ((_a = item.extrusionType) === null || _a === void 0 ? void 0 : _a.extrusionProfileType.id) && item.deletedAt === null; });
        options = selectedProfileType === null || selectedProfileType === void 0 ? void 0 : selectedProfileType.map((profileType) => {
            var _a, _b;
            return {
                label: (_a = profileType.extrusionType) === null || _a === void 0 ? void 0 : _a.name,
                value: (_b = profileType.extrusionType) === null || _b === void 0 ? void 0 : _b.id.toString(),
            };
        });
    }
    return {
        name: types_2.FieldName.profileType,
        type: types_2.FieldType.SELECT,
        label: 'Profile Type',
        placeholder: 'Select profile type',
        options,
        value: formState[types_2.FieldName.profileType],
    };
});
exports.selectExtrusionField = (0, reselect_1.createSelector)((state) => ({
    preconfiguredFrames: state.preconfiguredFrames,
    catalogMeta: state.catalogMeta,
    catalog: state.catalog,
}), ({ preconfiguredFrames, catalogMeta, catalog, }) => {
    const stateKey = consts_1.STATE_KEY_BY_VISIBLE_FRAME[preconfiguredFrames.visibleFrame];
    const formState = preconfiguredFrames[stateKey] || {};
    const array = [];
    if (window.data !== null) {
        const items = window.data.filter((item) => item.itemType.id === 1);
        items.forEach(function (item) {
            var _a, _b;
            if (item.deletedAt === null &&
                ((_a = item === null || item === void 0 ? void 0 : item.extrusionType) === null || _a === void 0 ? void 0 : _a.slotNumbers) !== null &&
                ((_b = item.extrusionType) === null || _b === void 0 ? void 0 : _b.slotNumbers.length) &&
                item.extrusionType.slotNumbers.length > 3) {
                if (item.extrusionType.slotNumbers.length % 4 === 0) {
                    let insertLockItem = true;
                    for (let i = 0; i < item.extrusionType.slotNumbers.length - 1; i++) {
                        if (item.extrusionType.slotNumbers[i + 1] - item.extrusionType.slotNumbers[i] !== 1) {
                            insertLockItem = false;
                            break;
                        }
                    }
                    if (insertLockItem) {
                        array.push({ label: `#${item === null || item === void 0 ? void 0 : item.item_number}`, value: item === null || item === void 0 ? void 0 : item.id });
                    }
                }
            }
        });
    }
    return {
        name: types_2.FieldName.extrusion,
        type: types_2.FieldType.SELECT,
        label: 'Extrusion',
        placeholder: 'Select extrusion',
        options: array,
        value: formState[types_2.FieldName.extrusion],
    };
});
exports.selectHandleField = (0, reselect_1.createSelector)((state) => ({
    preconfiguredFrames: state.preconfiguredFrames,
    catalogMeta: state.catalogMeta,
    catalog: state.catalog,
}), ({ preconfiguredFrames, catalogMeta, catalog, }) => {
    const stateKey = consts_1.STATE_KEY_BY_VISIBLE_FRAME[preconfiguredFrames.visibleFrame];
    const formState = preconfiguredFrames[stateKey] || {};
    let options = [{}];
    if (window.data !== null) {
        const selectedHandle = window.data.filter((item) => { var _a; return ((_a = item.componentType) === null || _a === void 0 ? void 0 : _a.name) === types_1.COMPONENT_TYPE.HANDLE && item.published === true && item.deletedAt === null; });
        options = selectedHandle === null || selectedHandle === void 0 ? void 0 : selectedHandle.map((handle) => {
            return {
                label: `#${handle.item_number}`,
                value: handle.id.toString(),
            };
        });
    }
    return {
        name: types_2.FieldName.handle,
        type: types_2.FieldType.SELECT,
        label: 'Handle',
        placeholder: 'Select handle',
        options,
        value: formState[types_2.FieldName.handle],
    };
});
exports.selectHingesField = (0, reselect_1.createSelector)((state) => ({
    preconfiguredFrames: state.preconfiguredFrames,
    catalogMeta: state.catalogMeta,
    catalog: state.catalog,
}), ({ preconfiguredFrames, catalogMeta, catalog, }) => {
    const stateKey = consts_1.STATE_KEY_BY_VISIBLE_FRAME[preconfiguredFrames.visibleFrame];
    const formState = preconfiguredFrames[stateKey] || {};
    let options = [{}];
    if (window.data !== null) {
        const selectedHinges = window.data.filter((item) => { var _a; return ((_a = item.componentType) === null || _a === void 0 ? void 0 : _a.name) === types_1.COMPONENT_TYPE.HINGE && item.published === true && item.deletedAt === null; });
        options = selectedHinges === null || selectedHinges === void 0 ? void 0 : selectedHinges.map((hinges) => {
            return {
                label: `#${hinges.item_number}`,
                value: hinges.id.toString(),
            };
        });
    }
    return {
        name: types_2.FieldName.hinges,
        type: types_2.FieldType.SELECT,
        label: 'Hinges',
        placeholder: 'Select hinges',
        options,
        value: formState[types_2.FieldName.hinges],
    };
});
exports.selectLatchField = (0, reselect_1.createSelector)((state) => ({
    preconfiguredFrames: state.preconfiguredFrames,
    catalogMeta: state.catalogMeta,
    catalog: state.catalog,
}), ({ preconfiguredFrames, catalogMeta, catalog, }) => {
    const stateKey = consts_1.STATE_KEY_BY_VISIBLE_FRAME[preconfiguredFrames.visibleFrame];
    const formState = preconfiguredFrames[stateKey] || {};
    let options = [{}];
    if (window.data !== null) {
        const selectedLatch = window.data.filter((item) => { var _a; return ((_a = item.componentType) === null || _a === void 0 ? void 0 : _a.name) === types_1.COMPONENT_TYPE.LATCH && item.published === true && item.deletedAt === null; });
        options = selectedLatch === null || selectedLatch === void 0 ? void 0 : selectedLatch.map((latch) => {
            return {
                label: `#${latch.item_number}`,
                value: latch.id.toString(),
            };
        });
    }
    return {
        name: types_2.FieldName.latch,
        type: types_2.FieldType.SELECT,
        label: 'Latch',
        placeholder: 'Select latch',
        options,
        value: formState[types_2.FieldName.latch],
        // defaultValue: options[0],
    };
});
exports.selectPanelField = (0, reselect_1.createSelector)((state) => ({
    preconfiguredFrames: state.preconfiguredFrames,
    catalogMeta: state.catalogMeta,
    catalog: state.catalog,
}), ({ preconfiguredFrames, catalogMeta, catalog, }) => {
    const stateKey = consts_1.STATE_KEY_BY_VISIBLE_FRAME[preconfiguredFrames.visibleFrame];
    const formState = preconfiguredFrames[stateKey] || {};
    let options = [{}];
    if (window.data !== null) {
        const selectedPanel = window.data.filter((item) => { var _a; return ((_a = item.componentType) === null || _a === void 0 ? void 0 : _a.name) === types_1.COMPONENT_TYPE.PANEL && item.published === true && item.deletedAt === null; });
        options = selectedPanel === null || selectedPanel === void 0 ? void 0 : selectedPanel.map((panel) => {
            return {
                label: `#${panel.item_number}`,
                value: panel.id.toString(),
            };
        });
    }
    return {
        name: types_2.FieldName.panel,
        type: types_2.FieldType.SELECT,
        label: 'Panel',
        placeholder: 'Select panel',
        options,
        value: formState[types_2.FieldName.panel],
        // defaultValue: options[0],
    };
});
exports.selectWidthField = (0, reselect_1.createSelector)((state) => ({
    preconfiguredFrames: state.preconfiguredFrames,
}), ({ preconfiguredFrames }) => {
    const stateKey = consts_1.STATE_KEY_BY_VISIBLE_FRAME[preconfiguredFrames.visibleFrame];
    const formState = preconfiguredFrames[stateKey] || {};
    return {
        name: types_2.FieldName.width,
        type: types_2.FieldType.INPUT,
        label: 'Width, mm',
        imperialLabel: 'Width, in',
        placeholder: 'Input width',
        inputType: 'number',
        value: Number(formState[types_2.FieldName.width]) || 0,
    };
});
exports.selectHeightField = (0, reselect_1.createSelector)((state) => ({
    preconfiguredFrames: state.preconfiguredFrames,
}), ({ preconfiguredFrames }) => {
    const stateKey = consts_1.STATE_KEY_BY_VISIBLE_FRAME[preconfiguredFrames.visibleFrame];
    const formState = preconfiguredFrames[stateKey] || {};
    return {
        name: types_2.FieldName.height,
        type: types_2.FieldType.INPUT,
        label: 'Height, mm',
        imperialLabel: 'Height, in',
        placeholder: 'Input height',
        inputType: 'number',
        value: Number(formState[types_2.FieldName.height]) || 0,
    };
});
exports.selectLengthField = (0, reselect_1.createSelector)((state) => ({
    preconfiguredFrames: state.preconfiguredFrames,
}), ({ preconfiguredFrames }) => {
    const stateKey = consts_1.STATE_KEY_BY_VISIBLE_FRAME[preconfiguredFrames.visibleFrame];
    const formState = preconfiguredFrames[stateKey] || {};
    return {
        name: types_2.FieldName.length,
        type: types_2.FieldType.INPUT,
        label: 'Length, mm',
        imperialLabel: 'Length, in',
        placeholder: 'Input Length',
        inputType: 'number',
        value: Number(formState[types_2.FieldName.length]) || 0,
    };
});
