"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const three_1 = require("three");
const consts_1 = require("../consts");
const MetalBaseMaterial = () => {
    return new three_1.MeshPhongMaterial({
        color: consts_1.consts.DEFAULT_METAL_BASE_MATERIAL_COLOR,
        specular: consts_1.consts.WHITE_COLOR,
        shininess: 30,
        side: three_1.DoubleSide,
    });
};
exports.default = MetalBaseMaterial;
