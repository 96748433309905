"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const OrbitControls_1 = require("../custom-components/controls/OrbitControls");
class OrbitControlsHelper extends OrbitControls_1.OrbitControls {
    enable() {
        this.enabled = true;
    }
    disable() {
        this.enabled = false;
    }
}
exports.default = OrbitControlsHelper;
