"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("~/store/types");
function isProjectStatusDisabled(projectStatus, shared, isCustomer, isDistributor, project) {
    let isDisabled = false;
    switch (projectStatus) {
        case types_1.PROJECT_STATUS.IN_PROGRESS: {
            if (shared) {
                isDisabled = true;
            }
            break;
        }
        case types_1.PROJECT_STATUS.UNDER_REVIEW: {
            if (!shared) {
                isDisabled = true;
            }
            break;
        }
        case types_1.PROJECT_STATUS.ARCHIVED: {
            if ((project === null || project === void 0 ? void 0 : project.status) === types_1.PROJECT_STATUS.ORDER_ENTERED && isDistributor && (project === null || project === void 0 ? void 0 : project.sharedWithAdmins)) {
                isDisabled = true;
            }
            break;
        }
        case types_1.PROJECT_STATUS.ORDER_ENTERED: {
            if (isCustomer || !shared || (isDistributor && (project === null || project === void 0 ? void 0 : project.sharedWithAdmins))) {
                isDisabled = true;
            }
            break;
        }
        default:
            break;
    }
    return isDisabled;
}
exports.default = isProjectStatusDisabled;
