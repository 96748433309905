"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setBomSubmitDisabled = exports.setDiscountedPriceShown = exports.toggleAssemblyChargeForItem = exports.addManuallyAddedMachiningServiceQuantity = exports.deleteManuallyAddedMachiningService = exports.addManuallyDeletedMachiningService = exports.applyMachiningServiceManually = exports.addItemSize = exports.addItemQuantity = exports.deleteManuallyAdded = exports.addManuallyDeleted = exports.addManually = exports.editMode = exports.updateRevisionAction = exports.groupManuallyAddedItems = exports.setAssemblyChargeIncludedItems = exports.setAssemblyChargeIncluded = exports.toggleQuote = exports.requestCloseProject = exports.requestUpdateServicesToBOM = exports.requestRemoveServicesFromBOM = exports.requestAddServicesToBOM = exports.requestUpdateItemsToBOM = exports.requestRemoveItemsFromBOM = exports.requestUpdateFastenersToBOM = exports.requestAddFastenersToBOM = exports.requestAddItemsToBOM = exports.requestBOMFail = exports.requestSocketBOM = exports.requestBOMSuccess = exports.setBOMLoading = exports.setEmptyBOM = exports.setDefaultBOM = void 0;
const typesafe_actions_1 = require("typesafe-actions");
const consts_1 = require("./consts");
exports.setDefaultBOM = (0, typesafe_actions_1.createAction)(consts_1.SET_DEFAULT_BOM)();
exports.setEmptyBOM = (0, typesafe_actions_1.createAction)(consts_1.SET_EMPTY_BOM)();
exports.setBOMLoading = (0, typesafe_actions_1.createAction)(consts_1.SET_BOM_LOADING)();
exports.requestBOMSuccess = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_BOM_SUCCESS)();
exports.requestSocketBOM = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_SOCKET_BOM)();
exports.requestBOMFail = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_BOM_FAIL)();
exports.requestAddItemsToBOM = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_SOCKET_ADD_ITEMS_TO_BOM)();
exports.requestAddFastenersToBOM = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_SOCKET_ADD_FASTENERS_TO_BOM)();
exports.requestUpdateFastenersToBOM = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_SOCKET_UPDATE_FASTENERS_TO_BOM)();
exports.requestRemoveItemsFromBOM = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_SOCKET_REMOVE_ITEMS_FROM_BOM)();
exports.requestUpdateItemsToBOM = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_SOCKET_UPDATE_ITEMS_TO_BOM)();
exports.requestAddServicesToBOM = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_SOCKET_ADD_SERVICES_TO_BOM)();
exports.requestRemoveServicesFromBOM = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_SOCKET_REMOVE_SERVICES_FROM_BOM)();
exports.requestUpdateServicesToBOM = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_SOCKET_UPDATE_SERVICES_TO_BOM)();
exports.requestCloseProject = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_SOCKET_CLOSE_PROJECT)();
exports.toggleQuote = (0, typesafe_actions_1.createAction)(consts_1.TOGGLE_QUOTE)();
exports.setAssemblyChargeIncluded = (0, typesafe_actions_1.createAction)(consts_1.SET_ASSEMBLY_CHARGE_INCLUDED)();
exports.setAssemblyChargeIncludedItems = (0, typesafe_actions_1.createAction)(consts_1.SET_ASSEMBLY_CHARGE_INCLUDED_ITEM)();
exports.groupManuallyAddedItems = (0, typesafe_actions_1.createAction)(consts_1.GROUP_MANUALLY_ADDED_ITEMS)();
exports.updateRevisionAction = (0, typesafe_actions_1.createAction)(consts_1.UPDATE_REVISION)();
exports.editMode = (0, typesafe_actions_1.createAction)(consts_1.EDIT_MODE)();
exports.addManually = (0, typesafe_actions_1.createAction)(consts_1.ADD_ITEM_MANUALLY)();
exports.addManuallyDeleted = (0, typesafe_actions_1.createAction)(consts_1.DELETE_ITEM_MANUALLY)();
exports.deleteManuallyAdded = (0, typesafe_actions_1.createAction)(consts_1.DELETE_MANUALLY_ADDED)();
exports.addItemQuantity = (0, typesafe_actions_1.createAction)(consts_1.ADD_ITEM_QUANTITY)();
exports.addItemSize = (0, typesafe_actions_1.createAction)(consts_1.ADD_ITEM_SIZE)();
// Manually added machining services
exports.applyMachiningServiceManually = (0, typesafe_actions_1.createAction)(consts_1.APPLY_MACHINING_SERVICE_MANUALLY)();
exports.addManuallyDeletedMachiningService = (0, typesafe_actions_1.createAction)(consts_1.DELETE_MACHINING_SERVICE_MANUALLY)();
exports.deleteManuallyAddedMachiningService = (0, typesafe_actions_1.createAction)(consts_1.DELETE_MANUALLY_ADDED_MACHINING_SERVICE)();
exports.addManuallyAddedMachiningServiceQuantity = (0, typesafe_actions_1.createAction)(consts_1.ADD_MANUALLY_ADDED_MACHINING_SERVICE_QUANTITY)();
exports.toggleAssemblyChargeForItem = (0, typesafe_actions_1.createAction)(consts_1.TOGGLE_ASSEMBLY_CHARGE_FOR_ITEM)();
exports.setDiscountedPriceShown = (0, typesafe_actions_1.createAction)(consts_1.SET_DISCOUNTED_PRICE_SHOWN)();
exports.setBomSubmitDisabled = (0, typesafe_actions_1.createAction)(consts_1.SET_BOM_DISABLED)();
