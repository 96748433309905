"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectIsWidgetShown = exports.selectWidgetWidth = exports.selectWidgetHeight = exports.selectWidgetState = exports.selectWidgetIsActive = void 0;
const reselect_1 = require("reselect");
// eslint-disable-next-line import/no-cycle
const utils_1 = require("~/utils/utils");
const selectWidgetIsActive = (widgetName) => (0, reselect_1.createSelector)((state) => state.widgets, (widgets) => (0, utils_1.last)(widgets.activeWidgetsHistory) === widgetName);
exports.selectWidgetIsActive = selectWidgetIsActive;
const selectWidgetState = (widgetName) => (0, reselect_1.createSelector)((state) => state.widgets, (widgets) => widgets.widgets[widgetName]);
exports.selectWidgetState = selectWidgetState;
const selectWidgetHeight = (widgetName) => (0, reselect_1.createSelector)((state) => state.widgets, (widgets) => widgets.widgets[widgetName].size.height);
exports.selectWidgetHeight = selectWidgetHeight;
const selectWidgetWidth = (widgetName) => (0, reselect_1.createSelector)((state) => state.widgets, (widgets) => widgets.widgets[widgetName].size.width);
exports.selectWidgetWidth = selectWidgetWidth;
const selectIsWidgetShown = (widgetName) => (0, reselect_1.createSelector)((state) => state.widgets, (widgets) => widgets.widgets[widgetName].show);
exports.selectIsWidgetShown = selectIsWidgetShown;
