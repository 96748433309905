"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const three_1 = require("three");
const three_mesh_bvh_1 = require("three-mesh-bvh");
// eslint-disable-next-line import/no-cycle
const _1 = require(".");
const consts_1 = require("../consts");
// eslint-disable-next-line import/no-cycle
const utils_1 = require("../utils");
const PanelInSlotsUtils_1 = require("./PanelInSlots/PanelInSlotsUtils");
// eslint-disable-next-line no-underscore-dangle
const _v1 = new three_1.Vector3();
class PanelObjectsBase extends _1.BaseObject3D {
    constructor() {
        super();
        this.notchesCount = 0;
        this.basePosition = null;
        this.wirePanelScale = new three_1.Vector3(1, 1, 1);
    }
    get isBuiltIntoSlots() {
        return this.cutContour.length > 0;
    }
    // eslint-disable-next-line class-methods-use-this
    get hasSnappingMetadata() {
        return true;
    }
    getInboundSnappingPointsRaw() {
        return utils_1.Utils.getMasterSnappingPoints(this);
    }
    // eslint-disable-next-line class-methods-use-this
    get isSnappable() {
        return false;
    }
    get scalingAxes() {
        return [utils_1.Utils.vecToAxis(this.xDirection), utils_1.Utils.vecToAxis(this.yDirection)];
    }
    copy(source, recursive = true) {
        super.copy(source, recursive);
        this.notchesCount = source.notchesCount;
        return this;
    }
    checkAndFixSize() {
        let scale;
        let minLength;
        let minWidth;
        if (this.isWireMesh()) {
            minWidth = consts_1.consts.WIRE_MESH_PANEL_INITIAL_SIZE;
            minLength = consts_1.consts.WIRE_MESH_PANEL_INITIAL_SIZE;
            scale = this.wirePanelScale.clone();
        }
        else {
            minWidth = consts_1.consts.DEFAULT_MIN_WIDTH;
            minLength = consts_1.consts.DEFAULT_MIN_LENGTH;
            scale = this.scale;
        }
        const isFirstComponentLength = scale.x > scale.y;
        let fixed = false;
        fixed || (fixed = this.checkAndFixSizeByAxis(scale, isFirstComponentLength ? 0 : 1, minLength, this.partDetails.maxStockLength));
        fixed =
            this.checkAndFixSizeByAxis(scale, isFirstComponentLength ? 1 : 0, minWidth, this.partDetails.maxStockWidth) ||
                fixed;
        if (fixed && this.isWireMesh() && !this.wirePanelScale.equals(scale)) {
            this.setWirePanelScale(scale);
        }
        return fixed;
    }
    setSizeComponent(index, value, validateSize = true) {
        // We apply a multiplication to the parameter to compensate the effect of resizing the default geometry.
        return super.setSizeComponent(index, this.isWireMesh() ? value * this.wirePanelScale.getComponent(index) : value, validateSize);
    }
    /**
     * Set the physical size of the object.
     */
    setSize(size, validateSize = true) {
        // We apply a multiplication to the parameter to compensate the effect of resizing the default geometry.
        return super.setSize(this.isWireMesh() ? _v1.copy(size).multiply(this.wirePanelScale) : size, validateSize);
    }
    /**
     * Calculate the size of the object depending on its scale and underlying geometry size.
     */
    getSize(useMetadata = true) {
        var _a, _b;
        if (this.isBuiltIntoSlots) {
            return (_b = (_a = this.mesh.geometry.boundingBox) === null || _a === void 0 ? void 0 : _a.getSize(new three_1.Vector3())) !== null && _b !== void 0 ? _b : new three_1.Vector3();
        }
        return this.isWireMesh() ? this.getGeometrySize(new three_1.Vector3(), useMetadata) : super.getSize(useMetadata);
    }
    getScale() {
        return this.isWireMesh() ? this.wirePanelScale : super.getScale();
    }
    getThickness() {
        return this.getLength();
    }
    addScale(growth, validateSize = true) {
        if (utils_1.Utils.areVectorsEqual(growth, utils_1.Utils.zeroVector)) {
            return this;
        }
        if (this.isWireMesh()) {
            this.setWirePanelScale(this.wirePanelScale.clone().add(growth));
        }
        else {
            this.scale.add(growth);
        }
        if (validateSize) {
            this.checkAndFixSize();
        }
        this.metadataNeedsUpdate = true;
        return this;
    }
    isWireMesh() {
        return ['655316', '655317', '655318', '655319', '655818', '655830'].includes(this.partDetails.itemNumber);
    }
    applyExtrudeGeometry(points, panelNormal) {
        var _a, _b, _c;
        if (!this.isBuiltIntoSlots) {
            this.standardGeometry = this.mesh.geometry.clone();
        }
        const { geometry, resultPoints } = (0, PanelInSlotsUtils_1.createExtrudeGeometry)(points, this.getThickness(), panelNormal);
        this.setCutContour(resultPoints);
        geometry.computeBoundingBox();
        this.mesh.geometry.dispose();
        this.mesh.geometry = geometry;
        if (this.isWireMesh() &&
            this.partMetadata.panelWireRadius &&
            this.partMetadata.panelWireHorizontalStep &&
            this.partMetadata.panelWireVerticalStep &&
            this.partMetadata.boundingBoxSize) {
            const wireMeshGeometry = (0, PanelInSlotsUtils_1.createWireMeshGeometry)(this.partMetadata.panelWireRadius, this.partMetadata.panelWireHorizontalStep, this.partMetadata.panelWireVerticalStep, this.partMetadata.boundingBoxSize.z, this.mesh);
            if (wireMeshGeometry) {
                wireMeshGeometry.computeBoundingBox();
                this.mesh.geometry.dispose();
                this.mesh.geometry = wireMeshGeometry;
            }
        }
        this.mesh = this.children[0];
        this.mesh.parent = this;
        const geometryCenter = this.mesh.geometry.boundingBox.getCenter(_v1);
        (_a = this.outerBox) === null || _a === void 0 ? void 0 : _a.position.copy(geometryCenter);
        (_b = this.collisionBox) === null || _b === void 0 ? void 0 : _b.position.copy(geometryCenter);
        (_c = this.selectionBox) === null || _c === void 0 ? void 0 : _c.position.copy(geometryCenter);
    }
    restoreStandardGeometry() {
        var _a, _b, _c;
        if (this.isBuiltIntoSlots) {
            this.cutContour = [];
            this.notchesCount = 0;
            this.mesh.geometry.dispose();
            this.mesh.geometry = this.standardGeometry;
            this.mesh.geometry.boundsTree = new three_mesh_bvh_1.MeshBVH(this.mesh.geometry);
            this.mesh.geometry.computeBoundingBox();
            const geometryCenter = this.mesh.geometry.boundingBox.getCenter(_v1);
            (_a = this.outerBox) === null || _a === void 0 ? void 0 : _a.position.copy(geometryCenter);
            (_b = this.collisionBox) === null || _b === void 0 ? void 0 : _b.position.copy(geometryCenter);
            (_c = this.selectionBox) === null || _c === void 0 ? void 0 : _c.position.copy(geometryCenter);
        }
    }
    setWirePanelScale(scale) {
        var _a, _b, _c, _d, _e;
        const { material } = this.children[0];
        const initialLength = consts_1.consts.WIRE_MESH_PANEL_INITIAL_SIZE;
        this.wirePanelScale = scale.clone();
        (_a = this.partMetadata.boundingBoxSize) === null || _a === void 0 ? void 0 : _a.setX(this.wirePanelScale.x * initialLength);
        (_b = this.partMetadata.boundingBoxSize) === null || _b === void 0 ? void 0 : _b.setY(this.wirePanelScale.y * initialLength);
        if (this.partMetadata.panelWireRadius &&
            this.partMetadata.panelWireHorizontalStep &&
            this.partMetadata.panelWireVerticalStep &&
            this.partMetadata.boundingBoxSize) {
            this.children[0] = utils_1.Utils.createWireMesh(this.partMetadata.panelWireRadius, this.partMetadata.boundingBoxSize.x, this.partMetadata.boundingBoxSize.y, this.partMetadata.panelWireHorizontalStep, this.partMetadata.panelWireVerticalStep, this.partMetadata.boundingBoxSize.z, material);
            this.mesh = this.children[0];
            this.mesh.parent = this;
            // Since the geometry has been changed, move object's
            // boxes to a new geometry center.
            const geometryCenter = this.mesh.geometry.boundingBox.getCenter(_v1);
            (_c = this.outerBox) === null || _c === void 0 ? void 0 : _c.position.copy(geometryCenter);
            (_d = this.collisionBox) === null || _d === void 0 ? void 0 : _d.position.copy(geometryCenter);
            (_e = this.selectionBox) === null || _e === void 0 ? void 0 : _e.position.copy(geometryCenter);
        }
    }
}
exports.default = PanelObjectsBase;
