"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const three_1 = require("three");
const types_1 = require("~/store/types");
const utils_1 = require("../../utils");
const utils_2 = require("./utils");
const utils_3d_1 = require("../../utils/utils.3d");
const DEFAULT_DEPTH = 3;
const DEBUG_MODE = false;
class ObjectContour {
    constructor(object, camera, index = 0, depthInCameraSpace = DEFAULT_DEPTH) {
        this.object = object;
        this.index = index;
        this.depthInCameraSpace = depthInCameraSpace;
        this.camera = camera;
        this.visibleParts = [];
        this.init(camera);
    }
    init(camera) {
        const box = utils_1.Utils.getAABBox(this.object);
        const boxVertices = (0, utils_2.getBoxVerticesInCameraSpace)(box, camera);
        const projectedVertices = boxVertices.map((vertex) => (0, utils_2.projectOnCameraPlane)(vertex, this.depthInCameraSpace));
        this.hull = (0, utils_2.buildConvexHullFromPoints)(projectedVertices);
        const center = box.getCenter(new three_1.Vector3()).applyMatrix4(camera.matrixWorldInverse);
        this.center = (0, utils_2.projectOnCameraPlane)(center, this.depthInCameraSpace);
        if (DEBUG_MODE) {
            // hull
            const lineGeometry = new three_1.BufferGeometry().setFromPoints(this.hull);
            const lineMaterial = new three_1.MeshBasicMaterial({ color: 0xff0000, depthTest: false });
            const line = new three_1.LineLoop(lineGeometry, lineMaterial);
            camera.add(line);
            // center of bounding box
            const centerGeom = new three_1.SphereGeometry(0.01, 10, 10);
            const centerMaterial = new three_1.MeshBasicMaterial({ color: 0xff0000 });
            const centerMesh = new three_1.Mesh(centerGeom, centerMaterial);
            centerMesh.position.copy(this.center);
            camera.add(centerMesh);
        }
    }
    getCenter() {
        return this.center;
    }
    setCenter(newCenter) {
        this.center.copy(newCenter);
    }
    getIndex() {
        return this.index;
    }
    setIndex(value) {
        this.index = value;
    }
    getHull() {
        return this.hull;
    }
    getObject() {
        return this.object;
    }
    computeVisibleParts(contours) {
        this.visibleParts = [this.hull];
        contours.forEach((otherContour) => {
            const result = [];
            this.visibleParts.forEach((part) => {
                var _a, _b;
                if ((0, utils_2.isPolygonInsidePolygon)(part, otherContour.getHull())) {
                    return;
                }
                if ((0, utils_3d_1.isExtrusion)(this.object) && (0, utils_3d_1.isPanel)(otherContour.object)) {
                    const { connectedItems } = this.object;
                    const connectionItem = connectedItems.find((item) => item.uuid === otherContour.object.uuid);
                    if (connectionItem &&
                        ((_a = connectionItem.connection) === null || _a === void 0 ? void 0 : _a.connectionType).name === types_1.CONNECTION_TYPE.PANEL_TO_EXTRUSION_SLOT) {
                        const slotNumber = (_b = connectionItem.slotNumbers) === null || _b === void 0 ? void 0 : _b[0];
                        const slots = this.object.getSlots();
                        if (slotNumber && slots) {
                            const slotWithPanel = slots.find((slot) => slot.slotNumber === slotNumber);
                            if (slotWithPanel) {
                                const slotNormal = slotWithPanel.normal;
                                const cameraDirection = this.camera.getWorldDirection(new three_1.Vector3());
                                // if slot is facing the camera
                                if (slotNormal.dot(cameraDirection) < 0) {
                                    result.push(...(0, utils_2.subtractPolygon)(part, otherContour.getHull()));
                                    return;
                                }
                            }
                        }
                    }
                }
                const intersections = (0, utils_2.getPolygonsIntersections)(part, otherContour.getHull());
                if (intersections.length < 2) {
                    result.push(part);
                    return;
                }
                let subtractionRequired = true;
                const raycaster = new three_1.Raycaster();
                intersections.forEach((intersection) => {
                    const pointInWorldSpace = this.camera.localToWorld(intersection.point.clone());
                    const rcDirection = pointInWorldSpace.sub(this.camera.position).normalize();
                    raycaster.set(this.camera.position, rcDirection);
                    const rcIntersections = raycaster.intersectObjects([this.object, otherContour.object]);
                    if (rcIntersections.length > 0) {
                        const isObjectItselfOrDescendant = rcIntersections[0].object === this.object || (0, utils_2.isObjectDescendant)(rcIntersections[0].object, this.object);
                        if (isObjectItselfOrDescendant) {
                            subtractionRequired = false;
                        }
                    }
                });
                if (subtractionRequired) {
                    result.push(...(0, utils_2.subtractPolygon)(part, otherContour.getHull()));
                }
                else {
                    result.push(part);
                }
            });
            this.visibleParts = result;
        });
        this.areas = this.visibleParts.map((part) => three_1.ShapeUtils.area(part));
    }
    getVisibleAreaPercent() {
        var _a;
        if (!((_a = this.visibleParts) === null || _a === void 0 ? void 0 : _a.length)) {
            return 0;
        }
        const wholeArea = three_1.ShapeUtils.area(this.hull);
        const visibleArea = this.areas.reduce((sum, area) => sum + area, 0);
        return visibleArea / wholeArea;
    }
    getVisibleParts() {
        return this.visibleParts;
    }
    getAreas() {
        return this.areas;
    }
    getLargestVisiblePart() {
        return this.visibleParts[this.areas.reduce((idxOfMax, area, currIdx, arr) => (area > arr[idxOfMax] ? currIdx : idxOfMax), 0)];
    }
    isPointVisible(point) {
        for (let i = 0; i < this.visibleParts.length; i += 1) {
            if ((0, utils_2.isPointInsidePolygon)(point, this.visibleParts[i])) {
                return true;
            }
        }
        return false;
    }
}
exports.default = ObjectContour;
