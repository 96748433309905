"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const effects_1 = require("redux-saga/effects");
const api_1 = require("~/store/api");
const utils_1 = require("~/utils/utils");
const actions_1 = require("./actions");
const consts_1 = require("./consts");
function* fetchCatalog() {
    try {
        const response = yield (0, effects_1.call)(api_1.getCatalog);
        yield (0, effects_1.put)((0, actions_1.requestCatalogSuccess)(response));
    }
    catch (error) {
        yield (0, effects_1.put)((0, actions_1.requestCatalogFail)((0, utils_1.errorify)(error)));
    }
    try {
        const responseMS = yield (0, effects_1.call)(api_1.getCatalogMachiningServices);
        yield (0, effects_1.put)((0, actions_1.requestCatalogMSSuccess)(responseMS));
    }
    catch (msError) {
        yield (0, effects_1.put)((0, actions_1.requestCatalogMSFail)((0, utils_1.errorify)(msError)));
    }
}
function* fetchIncludedFasteners({ payload }) {
    try {
        const response = yield (0, effects_1.call)(api_1.getIncludedCompatibleFasteners, payload);
        yield (0, effects_1.put)((0, actions_1.requestIncludedCompatibleFastenersSuccess)({ itemId: payload, mountToList: response }));
    }
    catch (error) {
        yield (0, effects_1.put)((0, actions_1.requestIncludedCompatibleFastenersFail)((0, utils_1.errorify)(error)));
    }
}
function* rootSaga() {
    yield (0, effects_1.all)([(0, effects_1.takeLatest)(consts_1.REQUEST_CATALOG, fetchCatalog)]);
    yield (0, effects_1.all)([(0, effects_1.takeLatest)(consts_1.REQUEST_INCLUDED_FASTENERS, fetchIncludedFasteners)]);
}
exports.default = rootSaga;
