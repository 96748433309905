"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectTransformMode = exports.SelectTool = void 0;
// eslint-disable-next-line import/prefer-default-export, no-shadow
var SelectTool;
(function (SelectTool) {
    SelectTool["SELECTED_TOOL_NAVIGATE"] = "selectedToolNavigate";
    SelectTool["SELECTED_TOOL_PAN"] = "selectedToolPan";
    SelectTool["SELECTED_TOOL_ROTATE"] = "selectedToolRotate";
    SelectTool["SELECTED_TOOL_MEASURE"] = "selectedToolMeasure";
})(SelectTool = exports.SelectTool || (exports.SelectTool = {}));
// eslint-disable-next-line no-shadow
var SelectTransformMode;
(function (SelectTransformMode) {
    SelectTransformMode["SELECTED_MODE_ROTATE"] = "selectedModeRotate";
    SelectTransformMode["SELECTED_MODE_RESIZE"] = "selectedModeResize";
    SelectTransformMode["SELECTED_MODE_TRANSLATE"] = "selectedModeTranslate";
})(SelectTransformMode = exports.SelectTransformMode || (exports.SelectTransformMode = {}));
