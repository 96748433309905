"use strict";
/* eslint-disable no-unused-expressions */
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
/**
 * @description Timer that triggers something when the countdown completes.
 * @param {number} durationSeconds Count of seconds until the timer would stop and call callback event
 * @param {() => void} onStopCallback Callback function on timer timeout
 */
exports.default = (durationSeconds, onStopCallback) => {
    const [startTime, setStartTime] = (0, react_1.useState)();
    const [seconds, setSeconds] = (0, react_1.useState)(0);
    const [prevSeconds, setPrevSeconds] = (0, react_1.useState)(0);
    const [isActive, setIsActive] = (0, react_1.useState)(false);
    const reset = () => {
        setPrevSeconds(0);
        setIsActive(false);
    };
    const start = () => {
        setStartTime(Date.now());
        setIsActive(true);
    };
    const pause = () => {
        setIsActive(false);
        setPrevSeconds(seconds);
    };
    (0, react_1.useEffect)(() => {
        if (durationSeconds && seconds >= durationSeconds) {
            reset();
            onStopCallback && onStopCallback();
        }
        let interval = null;
        if (isActive && startTime) {
            interval = setInterval(() => {
                setSeconds((Date.now() - startTime) / 1000 + prevSeconds);
            }, 10);
        }
        else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);
    return { seconds, start, reset, pause, isActive };
};
