"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const effects_1 = require("redux-saga/effects");
const utils_1 = require("~/utils/utils");
const actions_1 = require("~/store/actions");
const api_1 = require("~/store/api");
const actions_2 = require("./actions");
const consts_1 = require("./consts");
function* fetchCatalogMeta() {
    try {
        const response = yield (0, effects_1.call)(api_1.getCatalogMeta);
        if (response.seriesTypes) {
            yield (0, effects_1.put)((0, actions_1.setCatalogSeriesType)(response.seriesTypes[0]));
            if (response.seriesTypes[0].series && response.seriesTypes[0].series.length) {
                yield (0, effects_1.put)((0, actions_1.setCatalogSeries)(response.seriesTypes[0].series[0]));
            }
        }
        yield (0, effects_1.put)((0, actions_2.requestCatalogMetaSuccess)(response));
    }
    catch (error) {
        console.error(error);
        yield (0, effects_1.put)((0, actions_2.requestCatalogMetaFail)((0, utils_1.errorify)(error)));
    }
}
function* rootSaga() {
    yield (0, effects_1.all)([(0, effects_1.takeLatest)(consts_1.REQUEST_CATALOG_META, fetchCatalogMeta)]);
}
exports.default = rootSaga;
