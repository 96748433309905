"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateCustomerData = exports.fetchBundleVersionSuccess = exports.fetchBundleVersion = exports.fetchCustomersConnectedToDistributorSuccess = exports.fetchCustomersConnectedToDistributor = exports.fetchDataError = exports.fetchDataSuccess = exports.fetchData = void 0;
const typesafe_actions_1 = require("typesafe-actions");
const consts_1 = require("./consts");
exports.fetchData = (0, typesafe_actions_1.createAction)(consts_1.FETCH_DATA)();
exports.fetchDataSuccess = (0, typesafe_actions_1.createAction)(consts_1.FETCH_DATA_SUCCESS)();
exports.fetchDataError = (0, typesafe_actions_1.createAction)(consts_1.FETCH_DATA_ERROR)();
exports.fetchCustomersConnectedToDistributor = (0, typesafe_actions_1.createAction)(consts_1.FETCH_CUSTOMERS_CONNECTED_TO_DISTRIBUTOR)();
exports.fetchCustomersConnectedToDistributorSuccess = (0, typesafe_actions_1.createAction)(consts_1.FETCH_CUSTOMERS_CONNECTED_TO_DISTRIBUTOR_SUCCESS)();
exports.fetchBundleVersion = (0, typesafe_actions_1.createAction)(consts_1.FETCH_BUNDLE_VERSION)();
exports.fetchBundleVersionSuccess = (0, typesafe_actions_1.createAction)(consts_1.FETCH_BUNDLE_VERSION_SUCCESS)();
exports.updateCustomerData = (0, typesafe_actions_1.createAction)(consts_1.UPDATE_CUSTOMER_DATA)();
