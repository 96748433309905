"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCustomerRequestSuccess = exports.setCustomerData = exports.setCustomerExist = exports.updateCustomer = exports.getCustomer = exports.addCustomer = exports.inviteCustomer = void 0;
const typesafe_actions_1 = require("typesafe-actions");
const consts_1 = require("./consts");
exports.inviteCustomer = (0, typesafe_actions_1.createAction)(consts_1.INVITE_CUSTOMER)();
exports.addCustomer = (0, typesafe_actions_1.createAction)(consts_1.ADD_CUSTOMER)();
exports.getCustomer = (0, typesafe_actions_1.createAction)(consts_1.GET_CUSTOMER)();
exports.updateCustomer = (0, typesafe_actions_1.createAction)(consts_1.UPDATE_CUSTOMER)();
exports.setCustomerExist = (0, typesafe_actions_1.createAction)(consts_1.SET_CUSTOMER_EXIST)();
exports.setCustomerData = (0, typesafe_actions_1.createAction)(consts_1.SET_CUSTOMER_DATA)();
exports.setCustomerRequestSuccess = (0, typesafe_actions_1.createAction)(consts_1.CUSTOMER_REQUEST_SUCCESS)();
