"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REQUEST_SOCKET_UPDATE_PROJECT_SHARED = exports.SET_EXPORT_QUEUE = exports.REQUEST_EXPORT_QUEUE = exports.REQUEST_SOCKET_EXPORT_STEP_PROGRESS = exports.REQUEST_SOCKET_EXPORT_STEP_SUCCESS = exports.REQUEST_SOCKET_EXPORT_STEP = exports.SET_VIEW_MODE = exports.REQUEST_SOCKET_LOCK_PROJECT = exports.TOGGLE_PROJECT_DETAILS_POPOVER = exports.SET_DEFAULT_PROJECT_FASTENERS = exports.SEND_PROJECT_SCREENSHOT = exports.SET_PROJECT_LOADING = exports.UPDATE_PROJECT = exports.CLOSE_PROJECT = exports.DELETE_PROJECT = exports.EDIT_PROJECT_SUCCESS = exports.EDIT_PROJECT_WITHOUT_BOM = exports.EDIT_PROJECT = exports.DUPLICATE_PROJECT_SUCCESS = exports.DUPLICATE_PROJECT = exports.CREATE_PROJECT_SUCCESS = exports.CREATE_PROJECT = exports.GET_PROJECT_SUCCESS = exports.GET_PROJECT = exports.GET_CURRENT_PROJECT_FAILED = exports.GET_CURRENT_PROJECT_SUCCESS = exports.GET_CURRENT_PROJECT = void 0;
exports.GET_CURRENT_PROJECT = 'GET_CURRENT_PROJECT';
exports.GET_CURRENT_PROJECT_SUCCESS = 'REQUEST_PROJECT_SUCCESS';
exports.GET_CURRENT_PROJECT_FAILED = 'GET_CURRENT_PROJECT_FAILED';
exports.GET_PROJECT = 'GET_PROJECT';
exports.GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
exports.CREATE_PROJECT = 'CREATE_PROJECT';
exports.CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
exports.DUPLICATE_PROJECT = 'DUPLICATE_PROJECT';
exports.DUPLICATE_PROJECT_SUCCESS = 'DUPLICATE_PROJECT_SUCCESS';
exports.EDIT_PROJECT = 'EDIT_PROJECT';
exports.EDIT_PROJECT_WITHOUT_BOM = 'EDIT_PROJECT_WITHOUT_BOM';
exports.EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
exports.DELETE_PROJECT = 'DELETE_PROJECT';
exports.CLOSE_PROJECT = 'CLOSE_PROJECT';
exports.UPDATE_PROJECT = 'socket/UPDATE_PROJECT';
exports.SET_PROJECT_LOADING = 'SET_PROJECT_LOADING';
exports.SEND_PROJECT_SCREENSHOT = 'SEND_PROJECT_SCREENSHOT';
exports.SET_DEFAULT_PROJECT_FASTENERS = 'SET_DEFAULT_PROJECT_FASTENERS';
exports.TOGGLE_PROJECT_DETAILS_POPOVER = 'TOGGLE_PROJECT_DETAILS_POPOVER';
exports.REQUEST_SOCKET_LOCK_PROJECT = 'socket/REQUEST_SOCKET_LOCK_PROJECT';
exports.SET_VIEW_MODE = 'SET_VIEW_MODE';
exports.REQUEST_SOCKET_EXPORT_STEP = 'socket/REQUEST_SOCKET_EXPORT_STEP';
exports.REQUEST_SOCKET_EXPORT_STEP_SUCCESS = 'REQUEST_SOCKET_EXPORT_STEP_SUCCESS';
exports.REQUEST_SOCKET_EXPORT_STEP_PROGRESS = 'REQUEST_SOCKET_EXPORT_STEP_PROGRESS';
exports.REQUEST_EXPORT_QUEUE = 'REQUEST_EXPORT_QUEUE';
exports.SET_EXPORT_QUEUE = 'SET_EXPORT_QUEUE';
exports.REQUEST_SOCKET_UPDATE_PROJECT_SHARED = 'socket/REQUEST_SOCKET_UPDATE_PROJECT_SHARED';
