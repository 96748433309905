"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeUserEmail = exports.changeUserPassword = exports.validateRestorationToken = exports.validateActionToken = void 0;
const axiosInstance_1 = require("~/store/api/axiosInstance");
const validateActionToken = (actionToken) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .get(`/account/`, { headers: { 'x-action-token': actionToken } })
        .then(({ data }) => data);
});
exports.validateActionToken = validateActionToken;
const validateRestorationToken = (actionToken) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .post(`/account/`, { headers: { 'x-action-token': actionToken } })
        .then(({ data }) => data);
});
exports.validateRestorationToken = validateRestorationToken;
const changeUserPassword = (password, token, oldPassword) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .post('/account/password', { password, token, oldPassword })
        .then(({ data }) => data)
        .catch((error) => error.response.data);
});
exports.changeUserPassword = changeUserPassword;
const changeUserEmail = (actionToken) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .post('/account/email', {}, { headers: { 'x-action-token': actionToken } })
        .then(({ data }) => data)
        .catch((error) => error.response.data);
});
exports.changeUserEmail = changeUserEmail;
