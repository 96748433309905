"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectBOMItemsCount = exports.selectManuallySavedMachiningServices = exports.selectManuallySavedItems = exports.selectManuallyAddedMachiningServicesQuantity = exports.selectManuallyDeletedMachiningServices = exports.selectManuallyAddedMachiningServices = exports.selectManuallyDeletedItems = exports.selectManuallyAddedItems = exports.selectBOMDiscountedPriceShown = exports.selectBOMEditMode = exports.selectRevisionNumber = exports.selectQuoteShown = exports.selectBOMItemsSize = exports.selectBOMItemsQuantity = exports.selectBOMQuantity = exports.selectBOMLoading = exports.selectBOMDiscountedTotal = exports.selectBOMTotal = exports.selectBOMId = exports.selectBOM = void 0;
const reselect_1 = require("reselect");
// eslint-disable-next-line import/no-cycle
const types_1 = require("~/store/types");
const consts_1 = require("./consts");
exports.selectBOM = (0, reselect_1.createSelector)((state) => state.bom, (bom) => bom);
exports.selectBOMId = (0, reselect_1.createSelector)((state) => state.bom, (bom) => { var _a; return (_a = bom.bom) === null || _a === void 0 ? void 0 : _a.id; });
exports.selectBOMTotal = (0, reselect_1.createSelector)((state) => state.bom, (bom) => { var _a; return (_a = bom.bom) === null || _a === void 0 ? void 0 : _a.total; });
exports.selectBOMDiscountedTotal = (0, reselect_1.createSelector)((state) => state.bom, (bom) => { var _a; return (_a = bom.bom) === null || _a === void 0 ? void 0 : _a.discountedTotal; });
exports.selectBOMLoading = (0, reselect_1.createSelector)((state) => state.bom, (bom) => bom.isLoading);
exports.selectBOMQuantity = (0, reselect_1.createSelector)((state) => state.bom, (bom) => { var _a; return (_a = bom.bom) === null || _a === void 0 ? void 0 : _a.quantity; });
exports.selectBOMItemsQuantity = (0, reselect_1.createSelector)((state) => state.bom, (bom) => bom.manuallyItemsQuantity);
exports.selectBOMItemsSize = (0, reselect_1.createSelector)((state) => state.bom, (bom) => bom.manuallyItemsSize);
exports.selectQuoteShown = (0, reselect_1.createSelector)((state) => state.bom, (bom) => bom.isQuoteShown);
exports.selectRevisionNumber = (0, reselect_1.createSelector)((state) => state.bom, (bom) => { var _a; return (_a = bom.bom) === null || _a === void 0 ? void 0 : _a.revisionNumber; });
exports.selectBOMEditMode = (0, reselect_1.createSelector)((state) => state.bom, (bom) => bom.editMode);
exports.selectBOMDiscountedPriceShown = (0, reselect_1.createSelector)((state) => state.bom, (bom) => bom.isDiscountedPriceShown);
exports.selectManuallyAddedItems = (0, reselect_1.createSelector)((state) => state.bom, (bom) => bom.manuallyAddedItems);
exports.selectManuallyDeletedItems = (0, reselect_1.createSelector)((state) => state.bom, (bom) => bom.manuallyDeletedItems);
exports.selectManuallyAddedMachiningServices = (0, reselect_1.createSelector)((state) => state.bom, (bom) => bom.manuallyAddedMachiningServices);
exports.selectManuallyDeletedMachiningServices = (0, reselect_1.createSelector)((state) => state.bom, (bom) => bom.manuallyDeletedMachiningServices);
exports.selectManuallyAddedMachiningServicesQuantity = (0, reselect_1.createSelector)((state) => state.bom, (bom) => bom.manuallyAddedMachiningServicesQuantity);
exports.selectManuallySavedItems = (0, reselect_1.createSelector)((state) => state.bom, (bom) => {
    var _a, _b, _c;
    const res = {};
    (_c = (_b = (_a = bom.bom) === null || _a === void 0 ? void 0 : _a.groups) === null || _b === void 0 ? void 0 : _b.find((group) => {
        return group.name === consts_1.MANUALLY_ADDED_ITEMS;
    })) === null || _c === void 0 ? void 0 : _c.items.forEach((item) => {
        res[item.uuid && item.uuid.length > 0 ? item.uuid[0] : item.item_number] = item;
    });
    return res;
});
exports.selectManuallySavedMachiningServices = (0, reselect_1.createSelector)((state) => state.bom, (bom) => {
    var _a, _b, _c;
    const res = {};
    const manuallyAddedItems = (_c = (_b = (_a = bom.bom) === null || _a === void 0 ? void 0 : _a.groups) === null || _b === void 0 ? void 0 : _b.find((group) => {
        return group.name === consts_1.MANUALLY_ADDED_ITEMS;
    })) === null || _c === void 0 ? void 0 : _c.items;
    manuallyAddedItems === null || manuallyAddedItems === void 0 ? void 0 : manuallyAddedItems.forEach((item) => {
        var _a;
        if (item.services) {
            const key = item.uuid && item.uuid.length > 0 ? item.uuid[0] : item.item_number;
            res[key] = (_a = item.services) === null || _a === void 0 ? void 0 : _a.map((service) => ({
                projectItemUUID: key,
                machiningServiceId: service.id,
                quantity: service.quantity,
            }));
        }
    });
    return res;
});
exports.selectBOMItemsCount = (0, reselect_1.createSelector)((state) => state.bom, (bom) => {
    var _a, _b, _c;
    return ((_c = (_b = (_a = bom.bom) === null || _a === void 0 ? void 0 : _a.groups) === null || _b === void 0 ? void 0 : _b.reduce((total, group) => {
        return (total +
            group.items.reduce((groupTotal, item) => {
                var _a, _b;
                return ((_a = item.componentType) === null || _a === void 0 ? void 0 : _a.name) === types_1.COMPONENT_TYPE.FASTENER
                    ? groupTotal
                    : groupTotal + ((_b = item.quantity) !== null && _b !== void 0 ? _b : 1);
            }, 0));
    }, 0)) !== null && _c !== void 0 ? _c : 0);
});
