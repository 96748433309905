"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectBundleVersion = exports.selectIsDataLoading = exports.selectStatesWithDistributors = exports.selectStates = exports.selectStatesOnCountryId = exports.selectCountriesiWithDistributors = exports.selectCountries = exports.selectConnectedCustomers = exports.selectDistributors = void 0;
const reselect_1 = require("reselect");
exports.selectDistributors = (0, reselect_1.createSelector)((state) => state.data, (data) => data.distributors);
exports.selectConnectedCustomers = (0, reselect_1.createSelector)((state) => state.data, (data) => data.customersConnectedToDistributor);
exports.selectCountries = (0, reselect_1.createSelector)((state) => state.data, (data) => data.countries);
exports.selectCountriesiWithDistributors = (0, reselect_1.createSelector)((state) => state.data, (data) => data.countriesWithDistributors);
const selectStatesOnCountryId = (countryId) => (0, reselect_1.createSelector)((state) => state.data, (data) => data.states.filter((state) => state.countryId === countryId));
exports.selectStatesOnCountryId = selectStatesOnCountryId;
exports.selectStates = (0, reselect_1.createSelector)((state) => state.data, (data) => data.states);
exports.selectStatesWithDistributors = (0, reselect_1.createSelector)((state) => state.data, (data) => data.statesWithDistributors);
exports.selectIsDataLoading = (0, reselect_1.createSelector)((state) => state.data, (data) => data.isLoading);
exports.selectBundleVersion = (0, reselect_1.createSelector)((state) => state.data, (data) => { var _a; return (_a = data.version) !== null && _a !== void 0 ? _a : {}; });
