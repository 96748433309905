"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateWidgetPosition = exports.updateWidgetSize = exports.toggleWidget = exports.liftUpWidget = exports.initPersistentWidgets = void 0;
const typesafe_actions_1 = require("typesafe-actions");
const consts_1 = require("./consts");
exports.initPersistentWidgets = (0, typesafe_actions_1.createAction)(consts_1.INIT_PERSISTENT_WIDGETS)();
exports.liftUpWidget = (0, typesafe_actions_1.createAction)(consts_1.LIFT_UP_WIDGET)();
exports.toggleWidget = (0, typesafe_actions_1.createAction)(consts_1.TOGGLE_WIDGET)();
exports.updateWidgetSize = (0, typesafe_actions_1.createAction)(consts_1.WIDGET_SIZE)();
exports.updateWidgetPosition = (0, typesafe_actions_1.createAction)(consts_1.WIDGET_POSITION)();
