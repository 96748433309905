"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRoles = exports.CURRENT_TAB = exports.CHANGE_EMAIL = exports.CHANGE_PASSWORD = exports.TOGGLE_ACCOUNT_DROPDOWN = void 0;
// eslint-disable-next-line import/prefer-default-export
exports.TOGGLE_ACCOUNT_DROPDOWN = 'TOGGLE_ACCOUNT_DROPDOWN';
exports.CHANGE_PASSWORD = 'CHANGE_PASSWORD';
exports.CHANGE_EMAIL = 'CHANGE_EMAIL';
exports.CURRENT_TAB = 'CURRENT_TAB';
// eslint-disable-next-line no-shadow
var UserRoles;
(function (UserRoles) {
    UserRoles["admin"] = "admin";
    UserRoles["customer"] = "customer";
    UserRoles["distributor"] = "distributor";
})(UserRoles = exports.UserRoles || (exports.UserRoles = {}));
