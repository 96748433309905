"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Widget_1 = require("~/components/UI/Widget");
const getClosestToBOMWidgetPosition = (BOMWidgetState) => {
    return {
        x: BOMWidgetState.position.x - Widget_1.widgetMinWidth - 8 < 0
            ? BOMWidgetState.position.x + BOMWidgetState.size.width + 8
            : BOMWidgetState.position.x - Widget_1.widgetMinWidth - 8,
        y: BOMWidgetState.position.y,
    };
};
exports.default = getClosestToBOMWidgetPosition;
