import * as THREE from 'three';
import { MeshBVH } from 'three-mesh-bvh';
import { areEqual } from '../utils/utils.3d';

const _taskCache = new WeakMap();
const _dracoGeometryCache = [];
const _maxCacheSize = 20;

export default class DRCLoader extends THREE.Loader {
  constructor(manager, useCache = true) {
    super(manager);
    this.useCache = useCache;
    this.decoderPath = '';
    this.decoderConfig = {};
    this.decoderBinary = null;
    this.decoderPending = null;
    this.workerLimit = 4;
    this.workerPool = [];
    this.workerNextTaskID = 1;
    this.workerSourceURL = '';
    this.defaultAttributeIDs = {
      position: 'POSITION',
      normal: 'NORMAL',
      color: 'COLOR',
      uv: 'TEX_COORD',
    };
    this.defaultAttributeTypes = {
      position: 'Float32Array',
      normal: 'Float32Array',
      color: 'Float32Array',
      uv: 'Float32Array',
    };
  }

  setDecoderPath(path) {
    this.decoderPath = path;
    return this;
  }

  setDecoderConfig(config) {
    this.decoderConfig = config;
    return this;
  }

  setWorkerLimit(workerLimit) {
    this.workerLimit = workerLimit;
    return this;
  }

  load(url, onLoad, onProgress, onError) {
    if (this.useCache) {
      const cacheEntry = _dracoGeometryCache.find((e) => e[0] === url);
      if (cacheEntry) {
        onLoad(cacheEntry[1]);
        return;
      }
    }

    const loader = new THREE.FileLoader(this.manager);
    loader.setPath(this.path);
    loader.setResponseType('arraybuffer');
    loader.setRequestHeader(this.requestHeader);
    loader.setWithCredentials(this.withCredentials);
    loader.load(
      url,
      (buffer) => {
        const taskConfig = {
          attributeIDs: this.defaultAttributeIDs,
          attributeTypes: this.defaultAttributeTypes,
          useUniqueIDs: false,
          url,
        };
        this.decodeGeometry(buffer, taskConfig).then(onLoad).catch(onError);
      },
      onProgress,
      onError,
    );
  }
  /** @deprecated Kept for backward-compatibility with previous DRACOLoader versions. */

  decodeDracoFile(buffer, callback, attributeIDs, attributeTypes) {
    const taskConfig = {
      attributeIDs: attributeIDs || this.defaultAttributeIDs,
      attributeTypes: attributeTypes || this.defaultAttributeTypes,
      useUniqueIDs: !!attributeIDs,
    };
    this.decodeGeometry(buffer, taskConfig).then(callback);
  }

  decodeGeometry(buffer, taskConfig) {
    // TODO: For backward-compatibility, support 'attributeTypes' objects containing
    // references (rather than names) to typed array constructors. These must be
    // serialized before sending them to the worker.
    for (const attribute in taskConfig.attributeTypes) {
      const type = taskConfig.attributeTypes[attribute];

      if (type.BYTES_PER_ELEMENT !== undefined) {
        taskConfig.attributeTypes[attribute] = type.name;
      }
    } //

    const taskKey = JSON.stringify(taskConfig); // Check for an existing task using this buffer. A transferred buffer cannot be transferred
    // again from this thread.

    if (_taskCache.has(buffer)) {
      const cachedTask = _taskCache.get(buffer);

      if (cachedTask.key === taskKey) {
        return cachedTask.promise;
      }
      if (buffer.byteLength === 0) {
        // Technically, it would be possible to wait for the previous task to complete,
        // transfer the buffer back, and decode again with the second configuration. That
        // is complex, and I don't know of any reason to decode a Draco buffer twice in
        // different ways, so this is left unimplemented.
        throw new Error(
          'DRCLoader: Unable to re-decode a buffer with different ' + 'settings. Buffer has already been transferred.',
        );
      }
    } //

    let worker;
    const taskID = this.workerNextTaskID++;
    const taskCost = buffer.byteLength; // Obtain a worker and assign a task, and construct a geometry instance
    // when the task completes.

    const geometryPending = this._getWorker(taskID, taskCost)
      .then((_worker) => {
        worker = _worker;
        return new Promise((resolve, reject) => {
          worker._callbacks[taskID] = {
            resolve,
            reject,
          };
          worker.postMessage(
            {
              type: 'decode',
              id: taskID,
              taskConfig,
              buffer,
            },
            [buffer],
          ); // this.debug();
        });
      })
      .then((message) => this._createGeometry(message.geometry, taskConfig)); // Remove task from the task list.
    // Note: replaced '.finally()' with '.catch().then()' block - iOS 11 support (#19416)

    geometryPending
      .catch(() => true)
      .then(() => {
        if (worker && taskID) {
          this._releaseTask(worker, taskID); // this.debug();
        }
      }); // Cache the task result.

    _taskCache.set(buffer, {
      key: taskKey,
      promise: geometryPending,
    });

    return geometryPending;
  }

  // Sometimes the values decoded from the DRC buffer differ from the actual ones (the true values that were encoded)
  // by some epsilon value so the geometry using those values has a slightly incorrect bounding box.
  // This fact is critical for extrusions which are usually extruded, and the calculation error becomes significant.
  // This leads to various errors in snapping accuracy, machining notes calculation and so on.
  // Changing the DRC encoding properties only reduces the difference between the actual and decoded values, but doesn't
  // eliminate it.
  // So, as a trick, we can manually set the Z coordinates of the bottom points to zero. In the future, maybe we should do
  // the same for the X and Y coords (for the case of panels which are also resizable).
  _fixGeometryVertices(geometry) {
    const attributeValues = geometry.attributes.position;
    const verticesCount = attributeValues.count;

    for (let vertexIndex = 0; vertexIndex < verticesCount; vertexIndex += 1) {
      if (areEqual(attributeValues.getZ(vertexIndex), 0, 1e-3)) {
        attributeValues.setZ(vertexIndex, 0);
      }
    }
  }

  _createGeometry(geometryData, taskConfig) {
    const geometry = new THREE.BufferGeometry();

    if (geometryData.index) {
      geometry.setIndex(new THREE.BufferAttribute(geometryData.index.array, 1));
    }

    for (let i = 0; i < geometryData.attributes.length; i++) {
      const attribute = geometryData.attributes[i];
      const { name } = attribute;
      const { array } = attribute;
      const { itemSize } = attribute;
      geometry.setAttribute(name, new THREE.BufferAttribute(array, itemSize));
    }

    this._fixGeometryVertices(geometry);

    geometry.computeVertexNormals();
    geometry.computeBoundingBox();
    geometry.userData = geometryData.metadata;
    geometry.boundsTree = new MeshBVH(geometry);

    if (this.useCache && _dracoGeometryCache.every((e) => e[0] !== taskConfig.url)) {
      _dracoGeometryCache.push([taskConfig.url, geometry]);

      if (_dracoGeometryCache.length > _maxCacheSize) {
        _dracoGeometryCache.shift();
      }
    }

    return geometry;
  }

  _loadLibrary(url, responseType) {
    const loader = new THREE.FileLoader(this.manager);
    loader.setPath(this.decoderPath);
    loader.setResponseType(responseType);
    loader.setWithCredentials(this.withCredentials);
    return new Promise((resolve, reject) => {
      loader.load(url, resolve, undefined, reject);
    });
  }

  preload() {
    this._initDecoder();

    return this;
  }

  _initDecoder() {
    if (this.decoderPending) {
      return this.decoderPending;
    }
    const useJS = typeof WebAssembly !== 'object' || this.decoderConfig.type === 'js';
    const librariesPending = [];

    if (useJS) {
      librariesPending.push(this._loadLibrary('draco_decoder.js', 'text'));
    } else {
      librariesPending.push(this._loadLibrary('draco_wasm_wrapper.js', 'text'));
      librariesPending.push(this._loadLibrary('draco_decoder.wasm', 'arraybuffer'));
    }

    this.decoderPending = Promise.all(librariesPending).then((libraries) => {
      const jsContent = libraries[0];

      if (!useJS) {
        this.decoderConfig.wasmBinary = libraries[1];
      }

      const fn = DRACOWorker.toString();
      const body = [
        '/* draco decoder */',
        jsContent,
        '',
        '/* worker */',
        fn.substring(fn.indexOf('{') + 1, fn.lastIndexOf('}')),
      ].join('\n');
      this.workerSourceURL = URL.createObjectURL(new Blob([body]));
    });
    return this.decoderPending;
  }

  _getWorker(taskID, taskCost) {
    return this._initDecoder().then(() => {
      if (this.workerPool.length < this.workerLimit) {
        const worker = new Worker(this.workerSourceURL);
        worker._callbacks = {};
        worker._taskCosts = {};
        worker._taskLoad = 0;
        worker.postMessage({
          type: 'init',
          decoderConfig: this.decoderConfig,
        });

        worker.onmessage = function (e) {
          const message = e.data;

          switch (message.type) {
            case 'decode':
              worker._callbacks[message.id].resolve(message);

              break;

            case 'error':
              worker._callbacks[message.id].reject(message);

              break;

            default:
              console.error(`DRCLoader: Unexpected message, "${message.type}"`);
          }
        };

        this.workerPool.push(worker);
      } else {
        this.workerPool.sort(function (a, b) {
          return a._taskLoad > b._taskLoad ? -1 : 1;
        });
      }

      const worker = this.workerPool[this.workerPool.length - 1];
      worker._taskCosts[taskID] = taskCost;
      worker._taskLoad += taskCost;
      return worker;
    });
  }

  _releaseTask(worker, taskID) {
    worker._taskLoad -= worker._taskCosts[taskID];
    delete worker._callbacks[taskID];
    delete worker._taskCosts[taskID];
  }

  debug() {
    console.log(
      'Task load: ',
      this.workerPool.map((worker) => worker._taskLoad),
    );
  }

  dispose() {
    for (let i = 0; i < this.workerPool.length; ++i) {
      this.workerPool[i].terminate();
    }

    this.workerPool.length = 0;
    return this;
  }
}

/* WEB WORKER */

function DRACOWorker() {
  let decoderConfig;
  let decoderPending;

  onmessage = function (e) {
    const message = e.data;

    switch (message.type) {
      case 'init':
        decoderConfig = message.decoderConfig;
        decoderPending = new Promise(function (
          resolve,
          /* , reject */
        ) {
          decoderConfig.onModuleLoaded = function (draco) {
            // Module is Promise-like. Wrap before resolving to avoid loop.
            resolve({
              draco,
            });
          };

          DracoDecoderModule(decoderConfig); // eslint-disable-line no-undef
        });
        break;

      case 'decode':
        const { buffer } = message;
        const { taskConfig } = message;
        decoderPending.then((module) => {
          const { draco } = module;
          const decoder = new draco.Decoder();
          const decoderBuffer = new draco.DecoderBuffer();
          decoderBuffer.Init(new Int8Array(buffer), buffer.byteLength);

          try {
            const geometry = decodeGeometry(draco, decoder, decoderBuffer, taskConfig);
            const buffers = geometry.attributes.map((attr) => attr.array.buffer);
            if (geometry.index) {
              buffers.push(geometry.index.array.buffer);
            }
            self.postMessage(
              {
                type: 'decode',
                id: message.id,
                geometry,
              },
              buffers,
            );
          } catch (error) {
            console.error(error);
            self.postMessage({
              type: 'error',
              id: message.id,
              error: error.message,
            });
          } finally {
            draco.destroy(decoderBuffer);
            draco.destroy(decoder);
          }
        });
        break;
    }
  };

  function decodeGeometry(draco, decoder, decoderBuffer, taskConfig) {
    const { attributeIDs } = taskConfig;
    const { attributeTypes } = taskConfig;
    let dracoGeometry;
    let decodingStatus;
    const geometryType = decoder.GetEncodedGeometryType(decoderBuffer);

    if (geometryType === draco.TRIANGULAR_MESH) {
      dracoGeometry = new draco.Mesh();
      decodingStatus = decoder.DecodeBufferToMesh(decoderBuffer, dracoGeometry);
    } else if (geometryType === draco.POINT_CLOUD) {
      dracoGeometry = new draco.PointCloud();
      decodingStatus = decoder.DecodeBufferToPointCloud(decoderBuffer, dracoGeometry);
    } else {
      throw new Error('DRCLoader: Unexpected geometry type.');
    }

    if (!decodingStatus.ok() || dracoGeometry.ptr === 0) {
      throw new Error(`DRCLoader: Decoding failed: ${decodingStatus.error_msg()}`);
    }

    const geometry = {
      index: null,
      attributes: [],
      metadata: null,
    }; // Gather all vertex attributes.

    for (const attributeName in attributeIDs) {
      const attributeType = self[attributeTypes[attributeName]];
      let attribute;
      let attributeID; // A Draco file may be created with default vertex attributes, whose attribute IDs
      // are mapped 1:1 from their semantic name (POSITION, NORMAL, ...). Alternatively,
      // a Draco file may contain a custom set of attributes, identified by known unique
      // IDs. glTF files always do the latter, and `.drc` files typically do the former.

      if (taskConfig.useUniqueIDs) {
        attributeID = attributeIDs[attributeName];
        attribute = decoder.GetAttributeByUniqueId(dracoGeometry, attributeID);
      } else {
        attributeID = decoder.GetAttributeId(dracoGeometry, draco[attributeIDs[attributeName]]);
        if (attributeID === -1) {
          continue;
        }
        attribute = decoder.GetAttribute(dracoGeometry, attributeID);
      }

      geometry.attributes.push(decodeAttribute(draco, decoder, dracoGeometry, attributeName, attributeType, attribute));
    } // Add index.

    if (geometryType === draco.TRIANGULAR_MESH) {
      geometry.index = decodeIndex(draco, decoder, dracoGeometry);
    }

    // Constants for metadata extracting
    const inboundPointsCountName = 'ipc';
    const inboundPointEntryPrefix = 'ip';

    const inboundPointsNormalsCountName = 'inc';
    const inboundPointNormalEntryPrefix = 'in';

    const outboundPointsCountName = 'opc';
    const outboundPointEntryPrefix = 'op';

    const outboundPointsNormalsCountName = 'onc';
    const outboundPointNormalEntryPrefix = 'on';

    const recommendedFastenerPointsCountName = 'rfpc';
    const recommendedFastenerPointEntryPrefix = 'rfp';

    const rectanglesCountName = 'rc';
    const rectangleEntryPrefix = 'r';
    const slotNumbersEntryPrefix = 'sn';

    const boundingBoxEntryPrefix = 'b';

    const masterSnappingPointsCountName = 'mpc';
    const masterSnappingPointEntryPrefix = 'm';
    const alignmentAxisEntryPrefix = 'a';

    const panelWireRadius = 'wr';
    const panelWireHorizontalStep = 'whs';
    const panelWireVerticalStep = 'wvs';

    const RECT_POINTS_NUMBER = 4;
    const VECTOR_COMPONENTS_NUMBER = 3;

    // Extract metadata
    const metadata = decoder.GetMetadata(dracoGeometry);
    const metadataQuerier = new draco.MetadataQuerier();

    const geometryMetadata = {};

    // Metadata entries names consist of some prefix and entry's index.
    // For example: b0, b1, b2, ... for bounding box metadata.
    if (metadataQuerier.HasEntry(metadata, `${boundingBoxEntryPrefix}0`)) {
      geometryMetadata.boundingBoxSize = getVector3EntryFromMetadata(metadataQuerier, metadata, boundingBoxEntryPrefix);
    }

    if (metadataQuerier.HasEntry(metadata, `${alignmentAxisEntryPrefix}0`)) {
      geometryMetadata.alignmentAxis = getVector3EntryFromMetadata(metadataQuerier, metadata, alignmentAxisEntryPrefix);
    }

    // metadata may contain set of snapping points or a single snapping point
    if (metadataQuerier.HasEntry(metadata, masterSnappingPointsCountName)) {
      const pointsNum = metadataQuerier.GetIntEntry(metadata, masterSnappingPointsCountName);

      geometryMetadata.masterSnappingPoints = getPointArrayFromMetadata(
        metadataQuerier,
        metadata,
        masterSnappingPointEntryPrefix,
        pointsNum,
      );
    } else if (metadataQuerier.HasEntry(metadata, `${masterSnappingPointEntryPrefix}0`)) {
      geometryMetadata.masterSnappingPoints = getPointArrayFromMetadata(
        metadataQuerier,
        metadata,
        masterSnappingPointEntryPrefix,
        1,
      );
    }

    if (metadataQuerier.HasEntry(metadata, inboundPointsCountName)) {
      const pointsNum = metadataQuerier.GetIntEntry(metadata, inboundPointsCountName);

      geometryMetadata.inboundSnappingPoints = getPointArrayFromMetadata(
        metadataQuerier,
        metadata,
        inboundPointEntryPrefix,
        pointsNum,
      );
    }

    if (metadataQuerier.HasEntry(metadata, inboundPointsNormalsCountName)) {
      const normalsNum = metadataQuerier.GetIntEntry(metadata, inboundPointsNormalsCountName);

      geometryMetadata.inboundSnappingPointsNormals = getPointArrayFromMetadata(
        metadataQuerier,
        metadata,
        inboundPointNormalEntryPrefix,
        normalsNum,
      );
    }

    if (metadataQuerier.HasEntry(metadata, outboundPointsCountName)) {
      const pointsNum = metadataQuerier.GetIntEntry(metadata, outboundPointsCountName);

      geometryMetadata.outboundSnappingPoints = getPointArrayFromMetadata(
        metadataQuerier,
        metadata,
        outboundPointEntryPrefix,
        pointsNum,
      );
    }

    if (metadataQuerier.HasEntry(metadata, outboundPointsNormalsCountName)) {
      const normalsNum = metadataQuerier.GetIntEntry(metadata, outboundPointsNormalsCountName);

      geometryMetadata.outboundSnappingPointsNormals = getPointArrayFromMetadata(
        metadataQuerier,
        metadata,
        outboundPointNormalEntryPrefix,
        normalsNum,
      );
    }

    if (metadataQuerier.HasEntry(metadata, recommendedFastenerPointsCountName)) {
      const pointsNum = metadataQuerier.GetIntEntry(metadata, recommendedFastenerPointsCountName);

      geometryMetadata.recommendedFastenerPoints = getPointArrayFromMetadata(
        metadataQuerier,
        metadata,
        recommendedFastenerPointEntryPrefix,
        pointsNum,
      );
    }

    if (metadataQuerier.HasEntry(metadata, rectanglesCountName)) {
      const rectNum = metadataQuerier.GetIntEntry(metadata, rectanglesCountName);
      const rectangles = [];

      for (let rectID = 0; rectID < rectNum; ++rectID) {
        const slotNumber = metadataQuerier.GetIntEntry(metadata, slotNumbersEntryPrefix + rectID);
        const rectPoints = [];

        for (let rectPointID = 0; rectPointID < RECT_POINTS_NUMBER; ++rectPointID) {
          rectPoints.push(
            getVector3EntryFromMetadata(
              metadataQuerier,
              metadata,
              rectangleEntryPrefix,
              VECTOR_COMPONENTS_NUMBER * (rectPointID + RECT_POINTS_NUMBER * rectID),
            ),
          );
        }

        rectangles.push({ number: slotNumber, points: rectPoints });
      }
      geometryMetadata.rectangles = rectangles;
    }

    if (metadataQuerier.HasEntry(metadata, panelWireRadius)) {
      geometryMetadata.panelWireRadius = metadataQuerier.GetDoubleEntry(metadata, panelWireRadius);
    }

    if (metadataQuerier.HasEntry(metadata, panelWireHorizontalStep)) {
      geometryMetadata.panelWireHorizontalStep = metadataQuerier.GetDoubleEntry(metadata, panelWireHorizontalStep);
    }

    if (metadataQuerier.HasEntry(metadata, panelWireVerticalStep)) {
      geometryMetadata.panelWireVerticalStep = metadataQuerier.GetDoubleEntry(metadata, panelWireVerticalStep);
    }

    geometry.metadata = geometryMetadata;

    draco.destroy(metadataQuerier);
    draco.destroy(dracoGeometry);

    return geometry;
  }

  function getPointArrayFromMetadata(metadataQuerier, metadata, pointNamePrefix, pointsNum) {
    const points = [];

    for (let pointID = 0; pointID < pointsNum; ++pointID) {
      points.push(getVector3EntryFromMetadata(metadataQuerier, metadata, pointNamePrefix, 3 * pointID));
    }
    return points;
  }

  function getVector3EntryFromMetadata(metadataQuerier, metadata, namePrefix, startFromIndex = 0) {
    const resultVector = [];

    for (let compID = 0; compID < 3; ++compID) {
      resultVector.push(metadataQuerier.GetDoubleEntry(metadata, namePrefix + (startFromIndex + compID)));
    }
    return resultVector;
  }

  function decodeIndex(draco, decoder, dracoGeometry) {
    const numFaces = dracoGeometry.num_faces();
    const numIndices = numFaces * 3;
    const byteLength = numIndices * 4;

    const ptr = draco._malloc(byteLength);

    decoder.GetTrianglesUInt32Array(dracoGeometry, byteLength, ptr);
    const index = new Uint32Array(draco.HEAPF32.buffer, ptr, numIndices).slice();

    draco._free(ptr);

    return {
      array: index,
      itemSize: 1,
    };
  }

  function decodeAttribute(draco, decoder, dracoGeometry, attributeName, attributeType, attribute) {
    const numComponents = attribute.num_components();
    const numPoints = dracoGeometry.num_points();
    const numValues = numPoints * numComponents;
    const byteLength = numValues * attributeType.BYTES_PER_ELEMENT;
    const dataType = getDracoDataType(draco, attributeType);

    const ptr = draco._malloc(byteLength);

    decoder.GetAttributeDataArrayForAllPoints(dracoGeometry, attribute, dataType, byteLength, ptr);
    const array = new attributeType(draco.HEAPF32.buffer, ptr, numValues).slice();

    draco._free(ptr);

    return {
      name: attributeName,
      array,
      itemSize: numComponents,
    };
  }

  function getDracoDataType(draco, attributeType) {
    switch (attributeType) {
      case Float32Array:
        return draco.DT_FLOAT32;

      case Int8Array:
        return draco.DT_INT8;

      case Int16Array:
        return draco.DT_INT16;

      case Int32Array:
        return draco.DT_INT32;

      case Uint8Array:
        return draco.DT_UINT8;

      case Uint16Array:
        return draco.DT_UINT16;

      case Uint32Array:
        return draco.DT_UINT32;
    }
  }
}
