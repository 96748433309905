"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.END_DATE = exports.REDIRECT_URL = exports.IMAGE_NAME = exports.PATH_TO_IMAGES = void 0;
exports.PATH_TO_IMAGES = '/assets/images/';
// Change the values below to set up another banner
exports.IMAGE_NAME = 'Design-Competition-Banner.jpg';
exports.REDIRECT_URL = 'https://www.tslots.com/tbuild-competition/';
exports.END_DATE = {
    year: 2023,
    month: 12,
    day: 6,
};
