"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Views = void 0;
const three_1 = require("three");
/*
    Cube corners numbering order.
    Edges are described as two corner numbers sorted in ascending order

        7-------6
       /|      /|
      4-+-----5 |
      | Front | |      z-
      | 3-----+-2      | y-
      |/      |/       |/
      0-------1        +--x+
*/
// eslint-disable-next-line import/prefer-default-export
exports.Views = {
    Front: {
        offset: new three_1.Vector3(0, 1, 0),
        up: new three_1.Vector3(0, 0, -1),
    },
    Back: {
        offset: new three_1.Vector3(0, -1, 0),
        up: new three_1.Vector3(0, 0, -1),
    },
    Left: {
        offset: new three_1.Vector3(-1, 0, 0),
        up: new three_1.Vector3(0, 0, -1),
    },
    Right: {
        offset: new three_1.Vector3(1, 0, 0),
        up: new three_1.Vector3(0, 0, -1),
    },
    Top: {
        offset: new three_1.Vector3(0, 0, -1),
        up: new three_1.Vector3(0, -1, -1),
    },
    Bottom: {
        offset: new three_1.Vector3(0, 0, 1),
        up: new three_1.Vector3(0, 1, -1),
    },
    Edge04: {
        offset: new three_1.Vector3(-1, 1, 0),
        up: new three_1.Vector3(0, 0, -1),
    },
    Edge15: {
        offset: new three_1.Vector3(1, 1, 0),
        up: new three_1.Vector3(0, 0, -1),
    },
    Edge26: {
        offset: new three_1.Vector3(1, -1, 0),
        up: new three_1.Vector3(0, 0, -1),
    },
    Edge37: {
        offset: new three_1.Vector3(-1, -1, 0),
        up: new three_1.Vector3(0, 0, -1),
    },
    Edge01: {
        offset: new three_1.Vector3(0, 1, 1),
        up: new three_1.Vector3(0, 0, -1),
    },
    Edge23: {
        offset: new three_1.Vector3(0, -1, 1),
        up: new three_1.Vector3(0, 0, -1),
    },
    Edge03: {
        offset: new three_1.Vector3(-1, 0, 1),
        up: new three_1.Vector3(0, 0, -1),
    },
    Edge12: {
        offset: new three_1.Vector3(1, 0, 1),
        up: new three_1.Vector3(0, 0, -1),
    },
    Edge45: {
        offset: new three_1.Vector3(0, 1, -1),
        up: new three_1.Vector3(0, 0, -1),
    },
    Edge67: {
        offset: new three_1.Vector3(0, -1, -1),
        up: new three_1.Vector3(0, 0, -1),
    },
    Edge47: {
        offset: new three_1.Vector3(-1, 0, -1),
        up: new three_1.Vector3(0, 0, -1),
    },
    Edge56: {
        offset: new three_1.Vector3(1, 0, -1),
        up: new three_1.Vector3(0, 0, -1),
    },
    Corner0: {
        offset: new three_1.Vector3(-0.5, 0.5, 0.5),
        up: new three_1.Vector3(0, 0, -1),
    },
    Corner1: {
        offset: new three_1.Vector3(0.5, 0.5, 0.5),
        up: new three_1.Vector3(0, 0, -1),
    },
    Corner2: {
        offset: new three_1.Vector3(0.5, -0.5, 0.5),
        up: new three_1.Vector3(0, 0, -1),
    },
    Corner3: {
        offset: new three_1.Vector3(-0.5, -0.5, 0.5),
        up: new three_1.Vector3(0, 0, -1),
    },
    Corner4: {
        offset: new three_1.Vector3(-0.5, 0.5, -0.5),
        up: new three_1.Vector3(0, 0, -1),
    },
    Corner5: {
        offset: new three_1.Vector3(0.5, 0.5, -0.5),
        up: new three_1.Vector3(0, 0, -1),
    },
    Corner6: {
        offset: new three_1.Vector3(0.5, -0.5, -0.5),
        up: new three_1.Vector3(0, 0, -1),
    },
    Corner7: {
        offset: new three_1.Vector3(-0.5, -0.5, -0.5),
        up: new three_1.Vector3(0, 0, -1),
    },
};
