"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BomGroup = void 0;
// eslint-disable-next-line no-shadow
var BomGroup;
(function (BomGroup) {
    BomGroup["Extrusions"] = "Extrusions";
    BomGroup["Components"] = "Components";
    BomGroup["ManuallyAddedItems"] = "Manually Added Items";
})(BomGroup = exports.BomGroup || (exports.BomGroup = {}));
