"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TRANSFORM_MODE = exports.TRANSFORM_PANEL_TOOL = void 0;
/* eslint-disable no-shadow */
exports.TRANSFORM_PANEL_TOOL = 'TRANSFORM_PANEL_TOOL';
var TRANSFORM_MODE;
(function (TRANSFORM_MODE) {
    TRANSFORM_MODE["TRANSLATE"] = "translate";
    TRANSFORM_MODE["ROTATE"] = "rotate";
    TRANSFORM_MODE["SCALE"] = "scale";
    TRANSFORM_MODE["NONE"] = "none";
})(TRANSFORM_MODE = exports.TRANSFORM_MODE || (exports.TRANSFORM_MODE = {}));
