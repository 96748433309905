"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const three_1 = require("three");
const consts_1 = require("../consts");
// eslint-disable-next-line import/no-cycle
const utils_1 = require("../utils");
// eslint-disable-next-line import/no-cycle
const utils_3d_1 = require("../utils/utils.3d");
class SnapHelper {
    // private dirOnSnapX: Line;
    // private dirOnSnapY: Line;
    // private dirOnSnapZ: Line;
    constructor(viewer, anchorDetection, collisionProcessor) {
        this.scene = viewer.getScene();
        this.anchorDetection = anchorDetection;
        this.collisionProcessor = collisionProcessor;
        this.active = true;
        this.viewer = viewer;
    }
    activate() {
        this.active = true;
    }
    deactivate() {
        this.active = false;
    }
    try2SnapObjByBoxCenter(object, objectDir = new three_1.Vector3(0, 0, -1)) {
        var _a, _b, _c;
        if (!this.active) {
            return { collided: false, snapped: false };
        }
        if ((0, utils_3d_1.isAngledExtrusion)(object)) {
            return { collided: false, snapped: false };
        }
        let testResult = false;
        const box = utils_1.Utils.getAABBox(object);
        let orientedBBox = utils_1.Utils.getOBB(object);
        const positionBeforeSnap = object.position.clone();
        const halfSize = new three_1.Vector3(0, 0, 0);
        halfSize.setX(Math.abs((box.max.x - box.min.x) / 2));
        halfSize.setY(Math.abs((box.max.y - box.min.y) / 2));
        halfSize.setZ(Math.abs((box.max.z - box.min.z) / 2));
        const excludeUUIDs = new Set([object.uuid]);
        this.viewer.selectedObjects.forEach((item) => {
            excludeUUIDs.add(item.uuid);
        });
        object.getConnectionExcludeIDs().forEach((excludeUUID) => {
            excludeUUIDs.add(excludeUUID);
        });
        const snapTest = this.testBoxSnap(box, objectDir, halfSize, (_a = object.getSlots()) !== null && _a !== void 0 ? _a : [], (_b = object.getOutboundSnappingPoints()) !== null && _b !== void 0 ? _b : [], consts_1.consts.SNAP_BY_BBOX_THRESHOLD, excludeUUIDs);
        let offsetAfterSnap;
        if (snapTest.result === true) {
            if (snapTest.snapMask.x === 0) {
                snapTest.snapValues.x = object.position.x;
            }
            if (snapTest.snapMask.y === 0) {
                snapTest.snapValues.y = object.position.y;
            }
            if (snapTest.snapMask.z === 0) {
                snapTest.snapValues.z = object.position.z;
            }
            const groupBoxes = this.collisionProcessor.getGroupBoxes();
            for (let i = 0; i < 3; i += 1) {
                if (snapTest.snapMask.getComponent(i) !== 0) {
                    const groupBox = groupBoxes[snapTest.snappedBoxIndices.getComponent(i)];
                    const objData = groupBox.getObject(snapTest.snappedObjIndices.getComponent(i));
                    const neighborObject = utils_1.Utils.getObjectByUUID(this.viewer.getScene(), objData.objectUUID);
                    const centerPosOnNeighbor = utils_1.Utils.calculateCenterSnappingPosition(object, neighborObject, object.directionNormal, consts_1.consts.SNAP_TO_THE_CENTER_THRESHOLD);
                    if (centerPosOnNeighbor) {
                        if (neighborObject.directionNormal.x !== 0) {
                            snapTest.snapValues.setX(centerPosOnNeighbor.x);
                        }
                        if (neighborObject.directionNormal.y !== 0) {
                            snapTest.snapValues.setY(centerPosOnNeighbor.y);
                        }
                        if (neighborObject.directionNormal.z !== 0) {
                            snapTest.snapValues.setZ(centerPosOnNeighbor.z);
                        }
                        break;
                    }
                }
            }
            object.setPosition(snapTest.snapValues);
            offsetAfterSnap = object.position.clone().sub(positionBeforeSnap);
            this.viewer.selectedObjects.forEach((selObject) => {
                const selectedObject = selObject;
                if (selectedObject.uuid === object.uuid) {
                    return;
                }
                selectedObject.translate(offsetAfterSnap);
            });
            orientedBBox = utils_1.Utils.getOBB(object);
            this.viewer.getDesignProMain().highlightingTool.clearConnectionHighlight();
            const orientedBBoxSnapped = utils_1.Utils.getOBB(object);
            const collisionTest = this.collisionProcessor.testCollisions(object, orientedBBoxSnapped, {
                excludeIDs: new Set([object.uuid]),
                firstFoundCollisionOnly: true,
            });
            if (collisionTest.result === true) {
                testResult = true;
            }
        }
        else {
            this.viewer.getDesignProMain().highlightingTool.clearConnectionHighlight();
        }
        if (testResult === true || snapTest.result === false) {
            const collisionTest = this.collisionProcessor.testCollisions(object, orientedBBox, {
                excludeIDs: new Set([object.uuid, ...object.getConnectionExcludeIDs()]),
                firstFoundCollisionOnly: true,
            });
            if (collisionTest.result === true) {
                return { collided: true, snapped: false };
            }
            object.setPosition(positionBeforeSnap);
            return { collided: false, snapped: false };
        }
        if (snapTest.result === true) {
            let slots = [];
            const slotsWithCenteredPoints = [];
            let points = [];
            const pointsCenteredOnSlots = [];
            slots.push(...snapTest.xSlots, ...snapTest.ySlots, ...snapTest.zSlots);
            points.push(...snapTest.xPoints, ...snapTest.yPoints, ...snapTest.zPoints);
            points.forEach((point) => {
                slots.forEach((slot) => {
                    if (slot.objectUUID !== object.uuid &&
                        slot.objectUUID !== point.objectUUID &&
                        utils_1.Utils.areVectorsEqual(slot.surfacePoint, point.origin, consts_1.consts.TWO_FLOAT_EPSILON)) {
                        slotsWithCenteredPoints.push(slot);
                        pointsCenteredOnSlots.push(point);
                    }
                });
            });
            slots = slots.filter((slot) => !slotsWithCenteredPoints.includes(slot));
            points = points.filter((point) => !pointsCenteredOnSlots.includes(point));
            if (utils_1.Utils.areEqual(snapTest.dist2Snap.x, 0)) {
                points = points.filter((point) => !snapTest.xPoints.includes(point));
            }
            if (utils_1.Utils.areEqual(snapTest.dist2Snap.y, 0)) {
                points = points.filter((point) => !snapTest.yPoints.includes(point));
            }
            if (utils_1.Utils.areEqual(snapTest.dist2Snap.z, 0)) {
                points = points.filter((point) => !snapTest.zPoints.includes(point));
            }
            // update snapping points of snapped object
            if (offsetAfterSnap) {
                for (let i = 0; i < points.length; i += 1) {
                    if (points[i].objectUUID === object.uuid) {
                        const newPoint = utils_1.SnappingMetadataComputingUtils.cloneSnappingPoint(points[i]);
                        newPoint.origin.add(offsetAfterSnap);
                        points[i] = newPoint;
                    }
                }
            }
            // updated slots on snapped object
            const slotsAfterSnap = (_c = object.getSlots()) !== null && _c !== void 0 ? _c : [];
            for (let i = 0; i < slots.length; i += 1) {
                const slotOnExtrusion = slotsAfterSnap.find((slot) => slot.objectUUID === slots[i].objectUUID && slot.slotNumber === slots[i].slotNumber);
                if (slotOnExtrusion) {
                    slots[i] = slotOnExtrusion;
                }
            }
            this.viewer.getDesignProMain().highlightingTool.highlightSlots(slots, false);
            this.viewer.getDesignProMain().highlightingTool.highlightSlots(slotsWithCenteredPoints, true);
            this.viewer.getDesignProMain().highlightingTool.highlightSnappingPoints(points);
        }
        return { collided: false, snapped: snapTest.result };
    }
    checkPlaneSnap(object, snapPos, maxSize = null, snapDir = null) {
        if (!this.active || !object.isSnappable) {
            return { result: false, dist: 0 };
        }
        const inverseDir = new three_1.Vector3(1, 1, 1);
        const position = snapPos.clone();
        let direction;
        if (snapDir !== null) {
            direction = snapDir.clone();
        }
        else {
            direction = object.directionNormal.clone();
        }
        const dir = direction.clone().multiply(direction);
        if ((0, utils_3d_1.areEqual)(dir.x, 1)) {
            inverseDir.x = 0;
        }
        else if ((0, utils_3d_1.areEqual)(dir.y, 1)) {
            inverseDir.y = 0;
        }
        else if ((0, utils_3d_1.areEqual)(dir.z, 1)) {
            inverseDir.z = 0;
        }
        const excludeUUIDs = new Set(object.getCollisionExcludeIds());
        this.viewer.selectedObjects.forEach((item) => {
            excludeUUIDs.add(item.uuid);
        });
        const test = this.testEdgeSnap(position, direction, consts_1.consts.SNAP_BY_PLANE_THRESHOLD, excludeUUIDs);
        this.clearPlaneHighlight();
        if (test.result === false || (maxSize !== null && maxSize !== 0 && test.distance > maxSize)) {
            return { result: false, dist: 0 };
        }
        let axisPosition = 0;
        const planePosition = position.clone().addScalar(test.distance).multiply(dir);
        axisPosition = planePosition.x + planePosition.y + planePosition.z;
        this.highlightPlaneSnap(object, consts_1.consts.SNAP_BY_PLANE_HIGHLIGHT_INDENT, axisPosition, direction, inverseDir, true);
        for (let i = 0; i < test.IDs.length; i += 1) {
            const testObject = this.scene.getObjectByProperty('uuid', test.IDs[i]);
            this.highlightPlaneSnap(testObject, consts_1.consts.SNAP_BY_PLANE_HIGHLIGHT_INDENT, axisPosition, direction, inverseDir);
        }
        return { result: true, dist: test.distance };
    }
    highlightPlaneSnap(object, planeIndent, positionOnAxis, snapDir, inverseDir, isBase = false) {
        const position = object.position.clone();
        const snapDirAbs = snapDir.clone().multiply(snapDir);
        const box = utils_1.Utils.getAABBox(object);
        const surfaceSize = new three_1.Vector2();
        const surfacePos = new three_1.Vector3(1, 1, -2);
        const arrayedVec = surfaceSize.toArray();
        let dirFilled = 0;
        if ((0, utils_3d_1.areEqual)(snapDir.x, 0)) {
            arrayedVec[dirFilled] = box.max.x - box.min.x;
            dirFilled += 1;
        }
        if ((0, utils_3d_1.areEqual)(snapDir.y, 0)) {
            arrayedVec[dirFilled] = box.max.y - box.min.y;
            dirFilled += 1;
        }
        if ((0, utils_3d_1.areEqual)(snapDir.z, 0)) {
            arrayedVec[dirFilled] = Math.abs(box.max.z - box.min.z);
        }
        arrayedVec[0] += planeIndent;
        arrayedVec[1] += planeIndent;
        let snapDirectionAxis;
        if ((0, utils_3d_1.areNotEqual)(snapDir.x, 0)) {
            surfaceSize.set(arrayedVec[0], arrayedVec[1]);
            snapDirectionAxis = 'x';
        }
        if ((0, utils_3d_1.areNotEqual)(snapDir.y, 0)) {
            surfaceSize.set(arrayedVec[1], arrayedVec[0]);
            snapDirectionAxis = 'y';
        }
        if ((0, utils_3d_1.areNotEqual)(snapDir.z, 0)) {
            surfaceSize.set(arrayedVec[1], arrayedVec[0]);
            snapDirectionAxis = 'z';
        }
        const geometry = new three_1.PlaneGeometry(surfaceSize.y, surfaceSize.x);
        const material = new three_1.MeshBasicMaterial({
            color: 0x06ac36,
            side: three_1.DoubleSide,
        });
        material.transparent = true;
        material.opacity = 0.5;
        const plane = new three_1.Mesh(geometry, material);
        plane.position.copy(surfacePos);
        if (isBase === true) {
            plane.name = 'planeSnap2';
        }
        else {
            plane.name = 'planeSnap1';
        }
        const planePos = position.clone().multiply(inverseDir).add(snapDirAbs.clone().multiplyScalar(positionOnAxis));
        planePos[snapDirectionAxis] = positionOnAxis;
        if (!(0, utils_3d_1.areVectorsCollinear)(object.directionNormal, snapDir)) {
            planePos.add(object.directionNormal.clone().multiplyScalar(object.getScale().z / 2));
        }
        plane.position.copy(planePos);
        if ((0, utils_3d_1.areNotEqual)(snapDir.x, 0)) {
            plane.rotateY(consts_1.consts.HALF_PI);
        }
        else if ((0, utils_3d_1.areNotEqual)(snapDir.y, 0)) {
            plane.rotateX(consts_1.consts.HALF_PI);
        }
        this.scene.add(plane);
    }
    clearPlaneHighlight() {
        // optimize this and store all objects in class array instead of searching for them
        for (let object = this.scene.getObjectByName(`planeSnap1`); object !== null && object !== undefined; object = this.scene.getObjectByName(`planeSnap1`)) {
            this.scene.remove(object);
        }
        this.scene.remove(this.scene.getObjectByName(`planeSnap2`));
    }
    addDirOnSnapLeader(direction, startPoint, distance, name) {
        direction.normalize();
        const endPoint = new three_1.Vector3();
        endPoint.addVectors(startPoint, direction.multiplyScalar(distance));
        const points = [];
        points.push(startPoint);
        points.push(endPoint);
        const geometry = new three_1.BufferGeometry().setFromPoints(points);
        const material = new three_1.LineBasicMaterial({ color: consts_1.consts.TERRACOTTA_COLOR });
        const line = new three_1.Line(geometry, material);
        line.name = name;
        this.scene.add(line);
    }
    testEdgeSnap(position, direction, threshold, exclude = new Set()) {
        let minDist = null;
        let snappedObjects = [];
        let testResult = false;
        const groupBoxes = this.collisionProcessor.getGroupBoxes();
        for (let i = 0; i < groupBoxes.length; i += 1) {
            const test = groupBoxes[i].testEdgeSnap(position, direction, threshold, exclude);
            if (test.result === true) {
                for (let k = 0; k < test.snappedObjects.length; k += 1) {
                    if (Math.abs(test.snappedObjects[k].distance) < Math.abs(minDist) || testResult === false) {
                        minDist = test.snappedObjects[k].distance;
                        testResult = true;
                    }
                }
                snappedObjects = snappedObjects.concat(test.snappedObjects);
            }
        }
        snappedObjects.sort((a, b) => {
            if (Math.abs(a.distance) > Math.abs(b.distance)) {
                return 1;
            }
            if (Math.abs(a.distance) < Math.abs(b.distance)) {
                return -1;
            }
            return 0;
        });
        for (let i = 0; i < snappedObjects.length; i += 1) {
            if (Math.abs(snappedObjects[i].distance - minDist) > threshold) {
                snappedObjects.splice(i, snappedObjects.length - i);
                break;
            }
        }
        const objectsID = [];
        for (let i = 0; i < snappedObjects.length; i += 1) {
            objectsID.push(snappedObjects[i].objectUUID);
        }
        return { result: testResult, distance: minDist, IDs: objectsID };
    }
    testBoxSnap(box, dir, halfSize, slots, snapPts, threshold, excludeIDs = new Set()) {
        let testResult = false;
        const dist2Snap = new three_1.Vector3();
        const snapMask = new three_1.Vector3();
        const snapValues = new three_1.Vector3();
        const snappedObjIndices = new three_1.Vector3();
        const snappedBoxIndices = new three_1.Vector3();
        const snappedDirX = new three_1.Vector3();
        const snappedDirY = new three_1.Vector3();
        const snappedDirZ = new three_1.Vector3();
        const groupBoxes = this.collisionProcessor.getGroupBoxes();
        let xSlots = [];
        let xPoints = [];
        let ySlots = [];
        let yPoints = [];
        let zSlots = [];
        let zPoints = [];
        for (let i = 0; i < groupBoxes.length; i += 1) {
            groupBoxes[i].objectsData.forEach((item) => {
                if ((0, utils_3d_1.isAngledExtrusion)((0, utils_3d_1.getObjectByUUID)(this.viewer.getScene(), item.objectUUID))) {
                    excludeIDs.add(item.objectUUID);
                }
            });
            const test = groupBoxes[i].testBoxSnap(box, dir, halfSize, slots, snapPts, threshold, excludeIDs);
            if (test.result === true) {
                testResult = true;
                // handle cases where distances are equal but snapDiff is different
                if ((test.snapMask.x !== 0 && test.dist2Snap.x < dist2Snap.x) || (0, utils_3d_1.areEqual)(snapMask.x, 0)) {
                    snapMask.x = test.snapMask.x;
                    dist2Snap.x = test.dist2Snap.x;
                    snapValues.x = test.snapValues.x;
                    snappedObjIndices.x = test.snappedIndices.x;
                    snappedBoxIndices.x = i;
                    snappedDirX.copy(test.snappedDirs.xSnap);
                    xSlots.length = 0;
                    xPoints.length = 0;
                    xSlots = xSlots.concat(test.xSlots);
                    xPoints = xPoints.concat(test.xPoints);
                }
                if ((test.snapMask.y !== 0 && test.dist2Snap.y < dist2Snap.y) || (0, utils_3d_1.areEqual)(snapMask.y, 0)) {
                    snapMask.y = test.snapMask.y;
                    dist2Snap.y = test.dist2Snap.y;
                    snapValues.y = test.snapValues.y;
                    snappedObjIndices.y = test.snappedIndices.y;
                    snappedBoxIndices.y = i;
                    snappedDirY.copy(test.snappedDirs.ySnap);
                    ySlots.length = 0;
                    yPoints.length = 0;
                    ySlots = ySlots.concat(test.ySlots);
                    yPoints = yPoints.concat(test.yPoints);
                }
                if ((test.snapMask.z !== 0 && test.dist2Snap.z < dist2Snap.z) || (0, utils_3d_1.areEqual)(snapMask.z, 0)) {
                    snapMask.z = test.snapMask.z;
                    dist2Snap.z = test.dist2Snap.z;
                    snapValues.z = test.snapValues.z;
                    snappedObjIndices.z = test.snappedIndices.z;
                    snappedBoxIndices.z = i;
                    snappedDirZ.copy(test.snappedDirs.zSnap);
                    zSlots.length = 0;
                    zPoints.length = 0;
                    zSlots = zSlots.concat(test.zSlots);
                    zPoints = zPoints.concat(test.zPoints);
                }
            }
        }
        return {
            result: testResult,
            snapValues,
            snapMask,
            dist2Snap,
            snappedObjIndices,
            snappedBoxIndices,
            snapLineDir: { byX: snappedDirX.negate(), byY: snappedDirY.negate(), byZ: snappedDirZ },
            xSlots,
            xPoints,
            ySlots,
            yPoints,
            zSlots,
            zPoints,
        };
    }
    snapObjectByBoundingBox(object, axis) {
        if (!object) {
            return;
        }
        const threshold = this.viewer.isImperialMeasurementUsed
            ? consts_1.consts.DEFAULT_EXTRUDE_STEP
            : consts_1.consts.DEFAULT_EXTRUDE_STEP_SI;
        const excludeIDs = [object.uuid, ...object.getConnectionExcludeIDs()];
        this.collisionProcessor.getGroupBoxes().forEach((groupBox) => {
            groupBox.snapObjectByBoundingBox(object, axis, excludeIDs, threshold);
        });
    }
}
exports.default = SnapHelper;
