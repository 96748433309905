"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const api_1 = require("~/store/api");
const events_1 = require("../utils/events");
const utils_1 = require("../utils/utils");
const actions_1 = require("../store/actions");
const selectors_1 = require("../store/selectors");
const consts_1 = require("../store/consts");
const types_1 = require("../store/types");
function useCatalogItemClickHandler() {
    const isDefaultFastenersWidgetShown = (0, react_redux_1.useSelector)((0, selectors_1.selectIsWidgetShown)(consts_1.WIDGETS.DEFAULT_FASTENER_WIDGET));
    const isCurrentFastenersWidgetShown = (0, react_redux_1.useSelector)((0, selectors_1.selectIsWidgetShown)(consts_1.WIDGETS.CURRENT_FASTENER_WIDGET));
    const isBOMShown = (0, react_redux_1.useSelector)((0, selectors_1.selectIsWidgetShown)(consts_1.WIDGETS.BOM_WIDGET));
    const isBOMEditMode = (0, react_redux_1.useSelector)(selectors_1.selectBOMEditMode);
    const { dispatchAddManually, dispatchSelectItem } = (0, redux_1.bindActionCreators)({
        dispatchAddManually: actions_1.addManually,
        dispatchSelectItem: actions_1.selectItem,
    }, (0, react_redux_1.useDispatch)());
    return (item) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        if (isDefaultFastenersWidgetShown || isCurrentFastenersWidgetShown) {
            return;
        }
        if (isBOMShown && isBOMEditMode) {
            let panelSize;
            const partSize = (0, utils_1.getPartSize)(item);
            const uuid = (0, utils_1.uuidv4)();
            if (((_a = item.componentType) === null || _a === void 0 ? void 0 : _a.name) === types_1.ITEM_TYPE.PANEL) {
                panelSize = (0, utils_1.getPanelSize)(item);
            }
            document.body.dispatchEvent((0, events_1.addItemsToBOMEvent)([
                {
                    actionType: types_1.ACTION_TYPE.ADD,
                    itemNumber: item.item_number,
                    size: partSize,
                    panelSize,
                    manuallyAdded: true,
                    uuid,
                },
            ]));
            dispatchAddManually({
                id: item.id,
                description: item.description,
                item_number: item.item_number,
                size: partSize,
                panelSize,
                uuid: [uuid],
                price: item.price,
                componentType: null,
            });
            return;
        }
        if ((!item.compatibleExtrusions || ((_b = item.compatibleExtrusions) === null || _b === void 0 ? void 0 : _b.length) === 0) &&
            (((_c = item.componentType) === null || _c === void 0 ? void 0 : _c.name) === types_1.ITEM_TYPE.STEM_CASTER || ((_d = item.componentType) === null || _d === void 0 ? void 0 : _d.name) === types_1.ITEM_TYPE.FOOT)) {
            const threadSize = (0, utils_1.getItemProperty)(item, 'Thread size');
            let compatibleExtrusions = [];
            if (threadSize) {
                try {
                    compatibleExtrusions = yield (0, api_1.getCompatibleExtrusionsForTapMachiningService)(threadSize);
                }
                catch (error) {
                    (0, events_1.showNotification)({
                        message: (0, utils_1.errorify)(error),
                        type: 'error',
                    });
                }
            }
            // eslint-disable-next-line no-param-reassign
            item = Object.assign(Object.assign({}, item), { compatibleExtrusions });
        }
        document.body.dispatchEvent((0, events_1.addCatalogItemEvent)(item));
        dispatchSelectItem(item);
    });
}
exports.default = useCatalogItemClickHandler;
