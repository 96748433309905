"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOOL_STATE = exports.DRAWING_ANGLED = exports.REDO = exports.UNDO = exports.SELECTED_ITEM = exports.SELECT_TOOL = void 0;
exports.SELECT_TOOL = 'SELECT_TOOL';
exports.SELECTED_ITEM = 'SELECTED_ITEM';
exports.UNDO = 'UNDO';
exports.REDO = 'REDO';
exports.DRAWING_ANGLED = 'DRAWING_ANGLED';
// eslint-disable-next-line no-shadow
var TOOL_STATE;
(function (TOOL_STATE) {
    TOOL_STATE["DRAW"] = "draw";
    TOOL_STATE["NAVIGATE"] = "navigate";
    TOOL_STATE["ROTATE_SCENE"] = "rotateScene";
    TOOL_STATE["PAN"] = "pan";
    TOOL_STATE["SHOW_FASTENERS"] = "showFasteners";
    TOOL_STATE["MEASURE"] = "measure";
})(TOOL_STATE = exports.TOOL_STATE || (exports.TOOL_STATE = {}));
