"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_dom_1 = require("react-dom");
function Portal({ children, target = document.body }) {
    const [container] = (0, react_1.useState)(() => {
        const el = document.createElement('div');
        if (target === document.body) {
            el.style.position = 'absolute';
            el.style.top = '0';
            el.style.left = '0';
            el.style.right = '0';
            el.style.bottom = '0';
            el.style.pointerEvents = 'none';
        }
        return el;
    });
    (0, react_1.useEffect)(() => {
        if (target) {
            target.appendChild(container);
        }
        return () => {
            if (target) {
                target.removeChild(container);
            }
        };
    }, [target]);
    return (0, react_dom_1.createPortal)(children, container);
}
exports.default = Portal;
