"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnchorResultType = void 0;
const three_1 = require("three");
const consts_1 = require("../consts/consts");
// eslint-disable-next-line import/no-cycle
const utils_3d_1 = require("../utils/utils.3d");
const tempVector = new three_1.Vector3();
// eslint-disable-next-line no-shadow
var AnchorResultType;
(function (AnchorResultType) {
    AnchorResultType[AnchorResultType["empty"] = 0] = "empty";
    AnchorResultType[AnchorResultType["buildPlane"] = 1] = "buildPlane";
    AnchorResultType[AnchorResultType["slot"] = 2] = "slot";
    AnchorResultType[AnchorResultType["snapPt"] = 3] = "snapPt";
    AnchorResultType[AnchorResultType["box"] = 4] = "box";
})(AnchorResultType = exports.AnchorResultType || (exports.AnchorResultType = {}));
class AnchorDetection {
    static tryGetSnappingPointsData(anchorTestResult) {
        return anchorTestResult.resultType === AnchorResultType.snapPt
            ? anchorTestResult.resultData
            : null;
    }
    static tryGetSlotsData(anchorTestResult) {
        return anchorTestResult.resultType === AnchorResultType.slot ? anchorTestResult.resultData : null;
    }
    // eslint-disable-next-line class-methods-use-this
    testByPoint(obj, point, normal) {
        const anchorTestResult = {
            resultType: AnchorResultType.empty,
            intersectionPoint: new three_1.Vector3(),
            intersectionNormal: new three_1.Vector3(),
            resultData: null,
            objectUUID: null,
        };
        const anchorFound = false;
        let slideDir = obj.normal.clone().multiply(obj.normal);
        slideDir.add(normal.clone().multiply(normal));
        slideDir = (0, utils_3d_1.invertVector)(slideDir);
        if ((0, utils_3d_1.areVectorsCollinear)(obj.normal, normal)) {
            if (slideDir.z !== 0) {
                slideDir.z = 0;
            }
            else if (slideDir.y !== 0) {
                slideDir.y = 0;
            }
        }
        const intersectionPtAxisValue = point.clone().dot(slideDir);
        if (obj.slots.length) {
            const slotIndices = [];
            const slots = [];
            for (let s = 0; s < obj.slots.length; s += 1) {
                if ((0, utils_3d_1.areVectorsEqual)(normal, obj.slots[s].normal)) {
                    slotIndices.push({
                        index: s,
                        dist: tempVector.subVectors(point, obj.slots[s].surfacePoint).projectOnPlane(normal).length(),
                    });
                }
            }
            if (slotIndices.length !== 0) {
                slotIndices.sort((a, b) => Math.abs(a.dist) - Math.abs(b.dist));
                for (let i = 0; i < slotIndices.length; i += 1) {
                    slots.push(obj.slots[slotIndices[i].index]);
                }
                anchorTestResult.resultType = AnchorResultType.slot;
                anchorTestResult.intersectionPoint.copy(point);
                anchorTestResult.intersectionNormal.copy(normal);
                anchorTestResult.resultData = slots;
                anchorTestResult.objectUUID = obj.objectUUID;
                return anchorTestResult;
            }
        }
        const snappingPoints = obj.outboundSnapPts.concat(obj.inboundSnapPts);
        if (!anchorFound && snappingPoints.length) {
            const snapPtIndices = [];
            const snapPts = [];
            for (let p = 0; p < snappingPoints.length; p += 1) {
                if ((0, utils_3d_1.areVectorsEqual)(normal, snappingPoints[p].normal)) {
                    snapPtIndices.push({
                        index: p,
                        dist: snappingPoints[p].origin.distanceToSquared(point),
                    });
                }
            }
            if (snapPtIndices.length !== 0) {
                snapPtIndices.sort((a, b) => {
                    return a.dist - b.dist;
                });
                for (let i = 0; i < snapPtIndices.length; i += 1) {
                    snapPts.push(snappingPoints[snapPtIndices[i].index]);
                }
                anchorTestResult.resultType = AnchorResultType.snapPt;
                anchorTestResult.intersectionPoint.copy(point);
                anchorTestResult.intersectionNormal.copy(normal);
                anchorTestResult.resultData = snapPts;
                anchorTestResult.objectUUID = obj.objectUUID;
                return anchorTestResult;
            }
        }
        if (!anchorFound) {
            let minSlotDist = null;
            let slotIndex = 0;
            let minSnapPtDist = null;
            let snapPtIndex = 0;
            let slotOrSnapPt = null;
            const slot = false;
            const snapPt = true;
            if (obj.slots.length) {
                for (let s = 0; s < obj.slots.length; s += 1) {
                    const slotDist = Math.abs(obj.slots[s].surfacePoint.clone().dot(obj.slots[s].normal) - point.clone().dot(obj.slots[s].normal));
                    if (minSlotDist === null || minSlotDist > slotDist) {
                        minSlotDist = slotDist;
                        slotIndex = s;
                    }
                }
            }
            if (snappingPoints.length) {
                for (let p = 0; p < snappingPoints.length; p += 1) {
                    const snapPtDist = Math.abs(snappingPoints[p].origin.clone().dot(snappingPoints[p].normal) -
                        point.clone().dot(snappingPoints[p].normal));
                    if (minSnapPtDist === null || minSnapPtDist > snapPtDist) {
                        minSnapPtDist = snapPtDist;
                        snapPtIndex = p;
                    }
                }
            }
            if (minSlotDist !== null && minSnapPtDist !== null) {
                if (minSnapPtDist < minSlotDist) {
                    slotOrSnapPt = snapPt;
                }
                else {
                    slotOrSnapPt = slot;
                }
            }
            else if (minSlotDist !== null) {
                slotOrSnapPt = slot;
            }
            else if (minSnapPtDist !== null) {
                slotOrSnapPt = snapPt;
            }
            if (minSlotDist !== null || minSnapPtDist !== null) {
                if (slotOrSnapPt === slot) {
                    const slotIndices = [];
                    const slots = [];
                    let foundSlots = false;
                    for (let s = 0; s < obj.slots.length; s += 1) {
                        if ((0, utils_3d_1.areVectorsEqual)(obj.slots[slotIndex].normal, obj.slots[s].normal)) {
                            foundSlots = true;
                            slotIndices.push({
                                index: s,
                                dist: Math.abs(obj.slots[s].origin.clone().dot(slideDir) - intersectionPtAxisValue),
                            });
                        }
                    }
                    if (foundSlots) {
                        slotIndices.sort((a, b) => {
                            if (Math.abs(a.dist) > Math.abs(b.dist)) {
                                return 1;
                            }
                            if (Math.abs(a.dist) < Math.abs(b.dist)) {
                                return -1;
                            }
                            return 0;
                        });
                        for (let i = 0; i < slotIndices.length; i += 1) {
                            slots.push(obj.slots[slotIndices[i].index]);
                        }
                        anchorTestResult.resultType = AnchorResultType.slot;
                        anchorTestResult.intersectionPoint.copy(point);
                        anchorTestResult.intersectionNormal.copy(normal);
                        anchorTestResult.resultData = slots;
                        anchorTestResult.objectUUID = obj.objectUUID;
                        return anchorTestResult;
                    }
                }
                else {
                    const snapPtIndices = [];
                    const snapPts = [];
                    let foundSnapPt = false;
                    for (let p = 0; p < snappingPoints.length; p += 1) {
                        if ((0, utils_3d_1.areVectorsEqual)(snappingPoints[snapPtIndex].normal, snappingPoints[p].normal)) {
                            foundSnapPt = true;
                            snapPtIndices.push({
                                index: p,
                                dist: snappingPoints[p].origin.distanceToSquared(point),
                            });
                        }
                    }
                    if (foundSnapPt) {
                        snapPtIndices.sort((a, b) => {
                            return a.dist - b.dist;
                        });
                        for (let i = 0; i < snapPtIndices.length; i += 1) {
                            snapPts.push(snappingPoints[snapPtIndices[i].index]);
                        }
                    }
                    anchorTestResult.resultType = AnchorResultType.snapPt;
                    anchorTestResult.intersectionPoint.copy(point);
                    anchorTestResult.intersectionNormal.copy(normal);
                    anchorTestResult.resultData = snapPts;
                    anchorTestResult.objectUUID = obj.objectUUID;
                    return anchorTestResult;
                }
            }
        }
        if (!anchorFound) {
            const box = obj.objectBox;
            let vec;
            if (normal.clone().dot(consts_1.unitVec) > 0) {
                vec = box.max.clone();
            }
            else {
                vec = box.min.clone();
            }
            const absNormal = normal.clone().multiply(normal);
            vec.multiply(absNormal);
            const origin = new three_1.Vector3();
            box.getCenter(origin);
            origin.copy(origin.clone().multiply((0, utils_3d_1.invertVector)(absNormal)).add(vec));
            const boxSize = box.max.clone().sub(box.min);
            anchorTestResult.resultType = AnchorResultType.box;
            anchorTestResult.intersectionPoint.copy(point);
            anchorTestResult.intersectionNormal.copy(normal);
            anchorTestResult.resultData = {
                origin,
                normal,
                width: boxSize.dot(slideDir),
                height: boxSize.dot(obj.normal.clone().multiply(obj.normal)),
            };
            anchorTestResult.objectUUID = obj.objectUUID;
        }
        return anchorTestResult;
    }
}
exports.default = AnchorDetection;
