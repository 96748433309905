"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetPositionCommand = void 0;
/* eslint-disable no-param-reassign */
const three_1 = require("three");
// eslint-disable-next-line import/no-cycle
const types_1 = require("~/store/types");
// eslint-disable-next-line import/no-cycle
const Command_1 = require("./Command");
// eslint-disable-next-line import/no-cycle
const events_1 = require("../../../utils/events");
// eslint-disable-next-line import/prefer-default-export
class SetPositionCommand extends Command_1.ViewerCommand {
    constructor(objects, viewer, historyService) {
        super();
        this.oldPositions = [];
        this.newPositions = [];
        this.type = 'SetPositionCommand';
        this.name = 'Set Position';
        this.updatable = true;
        this.objects = objects;
        this.viewer = viewer;
        this.historyService = historyService;
        this.objects.forEach((object) => {
            this.newPositions.push(object.position.clone());
            this.oldPositions.push(object.positionOnDown.clone());
        });
    }
    moveObjects(newPositions, oldPositions, appliedFromHistory) {
        if (this.objects.length) {
            this.historyService.emit(`${this.objects[0].type}Moved`, this.objects); // TODO: should be rewritten in future
        }
        this.objects.forEach((movingObject, index) => {
            movingObject.positionOnDown = oldPositions[index].clone();
            movingObject.quaternionOnDown = movingObject.quaternion.clone();
            movingObject.setPosition(newPositions[index]);
            movingObject.updateMatrixWorld(true);
            this.viewer.collisionProcessor.notifyObjectUpdated(movingObject);
        });
        const connectionsPromises = this.viewer.getDesignProMain().updateConnectionsAndShowNotifications(...this.objects);
        Promise.all(connectionsPromises).then((connectionObjects) => {
            this.viewer.collisionProcessor.updateCollisionsForObjectsAndConnections(this.objects, connectionObjects.filter((connectionObject) => connectionObject !== null));
            const events = [];
            this.objects.forEach((object) => {
                var _a, _b, _c;
                const connections = this.viewer.getDesignProMain().connectionManager.getRelatedConnections(object);
                events.push({
                    actionType: types_1.ACTION_TYPE.MOVE,
                    size: object.getLength(),
                    geomkernelPosition: object.position,
                    position: (_a = object.originalPosition) !== null && _a !== void 0 ? _a : object.position,
                    itemNumber: (_b = object.partDetails) === null || _b === void 0 ? void 0 : _b.itemNumber,
                    itemType: object.type,
                    connections,
                    uuid: object.uuid,
                    slicePlanes: object.slicePlanes,
                    geomkernelPanelSize: object.getSize(),
                    panelSize: (_c = object.originalPanelSize) !== null && _c !== void 0 ? _c : object.getSize(),
                    rotation: {
                        x: object.quaternion.x,
                        y: object.quaternion.y,
                        z: object.quaternion.z,
                        w: object.quaternion.w,
                    },
                    appliedFromHistory,
                });
            });
            document.body.dispatchEvent((0, events_1.updateItemsToBOMEvent)(events));
        });
    }
    execute(appliedFromHistory) {
        this.moveObjects(this.newPositions, this.oldPositions, appliedFromHistory);
    }
    undo() {
        this.moveObjects(this.oldPositions, this.newPositions, false);
    }
    update(command) {
        this.objects = [...command.objects];
        this.newPositions = [...command.newPositions];
        this.oldPositions = [...command.oldPositions];
    }
    toJSON() {
        const positionsData = [];
        this.objects.forEach((object, index) => {
            positionsData.push({
                objectUuid: object.uuid,
                oldPosition: this.oldPositions[index].toArray(),
                newPosition: this.newPositions[index].toArray(),
            });
        });
        return {
            type: this.type,
            id: this.id,
            name: this.name,
            positionsData,
        };
    }
    fromJSON(json) {
        this.getFromJSON(json);
        this.objects = [];
        this.oldPositions = [];
        this.newPositions = [];
        json.positionsData.forEach((positionData) => {
            this.objects.push(this.viewer.getObjectById(positionData.objectUuid));
            this.oldPositions.push(new three_1.Vector3().fromArray(positionData.oldPosition));
            this.newPositions.push(new three_1.Vector3().fromArray(positionData.newPosition));
        });
    }
}
exports.SetPositionCommand = SetPositionCommand;
