"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOADING_STATE = void 0;
// eslint-disable-next-line import/prefer-default-export, no-shadow
var LOADING_STATE;
(function (LOADING_STATE) {
    LOADING_STATE[LOADING_STATE["LOADING"] = 0] = "LOADING";
    LOADING_STATE[LOADING_STATE["COMPLETE"] = 1] = "COMPLETE";
    LOADING_STATE[LOADING_STATE["FAILED"] = 2] = "FAILED";
    LOADING_STATE[LOADING_STATE["COMPLETE_ALL"] = 3] = "COMPLETE_ALL";
    LOADING_STATE["WS_CONNECTED"] = "Connected";
    LOADING_STATE["WS_RECONNECTING"] = "Reconnecting";
    LOADING_STATE["WS_RECONNECTED"] = "Reconnected";
    LOADING_STATE["WS_DISCONNECTED"] = "Disconnected";
    LOADING_STATE["WS_CONNECTION_ERROR"] = "Connection error";
})(LOADING_STATE = exports.LOADING_STATE || (exports.LOADING_STATE = {}));
