"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldName = exports.FieldType = exports.IVisibleFrameType = void 0;
// eslint-disable-next-line no-shadow
var IVisibleFrameType;
(function (IVisibleFrameType) {
    IVisibleFrameType[IVisibleFrameType["DEFAULT"] = 0] = "DEFAULT";
    IVisibleFrameType[IVisibleFrameType["BOX_ITEM"] = 1] = "BOX_ITEM";
    IVisibleFrameType[IVisibleFrameType["DOOR_ITEM"] = 2] = "DOOR_ITEM";
    IVisibleFrameType[IVisibleFrameType["FRAME_ITEM"] = 3] = "FRAME_ITEM";
})(IVisibleFrameType = exports.IVisibleFrameType || (exports.IVisibleFrameType = {}));
// eslint-disable-next-line no-shadow
var FieldType;
(function (FieldType) {
    FieldType[FieldType["SELECT"] = 0] = "SELECT";
    FieldType[FieldType["INPUT"] = 1] = "INPUT";
})(FieldType = exports.FieldType || (exports.FieldType = {}));
// eslint-disable-next-line no-shadow
var FieldName;
(function (FieldName) {
    FieldName["seriesType"] = "seriesType";
    FieldName["series"] = "series";
    FieldName["profileType"] = "profileType";
    FieldName["extrusion"] = "extrusion";
    FieldName["handle"] = "handle";
    FieldName["hinges"] = "hinges";
    FieldName["latch"] = "latch";
    FieldName["panel"] = "panel";
    FieldName["width"] = "width";
    FieldName["height"] = "height";
    FieldName["length"] = "length";
})(FieldName = exports.FieldName || (exports.FieldName = {}));
