"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useIntersectionObserver(ref) {
    const observerRef = (0, react_1.useRef)(null);
    const [isOnScreen, setIsOnScreen] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        observerRef.current = new IntersectionObserver(([entry]) => {
            setIsOnScreen(entry.isIntersecting);
        });
    }, [ref]);
    (0, react_1.useEffect)(() => {
        var _a;
        if (ref.current) {
            (_a = observerRef === null || observerRef === void 0 ? void 0 : observerRef.current) === null || _a === void 0 ? void 0 : _a.observe(ref.current);
        }
        return () => {
            var _a;
            (_a = observerRef === null || observerRef === void 0 ? void 0 : observerRef.current) === null || _a === void 0 ? void 0 : _a.disconnect();
        };
    });
    return isOnScreen;
}
exports.default = useIntersectionObserver;
