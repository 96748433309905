"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-param-reassign */
const three_1 = require("three");
// eslint-disable-next-line import/no-cycle
const consts_1 = require("../consts");
// eslint-disable-next-line import/no-cycle
const utils_1 = require("../utils");
const tempVector = new three_1.Vector3();
const tempVector2 = new three_1.Vector3();
class HighlightingTool {
    constructor(viewer) {
        this.snappingHighlightMeshes = [];
        this.highlightedInRedOuterBoxes = [];
        this.highlightedInRedObjects = [];
        this.viewer = viewer;
    }
    highlightSlots(slots, snappedToCenter = false) {
        var _a, _b;
        const extrusionMap = new Map();
        for (let i = 0; i < slots.length; i += 1) {
            const slot = slots[i];
            if (!slot) {
                // eslint-disable-next-line no-continue
                continue;
            }
            const origin = new three_1.Vector3();
            origin.copy(slot.surfacePoint);
            const { length } = slot;
            const width = slot.width || 0.2;
            const halfWidthVector = new three_1.Vector3().crossVectors(slot.normal, slot.direction).multiplyScalar(width / 2);
            const halfLengthVector = slot.direction.clone().multiplyScalar(length / 2);
            const surfacePointWithOffset = slot.surfacePoint
                .clone()
                .add(tempVector.copy(slot.normal).multiplyScalar(consts_1.consts.SLOT_HIGHLIGHT_OFFSET));
            const corner1 = surfacePointWithOffset.clone().add(halfLengthVector).add(halfWidthVector);
            const corner2 = surfacePointWithOffset
                .clone()
                .add(halfLengthVector)
                .add(tempVector.copy(halfWidthVector).negate());
            const corner3 = surfacePointWithOffset
                .clone()
                .add(tempVector.copy(halfLengthVector).negate())
                .add(halfWidthVector);
            const corner4 = surfacePointWithOffset
                .clone()
                .add(tempVector.copy(halfLengthVector).negate())
                .add(tempVector2.copy(halfWidthVector).negate());
            const points = [corner1, corner2, corner3, corner3, corner2, corner4];
            if (!extrusionMap.has(slot.objectUUID)) {
                const extrusion = this.viewer.getObjectById(slot.objectUUID);
                extrusionMap.set(slot.objectUUID, extrusion !== null && extrusion !== void 0 ? extrusion : null);
            }
            const clippingPlanes = (_b = (_a = extrusionMap
                .get(slot.objectUUID)) === null || _a === void 0 ? void 0 : _a.slicePlanes) === null || _b === void 0 ? void 0 : _b.map((slicePlane) => new three_1.Plane(slicePlane.normal, slicePlane.constant));
            const geometry = new three_1.BufferGeometry().setFromPoints(points);
            const material = new three_1.MeshBasicMaterial({
                color: snappedToCenter ? consts_1.consts.SLOT_CENTER_FOUND_COLOR : consts_1.consts.TERRACOTTA_COLOR,
                side: three_1.DoubleSide,
                clippingPlanes,
            });
            material.transparent = true;
            material.opacity = 0.75;
            const plane = new three_1.Mesh(geometry, material);
            plane.renderOrder = -1;
            this.snappingHighlightMeshes.push(plane);
            this.viewer.getScene().add(plane);
        }
    }
    highlightObject(object, newColor = consts_1.consts.COLLISION_BOX_COLOR, colorOpacity = consts_1.consts.COLOR_LERP_OPACITY) {
        var _a;
        if (this.highlightedInRedObjects.some((o) => o.object.uuid === object.uuid)) {
            return;
        }
        const color = (_a = object.mesh.material) === null || _a === void 0 ? void 0 : _a.color;
        if (!color) {
            this.highlightOuterBox(object.outerBox, newColor);
            return;
        }
        const result = {
            object,
            prevColor: color.clone(),
        };
        color.lerp(new three_1.Color(newColor), colorOpacity);
        this.highlightedInRedObjects.push(result);
    }
    highlightOuterBox(outerBox, color = consts_1.consts.COLLISION_BOX_COLOR) {
        if (this.highlightedInRedOuterBoxes.some((box) => box.outerBox.uuid === outerBox.uuid)) {
            return;
        }
        const prevColor = outerBox.material.color;
        const prevVisibility = outerBox.visible;
        outerBox.material = new three_1.MeshBasicMaterial({
            color,
            opacity: consts_1.consts.OUTER_BOX_OPACITY,
            transparent: true,
        });
        this.highlightedInRedOuterBoxes.push({
            outerBox,
            prevColor,
            prevVisibility,
        });
        outerBox.visible = true;
    }
    clearConnectionHighlight() {
        this.snappingHighlightMeshes.forEach((mesh) => mesh.removeFromParent());
        this.snappingHighlightMeshes = [];
        this.highlightedInRedOuterBoxes.forEach(({ outerBox, prevVisibility, prevColor }) => {
            outerBox.material = new three_1.MeshBasicMaterial({
                color: prevColor,
                opacity: consts_1.consts.OUTER_BOX_OPACITY,
                transparent: true,
            });
            outerBox.visible = prevVisibility;
        });
        this.highlightedInRedOuterBoxes = [];
        this.highlightedInRedObjects.forEach((object) => {
            object.object.mesh.material.color = object.prevColor;
        });
        this.highlightedInRedObjects = [];
    }
    highlightSnappingPoints(snappingPoints) {
        const objectsMap = {};
        snappingPoints.forEach((point) => {
            if (objectsMap[point.objectUUID]) {
                objectsMap[point.objectUUID].push(point);
            }
            else {
                objectsMap[point.objectUUID] = [point];
            }
        });
        Object.keys(objectsMap).forEach((objectUUID) => {
            const object = utils_1.Utils.getObjectByUUID(this.viewer.getScene(), objectUUID);
            if ((object === null || object === void 0 ? void 0 : object.type) === 'Extrusion') {
                this.highlightSnappingPointsForOneExtrusion(objectsMap[objectUUID], object);
            }
            else {
                this.highlightSnappingPointsForComponent(objectsMap[objectUUID]);
            }
        });
    }
    highlightSnappingPointsForOneExtrusion(snappingPoints, extrusion) {
        const sides = [
            consts_1.consts.DIRECTIONS_LIST[consts_1.consts.POSITIVE_AXES.positiveX],
            consts_1.consts.DIRECTIONS_LIST[consts_1.consts.POSITIVE_AXES.positiveY],
            consts_1.consts.DIRECTIONS_LIST[consts_1.consts.POSITIVE_AXES.positiveZ],
        ].filter((axis) => !utils_1.Utils.areVectorsCollinear(axis, extrusion.directionNormal));
        const extrusionSize = utils_1.Utils.getAABBoxSize(extrusion);
        let maxSideVector = sides[0];
        let maxSide = sides[0].dot(extrusionSize);
        const side2Dot = sides[1].dot(extrusionSize);
        if (side2Dot > maxSide) {
            // eslint-disable-next-line prefer-destructuring
            maxSideVector = sides[1];
            maxSide = side2Dot;
        }
        const extrusionSnappingPoints = extrusion.getInboundSnappingPointsRaw();
        if (!extrusionSnappingPoints) {
            return;
        }
        const sideForOnePlane = maxSide / utils_1.Utils.getNumberOfUniquePointsOnAxis(extrusionSnappingPoints, maxSideVector) +
            consts_1.consts.SNAP_HIGHLIGHT_INDENT;
        snappingPoints.forEach((point) => {
            const geometry = new three_1.PlaneGeometry(sideForOnePlane, sideForOnePlane);
            const material = new three_1.MeshBasicMaterial({
                color: consts_1.consts.TERRACOTTA_COLOR,
                side: three_1.DoubleSide,
            });
            material.transparent = true;
            material.opacity = 0.75;
            const plane = new three_1.Mesh(geometry, material);
            plane.setRotationFromQuaternion(new three_1.Quaternion().setFromUnitVectors(consts_1.consts.DIRECTIONS_LIST[consts_1.consts.AXES.positiveZ], snappingPoints[0].normal));
            plane.position.copy(point.origin);
            this.snappingHighlightMeshes.push(plane);
            this.viewer.getScene().add(plane);
        });
    }
    highlightSnappingPointsForComponent(snappingPoints) {
        snappingPoints.forEach((point) => {
            const plane = new three_1.Mesh(new three_1.PlaneGeometry(consts_1.consts.SQUARE_SIDE_FOR_SNAPPING_HIGHLIGHT_FOR_COMPONENTS, consts_1.consts.SQUARE_SIDE_FOR_SNAPPING_HIGHLIGHT_FOR_COMPONENTS), new three_1.MeshBasicMaterial({
                color: consts_1.consts.TERRACOTTA_COLOR,
                side: three_1.DoubleSide,
            }));
            plane.quaternion.setFromUnitVectors(tempVector.set(0, 0, 1), point.normal);
            plane.position.copy(point.origin);
            this.snappingHighlightMeshes.push(plane);
            this.viewer.getScene().add(plane);
        });
    }
}
exports.default = HighlightingTool;
