"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MACHINING_SERVICE = exports.DISCOUNTED_PRICE_SHOWN_KEY = exports.ADDING_MS_NOTIFICATION = exports.PLASTIC_PANELS = exports.SHIP_TO_ADDRESS_PLACEHOLDER = exports.SHIP_TO_ADDRESS = exports.INPUT_NAME = exports.HEADER_ITEM = exports.ALL_STATES_NAME = exports.PROSPECT = exports.ADMIN_COMPANY = exports.DISCOUNT_TYPE_ID = exports.DISCOUNT_TYPE = exports.SHORTCUTS = exports.ITEM_STOCK_SIZE_PROPERTIES = exports.unitsTable = exports.unitsConvertTable = exports.EDIT_ALL = exports.EDIT_CURRENT = exports.PRECISION_IN_LABELS = exports.MS_IN_SECOND = exports.MAX_SIZE_PRECISION = exports.itemPlaceholderSrc = exports.logoLink = void 0;
/* eslint-disable no-shadow */
const consts_1 = require("~/components/Cad3d/consts");
exports.logoLink = 'https://www.tslots.com/';
exports.itemPlaceholderSrc = '/assets/item-placeholder-90-90.png';
exports.MAX_SIZE_PRECISION = 3;
exports.MS_IN_SECOND = 1000;
exports.PRECISION_IN_LABELS = 3;
exports.EDIT_CURRENT = 'current';
exports.EDIT_ALL = 'all';
exports.unitsConvertTable = {
    'IN': consts_1.consts.INCH_TO_MM,
    'MM': consts_1.consts.MM_TO_INCH,
    'FT': consts_1.consts.FT_TO_M,
    'M': consts_1.consts.M_TO_FT,
    'SQ FT': consts_1.consts.SQ_FT_TO_SQ_M,
    'SQ M': consts_1.consts.SQ_M_TO_SQ_FT,
};
exports.unitsTable = {
    'IN': 'MM',
    'MM': 'IN',
    'FT': 'M',
    'M': 'FT',
    'SQ FT': 'SQ M',
    'SQ M': 'SQ FT',
};
exports.ITEM_STOCK_SIZE_PROPERTIES = {
    STOCK_WIDTH: 'Stock width',
    STOCK_LENGTH: 'Stock length',
};
exports.SHORTCUTS = [
    { name: 'Undo last action', value: 'CTRL + Z' },
    { name: 'Redo last action', value: 'CTRL + Y' },
    { name: 'Select multiple parts', value: 'CTRL+Click' },
    { name: 'Select all', value: 'CTRL + A' },
    { name: 'Copy selected parts', value: 'CTRL + C' },
    { name: 'Paste parts', value: 'CTRL + V' },
    { name: 'Rotate extrusion preview', value: 'SPACE' },
    { name: 'Exit drawing mode', value: 'ESC' },
    { name: 'Delete selected parts', value: 'Delete' },
];
var DISCOUNT_TYPE;
(function (DISCOUNT_TYPE) {
    DISCOUNT_TYPE["DISTRIBUTOR"] = "distributor";
    DISCOUNT_TYPE["CUSTOMER"] = "customer";
    DISCOUNT_TYPE["SPECIAL_PRICE_AGREEMENT"] = "special price agreement";
})(DISCOUNT_TYPE = exports.DISCOUNT_TYPE || (exports.DISCOUNT_TYPE = {}));
var DISCOUNT_TYPE_ID;
(function (DISCOUNT_TYPE_ID) {
    DISCOUNT_TYPE_ID[DISCOUNT_TYPE_ID["DISTRIBUTOR"] = 1] = "DISTRIBUTOR";
    DISCOUNT_TYPE_ID[DISCOUNT_TYPE_ID["CUSTOMER"] = 2] = "CUSTOMER";
    DISCOUNT_TYPE_ID[DISCOUNT_TYPE_ID["SPECIAL_PRICE_AGREEMENT"] = 3] = "SPECIAL_PRICE_AGREEMENT";
})(DISCOUNT_TYPE_ID = exports.DISCOUNT_TYPE_ID || (exports.DISCOUNT_TYPE_ID = {}));
exports.ADMIN_COMPANY = {
    companyName: 'TSLOTS By Bonnell Aluminum',
    email: 'tslotsinfo@bonnellaluminum.com',
    phoneNumber: '800-824-2049',
    publicAddress: {
        addressLine: '1201 S. Freeport Industrial Pkwy',
        addressLine2: '',
        city: 'Clearfield',
        country: {
            name: 'United States',
        },
        state: 'UT',
        zip: '84015',
    },
};
exports.PROSPECT = 'Prospect';
exports.ALL_STATES_NAME = 'All States';
exports.HEADER_ITEM = 'header-item';
exports.INPUT_NAME = 'input-name';
exports.SHIP_TO_ADDRESS = 'ship-to-address';
exports.SHIP_TO_ADDRESS_PLACEHOLDER = 'edit-project-form.placeholder.ship-to-address';
exports.PLASTIC_PANELS = 'Plastic Panels';
exports.ADDING_MS_NOTIFICATION = 'Press the Add button on BOM widget to apply chosen machining service to corresponding items';
exports.DISCOUNTED_PRICE_SHOWN_KEY = 'discountedPriceShown';
var MACHINING_SERVICE;
(function (MACHINING_SERVICE) {
    MACHINING_SERVICE["ANCHOR_FASTENER_COUNTERBORE"] = "Anchor fastener counterbore";
    MACHINING_SERVICE["BOLT_CONNECTOR_MACHINING"] = "Bolt connector machining";
    MACHINING_SERVICE["BUTT_FASTENER_COUNTERBORE"] = "Butt fastener counterbore";
    MACHINING_SERVICE["CENTRAL_CONNECTOR_MACHINING"] = "Central connector machining";
    MACHINING_SERVICE["COUNTER_BORE_WITH_THROUGH_HOLE"] = "Counter bore with through hole";
    MACHINING_SERVICE["CUT_TO_LENGTH"] = "Cut to length";
    MACHINING_SERVICE["DELUXE_DOOR_HANDLE_MILLING_SERVICE"] = "Deluxe door handle milling service";
    MACHINING_SERVICE["DRILL_ACCESS_HOLE"] = "Drill access hole";
    MACHINING_SERVICE["FURNITURE_STYLE_DOOR_HANDLE_MILLING_SERVICE"] = "Furniture style door handle milling service";
    MACHINING_SERVICE["MISCELLANEOUS_CUT_CHARGE"] = "Miscellaneous cut charge";
    MACHINING_SERVICE["MITER_SAW_CUT"] = "Miter saw cut";
    MACHINING_SERVICE["MITER_SAW_CUTS_AND_COUNTERBORE"] = "Miter saw cuts and counterbore";
    MACHINING_SERVICE["TAP_PROFILE_END"] = "Tap profile end";
    MACHINING_SERVICE["PLASTIC_PANEL_MACHINING_CLEARANCE_HOLE"] = "Plastic panel machining - clearance hole";
    MACHINING_SERVICE["PLASTIC_PANEL_MACHINING_COUNTERSINK_HOLE"] = "Plastic panel machining - countersink hole";
    MACHINING_SERVICE["MISCELLANEOUS_MACHINING"] = "Miscellaneous machining";
    MACHINING_SERVICE["PANEL_NOTCHING"] = "Panel notching";
    MACHINING_SERVICE["ASSEMBLY_CHARGE"] = "Assembly charge";
    MACHINING_SERVICE["CUT_TO_SIZE"] = "Cut to size";
})(MACHINING_SERVICE = exports.MACHINING_SERVICE || (exports.MACHINING_SERVICE = {}));
