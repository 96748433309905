"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REQUEST_FAILED_STATUS_CODE = exports.SEND_CHANGE_EMAIL_LINK = exports.SEND_RESTORE_PASSWORD_LINK = exports.VALIDATE_TOKEN = exports.HANDLE_AUTH_RESPONSE = exports.SET_AUTH_DATA = exports.INITIAL_REQUEST = exports.SIGNUP_FAILED = exports.ACTION_FAILED = exports.LOGIN_FAILED = exports.LOGIN_SUCCESS = exports.SETMODE = exports.SIGNUP = exports.LOGOUT = exports.LOGIN = void 0;
exports.LOGIN = 'LOGIN';
exports.LOGOUT = 'LOGOUT';
exports.SIGNUP = 'SIGNUP';
exports.SETMODE = 'SET_MODE';
exports.LOGIN_SUCCESS = 'LOGIN_SUCCESS';
exports.LOGIN_FAILED = 'LOGIN_FAILED';
exports.ACTION_FAILED = 'ACTION_FAILED';
exports.SIGNUP_FAILED = 'SIGNUP_FAILED';
exports.INITIAL_REQUEST = 'INITIAL_REQUEST';
exports.SET_AUTH_DATA = 'SET_AUTH_DATA';
exports.HANDLE_AUTH_RESPONSE = 'HANDLE_AUTH_RESPONSE';
exports.VALIDATE_TOKEN = 'VALIDATE_TOKEN';
exports.SEND_RESTORE_PASSWORD_LINK = 'SEND_RESTORE_PASSWORD_LINK';
exports.SEND_CHANGE_EMAIL_LINK = 'SEND_CHANGE_EMAIL_LINK';
exports.REQUEST_FAILED_STATUS_CODE = 'REQUEST_FAILED_STATUS_CODE';
