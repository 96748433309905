"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Edge = void 0;
class Edge {
    constructor(startVertex, endVertex) {
        this.startVertex = startVertex;
        this.endVertex = endVertex;
    }
}
exports.Edge = Edge;
class EdgeUtils {
    static collectBBoxEdges(part) {
        const { vertices } = part.selectionBox;
        const edges = [];
        const lowerIndexArray = [0, 1, 2, 3, 0];
        const upperIndexArray = [4, 5, 6, 7, 4];
        const lowerToUpperOffset = 4;
        for (let vertexIndex = 0; vertexIndex < lowerIndexArray.length - 1; vertexIndex += 1) {
            const lowerArrayFirstIndex = lowerIndexArray[vertexIndex];
            const lowerArraySecondIndex = lowerIndexArray[vertexIndex + 1];
            const upperArrayFirstIndex = upperIndexArray[vertexIndex];
            const upperArraySecondIndex = upperIndexArray[vertexIndex + 1];
            edges.push(new Edge(vertices[lowerArrayFirstIndex], vertices[lowerArraySecondIndex]));
            edges.push(new Edge(vertices[upperArrayFirstIndex], vertices[upperArraySecondIndex]));
            edges.push(new Edge(vertices[vertexIndex], vertices[vertexIndex + lowerToUpperOffset]));
        }
        return edges;
    }
    static getPointOnClosestEdge(edges, referencePoint) {
        let pointOnClosestEdge = null;
        let shortestDistance = null;
        edges.forEach((edge) => {
            const pointOnEdge = this.getPointOnEdge(edge, referencePoint);
            const distanceToPointOnEdge = referencePoint.distanceTo(pointOnEdge);
            const isShorterDistance = !shortestDistance || distanceToPointOnEdge < shortestDistance;
            if (isShorterDistance) {
                shortestDistance = distanceToPointOnEdge;
                pointOnClosestEdge = pointOnEdge;
            }
        });
        return pointOnClosestEdge;
    }
    static getPointOnEdge(edge, referencePoint) {
        const { startVertex, endVertex } = edge;
        const edgeDirection = endVertex.clone().sub(startVertex).normalize();
        const startVertexToReferencePointVec = referencePoint.clone().sub(startVertex);
        const projectionDistance = startVertexToReferencePointVec.dot(edgeDirection);
        return startVertex.clone().add(edgeDirection.clone().multiplyScalar(projectionDistance));
    }
    static createEdgesFromPoints(points) {
        const edges = [];
        for (let pointIndex = 0; pointIndex < points.length - 1; pointIndex += 1) {
            edges.push(new Edge(points[pointIndex], points[pointIndex + 1]));
        }
        edges.push(new Edge(points[points.length - 1], points[0]));
        return edges;
    }
}
exports.default = EdgeUtils;
