"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPreconfiguredPayload = void 0;
/* eslint-disable jsx-a11y/alt-text */
const types_1 = require("~/store/types");
const preconfiguredFrameSendFramePayload = (frame) => {
    const { extrusion, panel, width, height } = frame;
    const payload = {
        extrusion,
        panel,
        width,
        height,
    };
    console.log('Frame------->\n', payload);
    return payload;
};
const preconfiguredFrameSendDoorPayload = (door) => {
    const { extrusion, panel, handle, hinges, latch, width, height } = door;
    const payload = {
        extrusion,
        panel,
        handle,
        hinges,
        latch,
        width,
        height,
    };
    console.log('Door------->\n', payload);
    return payload;
};
const preconfiguredFrameSendBoxPayload = (box) => {
    const { extrusion, length, width, height } = box;
    const payload = {
        extrusion,
        length,
        width,
        height,
    };
    console.log('Box------->\n', payload);
    return payload;
};
function GetPreconfiguredPayload(id, obj) {
    switch (id) {
        case types_1.IVisibleFrameType.BOX_ITEM:
            return preconfiguredFrameSendBoxPayload(obj);
        case types_1.IVisibleFrameType.FRAME_ITEM:
            return preconfiguredFrameSendFramePayload(obj);
        case types_1.IVisibleFrameType.DOOR_ITEM:
            return preconfiguredFrameSendDoorPayload(obj);
    }
    return {};
}
exports.GetPreconfiguredPayload = GetPreconfiguredPayload;
