"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_UNABLED = exports.SET_DIASBLED = exports.RESET_QUOTE = exports.UPDATE_QUOTE_ERROR = exports.UPDATE_QUOTE_VALUE = exports.SUBMIT_QUOTE_ERROR = exports.SUBMIT_QUOTE_SUCCESS = exports.SUBMIT_QUOTE = void 0;
exports.SUBMIT_QUOTE = 'SUBMIT_QUOTE';
exports.SUBMIT_QUOTE_SUCCESS = 'SUBMIT_QUOTE_SUCCESS';
exports.SUBMIT_QUOTE_ERROR = 'SUBMIT_QUOTE_ERROR';
exports.UPDATE_QUOTE_VALUE = 'UPDATE_QUOTE_VALUE';
exports.UPDATE_QUOTE_ERROR = 'UPDATE_QUOTE_ERROR';
exports.RESET_QUOTE = 'RESET_QUOTE';
exports.SET_DIASBLED = 'SET_DIASBLED';
exports.SET_UNABLED = 'SET_UNABLED';
