"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const three_1 = require("three");
// eslint-disable-next-line import/no-cycle
const types_1 = require("../../../store/types");
const consts_1 = require("../consts");
// eslint-disable-next-line import/no-cycle
const models_1 = require("../models");
// eslint-disable-next-line import/no-cycle
const utils_1 = require("../utils");
const appliedColor = 0x06ac36;
const unappliedColor = 0xe15e37;
const radius = 0.5;
const height = 1.5;
const radicalSegments = 30;
const offset = 1;
class CapHelper {
    constructor(viewer, snappingTool) {
        this.enabled = false;
        this.cones = [];
        this.viewer = viewer;
        this.scene = this.viewer.getScene();
        this.snappingTool = snappingTool;
        this.viewer.getRendererDOMElement().addEventListener('mousemove', this.onMouseMove.bind(this), false);
        this.viewer.getRendererDOMElement().addEventListener('mousedown', this.onMouseDown.bind(this), false);
    }
    findCone(mouseX, mouseY) {
        const rect = this.viewer.getRendererDOMElement().getBoundingClientRect();
        const mouse = new three_1.Vector2();
        mouse.x = ((mouseX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((mouseY - rect.top) / rect.height) * 2 + 1;
        this.viewer.raycaster = new three_1.Raycaster();
        this.viewer.raycaster.params.Line = { threshold: 0.1 };
        this.viewer.raycaster.setFromCamera(mouse, this.viewer.getCamera());
        const intersects = this.viewer.raycaster.intersectObjects(this.cones.map((data) => data.mesh), true);
        if (intersects.length > 0) {
            return this.cones.find((coneData) => coneData.mesh.uuid === intersects[0].object.uuid);
        }
        return null;
    }
    drawCone(extrusion, endFace) {
        var _a;
        for (let itemIndex = 0; itemIndex < extrusion.connectedItems.length; itemIndex += 1) {
            const connectedItem = extrusion.connectedItems[itemIndex];
            if (connectedItem.componentType !== types_1.COMPONENT_TYPE.END_CAP) {
                const connectedObject = this.viewer.getObjectById(connectedItem.uuid);
                if (connectedObject.connectedItems.find((item) => item.uuid === extrusion.uuid && item.endFace === endFace)) {
                    return;
                }
            }
        }
        const obb = utils_1.Utils.getOBB(extrusion);
        const { center } = obb;
        const { halfSize } = obb;
        const extrusionDir = extrusion.directionNormal;
        const geometry = new three_1.ConeGeometry(radius, height, radicalSegments);
        const material = new three_1.MeshBasicMaterial({ color: appliedColor });
        material.transparent = true;
        material.opacity = 0.5;
        const coneMesh = new three_1.Mesh(geometry, material);
        coneMesh.renderOrder = -1;
        coneMesh.geometry.center();
        coneMesh.geometry.attributes.position.needsUpdate = true;
        coneMesh.geometry.computeBoundingSphere();
        coneMesh.geometry.computeBoundingBox();
        coneMesh.geometry.rotateX(Math.PI / 2);
        // eslint-disable-next-line no-underscore-dangle
        const _offset = new three_1.Vector3();
        (_a = coneMesh.geometry.boundingBox) === null || _a === void 0 ? void 0 : _a.getCenter(_offset).negate();
        coneMesh.geometry.translate(_offset.x, _offset.y, _offset.z);
        coneMesh.translateX(center.x);
        coneMesh.translateY(center.y);
        coneMesh.translateZ(center.z);
        coneMesh.translateOnAxis(extrusionDir, endFace === 'A' ? -halfSize.z : halfSize.z);
        const endFaceCenter = coneMesh.position.clone();
        coneMesh.translateOnAxis(extrusionDir, endFace === 'A' ? -offset : offset);
        coneMesh.up = new three_1.Vector3(0, 0, 1);
        const aabbCenter = utils_1.Utils.getAABBoxCenter(extrusion);
        coneMesh.lookAt(aabbCenter);
        const endConnections = extrusion.connectedItems.filter((item) => item.componentType === types_1.COMPONENT_TYPE.END_CAP);
        let endFaceSnapped = false;
        let snappedCap = null;
        for (let i = 0; i < endConnections.length; i += 1) {
            const capObject = utils_1.Utils.getObjectByUUID(this.scene, endConnections[i].uuid);
            const distance = endFaceCenter.distanceTo(capObject.position);
            if (utils_1.Utils.areEqual(distance, 0)) {
                endFaceSnapped = true;
                snappedCap = endConnections[i].uuid;
                break;
            }
        }
        coneMesh.material.color.setHex(endFaceSnapped ? appliedColor : unappliedColor);
        this.cones.push({
            mesh: coneMesh,
            extrusionId: extrusion.uuid,
            side: endFace,
            capId: snappedCap,
            itemNumber: '',
        });
    }
    drawCones(cap, itemSeriesIds) {
        this.enabled = true;
        this.cap = cap;
        const sceneChildren = this.scene.children;
        const extrusions = sceneChildren.filter((child) => {
            if (!(child instanceof models_1.Extrusion)) {
                return false;
            }
            const intersection = child.partDetails.seriesIds.filter((item1) => itemSeriesIds.some((item2) => item1 === item2));
            const bbSize = child.partMetadata.boundingBoxSize;
            const capSize = cap.partMetadata.boundingBoxSize;
            const canBeSnapped = (utils_1.Utils.areEqual(bbSize.x, capSize.x, consts_1.consts.THREE_FLOAT_EPSILON) &&
                utils_1.Utils.areEqual(bbSize.y, capSize.y, consts_1.consts.THREE_FLOAT_EPSILON)) ||
                (utils_1.Utils.areEqual(bbSize.x, capSize.y, consts_1.consts.THREE_FLOAT_EPSILON) &&
                    utils_1.Utils.areEqual(bbSize.y, capSize.x, consts_1.consts.THREE_FLOAT_EPSILON));
            return intersection.length > 0 && canBeSnapped && child.partDetails.isConvex === cap.partDetails.isConvex;
        });
        extrusions.forEach((extrusion) => {
            this.drawCone(extrusion, 'A');
            this.drawCone(extrusion, 'B');
        });
        this.cones.forEach((cone) => {
            this.scene.add(cone.mesh);
        });
    }
    removeCones() {
        this.enabled = false;
        if (this.cones.length === 0) {
            return;
        }
        this.cones.forEach((cone) => {
            this.scene.remove(cone.mesh);
        });
        this.cones = [];
    }
    updateCones() {
        if (!this.enabled) {
            return;
        }
        this.removeCones();
        this.drawCones(this.cap, this.cap.partDetails.seriesIds);
    }
    removeEventListeners() {
        this.viewer.getRendererDOMElement().removeEventListener('mousemove', this.onMouseMove.bind(this), false);
        this.viewer.getRendererDOMElement().removeEventListener('mousedown', this.onMouseDown.bind(this), false);
    }
    onMouseDown(event) {
        if (event.button !== 0) {
            return;
        }
        if (this.cones.length === 0) {
            return;
        }
        const cone = this.findCone(event.clientX, event.clientY);
        if (!cone) {
            return;
        }
        const sceneChildren = this.scene.children;
        const extrusion = sceneChildren.find((child) => {
            return child.uuid === cone.extrusionId;
        });
        if (!extrusion) {
            return;
        }
        const material = cone.mesh.material;
        if (!material) {
            return;
        }
        if (material.color.getHex() === appliedColor) {
            material.color.setHex(unappliedColor);
            const capObject = sceneChildren.find((child) => {
                return child.uuid === cone.capId;
            });
            if (capObject) {
                this.viewer.getDesignProMain().removeObjects([capObject.uuid]);
            }
            return;
        }
        material.color.setHex(appliedColor);
        const capCopy = this.cap.clone();
        cone.capId = capCopy.uuid;
        capCopy.setPosition(extrusion.position);
        capCopy.setRotation(extrusion.quaternion);
        if (cone.side === 'A') {
            capCopy.rotateX(Math.PI);
            if (capCopy.partDetails.isConvex) {
                capCopy.rotateZ(Math.PI / 2);
            }
        }
        else {
            capCopy.translateOnAxis(consts_1.consts.DP_UP_DIR, extrusion.getLength());
        }
        this.scene.add(capCopy);
        this.snappingTool.setObject(capCopy);
        this.snappingTool.neighborObject = capCopy;
        this.snappingTool.isSnappedToNeighborsEnd = true;
        this.snappingTool.getObject().directionNormal = capCopy.directionNormal.clone();
        this.snappingTool.neighborObject.directionNormal = extrusion.directionNormal.clone();
        this.viewer.getDesignProMain().finalizeObjectsInsertion([capCopy]);
    }
    onMouseMove(event) {
        if (this.cones.length === 0) {
            return;
        }
        const cone = this.findCone(event.clientX, event.clientY);
        if (cone) {
            cone.mesh.material.transparent = false;
        }
        else {
            this.cones
                .map((data) => data.mesh)
                .forEach((coneMesh) => {
                // eslint-disable-next-line no-param-reassign
                coneMesh.material.transparent = true;
            });
        }
    }
}
exports.default = CapHelper;
