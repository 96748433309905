"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MEASUREMENT_UNIT = exports.FASTENER_TYPES_OF_END_TO_END_GROUP = exports.FASTENER_TYPES_OF_SCREWS_GROUP = exports.FASTENER_TYPES_OF_END_GROUP = exports.FASTENER_TYPES_OF_DOUBLE_ANCHOR_GROUP = exports.FASTENER_TYPES_OF_GROUP_A = exports.FASTENER_TYPES = exports.ACTION_ON_FASTENER_VISUALIZATION_FAILURE = exports.ITEM_PROPERTY_NAMES = void 0;
exports.ITEM_PROPERTY_NAMES = {
    FASTENER_TYPE: 'Fastener type',
    STOCK_WIDTH: 'Stock width',
    STOCK_LENGTH: 'Stock length',
    SIZE: 'Size',
};
exports.ACTION_ON_FASTENER_VISUALIZATION_FAILURE = {
    DO_NOTHING: 'Do nothing',
    APPLY_SPHERE: 'Apply sphere',
};
exports.FASTENER_TYPES = {
    ANCHOR_FASTENER: 'Anchor fasteners',
    DOUBLE_ANCHOR_FASTENER: 'Double anchor fasteners',
    MILLING_CONNECTOR: 'Milling connectors',
    ZERO_DEGREE_CENTRAL_CONNECTOR: '0° central connectors',
    NINETY_DEGREE_CENTRAL_CONNECTOR: '90° central connectors',
    INNER_CONNECTOR: 'Inner connectors',
    T_MATIC_CONNECTOR: 'T-matic connectors',
    END_FASTENER: 'End fasteners',
    DOUBLE_END_FASTENERS: 'Double end fasteners',
    CONNECTION_SCREWS: 'Connection screws',
    SELF_TAPPING_SCREWS: 'Self tapping screws',
    SOCKET_HEAD_CAP_SCREWS: 'Socket head cap screws',
    SOCKET_HEAD_CAP_SCREW_COMBINATION_FASTENERS: 'Socket head cap screw combination fasteners',
    BOLT_CONNECTOR: 'Bolt connectors',
    PLATE_CONNECTOR: 'Plate connectors',
    BUTT_FASTENER: 'Butt fasteners',
    T_MATIC_BUTT_CONNECTOR: 'T-matic butt connectors',
    CONNECTOR_LINKS: 'Connector links',
};
exports.FASTENER_TYPES_OF_GROUP_A = [
    exports.FASTENER_TYPES.ANCHOR_FASTENER,
    exports.FASTENER_TYPES.MILLING_CONNECTOR,
    exports.FASTENER_TYPES.NINETY_DEGREE_CENTRAL_CONNECTOR,
    exports.FASTENER_TYPES.INNER_CONNECTOR,
    exports.FASTENER_TYPES.T_MATIC_CONNECTOR,
];
exports.FASTENER_TYPES_OF_DOUBLE_ANCHOR_GROUP = [
    exports.FASTENER_TYPES.DOUBLE_ANCHOR_FASTENER,
    exports.FASTENER_TYPES.BOLT_CONNECTOR,
];
exports.FASTENER_TYPES_OF_END_GROUP = [exports.FASTENER_TYPES.END_FASTENER, exports.FASTENER_TYPES.DOUBLE_END_FASTENERS];
exports.FASTENER_TYPES_OF_SCREWS_GROUP = [exports.FASTENER_TYPES.CONNECTION_SCREWS, exports.FASTENER_TYPES.SELF_TAPPING_SCREWS];
exports.FASTENER_TYPES_OF_END_TO_END_GROUP = [
    exports.FASTENER_TYPES.BUTT_FASTENER,
    exports.FASTENER_TYPES.T_MATIC_BUTT_CONNECTOR,
    exports.FASTENER_TYPES.CONNECTOR_LINKS,
];
exports.MEASUREMENT_UNIT = {
    IMPERIAL: 'Imperial',
    METRIC: 'Metric',
};
// eslint-disable-next-line import/no-cycle
__exportStar(require("./account/types"), exports);
// eslint-disable-next-line import/no-cycle
__exportStar(require("./authentication/types"), exports);
// eslint-disable-next-line import/no-cycle
__exportStar(require("./catalog/types"), exports);
// eslint-disable-next-line import/no-cycle
__exportStar(require("./catalogMeta/types"), exports);
// eslint-disable-next-line import/no-cycle
__exportStar(require("./common/types"), exports);
__exportStar(require("./contextmenu/types"), exports);
// eslint-disable-next-line import/no-cycle
__exportStar(require("./data/types"), exports);
__exportStar(require("./loading/types"), exports);
// eslint-disable-next-line import/no-cycle
__exportStar(require("./project/types"), exports);
// eslint-disable-next-line import/no-cycle
__exportStar(require("./quote/types"), exports);
__exportStar(require("./selectedObjects/types"), exports);
// eslint-disable-next-line import/no-cycle
__exportStar(require("./tools/types"), exports);
__exportStar(require("./transformModes/types"), exports);
__exportStar(require("./unitOfMeasurement/types"), exports);
// eslint-disable-next-line import/no-cycle
__exportStar(require("./user/types"), exports);
// eslint-disable-next-line import/no-cycle
__exportStar(require("./widgets/behavior/types"), exports);
// eslint-disable-next-line import/no-cycle
__exportStar(require("./widgets/content/types"), exports);
// eslint-disable-next-line import/no-cycle
__exportStar(require("./customerManagement/types"), exports);
