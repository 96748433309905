"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const effects_1 = require("redux-saga/effects");
const actions_1 = require("~/store/actions");
const events_1 = require("~/utils/events");
const utils_1 = require("~/utils/utils");
const api_1 = require("../api");
const actions_2 = require("./actions");
const consts_1 = require("./consts");
function* trySetUserData({ payload }) {
    if (payload) {
        try {
            const user = yield (0, effects_1.call)(api_1.getUserData);
            yield (0, effects_1.put)((0, actions_2.setUserDataSuccess)(user));
        }
        catch (error) {
            console.log(error);
        }
    }
    else {
        yield (0, effects_1.put)((0, actions_1.setDefaultBOM)());
        yield (0, effects_1.put)((0, actions_1.getCurrentProjectSuccess)(null));
        yield (0, effects_1.put)((0, actions_2.setUserDataSuccess)(null));
    }
}
function* tryUpdateUserInfo({ payload }) {
    try {
        const response = yield (0, effects_1.call)(api_1.updateUserInfo, payload);
        yield (0, effects_1.put)((0, actions_1.handleAuthResponse)(response.data));
    }
    catch (error) {
        console.log(error);
    }
}
function* tryUpdateDistributorInfo({ payload }) {
    try {
        yield (0, effects_1.call)(api_1.updateDistributorInfo, payload);
    }
    catch (error) {
        console.log(error);
    }
}
function* tryDeleteUser({ payload }) {
    var _a;
    const accessToken = yield (0, effects_1.call)(utils_1.getToken);
    const refreshToken = yield (0, effects_1.call)(utils_1.getRefreshToken);
    try {
        const response = yield (0, effects_1.call)(api_1.deleteUserByUserId, payload, accessToken, refreshToken);
        if (((_a = response.action) === null || _a === void 0 ? void 0 : _a.status) === 'success') {
            yield (0, effects_1.put)((0, actions_1.setAuthData)(response.data));
            yield (0, effects_1.put)((0, actions_2.setDeleteUserSuccess)());
        }
    }
    catch (error) {
        console.log(error);
    }
}
function* tryTryTransferOwnership({ payload }) {
    const accessToken = yield (0, effects_1.call)(utils_1.getToken);
    const refreshToken = yield (0, effects_1.call)(utils_1.getRefreshToken);
    try {
        const response = yield (0, effects_1.call)(api_1.transferOwnershipUserId, payload, accessToken, refreshToken);
        if (response.status === 'success') {
            yield (0, effects_1.put)((0, actions_1.setAuthData)(response.data));
            yield (0, effects_1.put)((0, actions_2.setUserDataAction)(true));
            yield (0, effects_1.call)(events_1.showNotification, {
                message: response.result,
                type: 'success',
            });
        }
    }
    catch (error) {
        console.log(error);
    }
}
function* trySendInvitations({ payload, }) {
    const accessToken = yield (0, effects_1.call)(utils_1.getToken);
    const refreshToken = yield (0, effects_1.call)(utils_1.getRefreshToken);
    try {
        const response = yield (0, effects_1.call)(api_1.sendInvitationsByUsersEmail, payload.emails, payload.distributorId, accessToken, refreshToken);
        if (response.errors && response.errors.length > 0) {
            yield (0, effects_1.put)((0, actions_2.responseErrors)(response.errors));
        }
        if (response.status === 'success' && payload.navigateBack) {
            yield (0, effects_1.call)(events_1.showNotification, {
                message: `${payload.emails.length} invite${payload.emails.length > 1 ? 's were' : ' was'} sent`,
                type: 'success',
            });
            yield (0, effects_1.call)(payload.navigateBack);
        }
    }
    catch (error) {
        console.log(error);
    }
}
function* tryResendInvitation({ payload }) {
    const accessToken = yield (0, effects_1.call)(utils_1.getToken);
    const refreshToken = yield (0, effects_1.call)(utils_1.getRefreshToken);
    try {
        const response = yield (0, effects_1.call)(api_1.resendInvitationByUserEmail, [payload.email], payload.distributorId, accessToken, refreshToken);
        if (response.status === 'success') {
            yield (0, effects_1.call)(events_1.showNotification, {
                message: 'Invitation was successfully resent',
                type: 'success',
            });
        }
    }
    catch (error) {
        console.log(error);
    }
}
function* tryGetUserDiscounts() {
    try {
        const response = yield (0, effects_1.call)(api_1.getUserDiscounts);
        yield (0, effects_1.put)((0, actions_2.setUserDiscounts)(response.data));
    }
    catch (error) {
        console.log(error);
    }
}
function* rootSaga() {
    yield (0, effects_1.all)([
        (0, effects_1.takeLatest)(consts_1.SET_USER_DATA, trySetUserData),
        (0, effects_1.takeLatest)(consts_1.UPDATE_USER_INFO, tryUpdateUserInfo),
        (0, effects_1.takeLatest)(consts_1.DELETE_USER, tryDeleteUser),
        (0, effects_1.takeLatest)(consts_1.TRANSFER_OWNERSHIP, tryTryTransferOwnership),
        (0, effects_1.takeLatest)(consts_1.UPDATE_DISTRIBUTOR_INFO, tryUpdateDistributorInfo),
        (0, effects_1.takeLatest)(consts_1.INVITE_TEAM_MEMBER, trySendInvitations),
        (0, effects_1.takeLatest)(consts_1.RESEND_INVITATION, tryResendInvitation),
        (0, effects_1.takeLatest)(consts_1.GET_USER_DISCOUNTS, tryGetUserDiscounts),
    ]);
}
exports.default = rootSaga;
