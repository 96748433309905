"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable  @typescript-eslint/no-explicit-any */
const react_1 = require("react");
const axiosInstance_1 = require("~/store/api/axiosInstance");
function useInfiniteFetch({ query, page, field = '', sort = 'ASC', forceRefresh = false, search = '', limit = 21, // TODO: remove limit from here; use only page
additionalDataHandler, }) {
    const [loading, setLoading] = (0, react_1.useState)(true);
    const [error, setError] = (0, react_1.useState)(false);
    const [baseUrl, setBaseUrl] = (0, react_1.useState)(query);
    const [result, setResult] = (0, react_1.useState)(null);
    const [isMoreData, setIsMoreData] = (0, react_1.useState)(true);
    (0, react_1.useEffect)(() => {
        setBaseUrl(query);
    }, [query]);
    const sendQuery = (0, react_1.useCallback)((url) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        setError(false);
        yield axiosInstance_1.axiosInstance
            .get(url)
            .then(({ data: { data: response, additionalData } }) => {
            if (!response || !response.length) {
                throw new Error();
            }
            additionalDataHandler === null || additionalDataHandler === void 0 ? void 0 : additionalDataHandler(response, additionalData);
            if (response.length > limit) {
                response.pop();
            }
            else {
                setIsMoreData(false);
            }
            setResult((previousValue) => [...new Set([...(previousValue || []), ...response])]);
            setLoading(false);
        })
            .catch(() => {
            setLoading(false);
            setError(true);
        });
    }), []);
    const createQuery = () => {
        if (forceRefresh || isMoreData || page === 0) {
            setLoading(true);
            let filter = '';
            if (field) {
                filter = `&field=${field}&sort=${sort}&search=${search}`;
            }
            const queryParams = `?page=${page}&limit=${limit + 1}&offset=${limit}`;
            if (page === 0) {
                setResult(null);
            }
            sendQuery(baseUrl + queryParams + filter);
        }
    };
    (0, react_1.useEffect)(() => {
        setIsMoreData(true);
        setResult(null);
    }, [query, sendQuery, baseUrl, field, sort, search, limit]);
    (0, react_1.useEffect)(() => {
        createQuery();
    }, [query, sendQuery, page, baseUrl, field, sort, search, limit]);
    (0, react_1.useEffect)(() => {
        if (forceRefresh) {
            setIsMoreData(true);
            setResult(null);
            const timer = setTimeout(() => {
                createQuery();
            }, 500);
            return () => clearTimeout(timer);
        }
        return undefined;
    }, [forceRefresh]);
    return { loading, error, result, isMoreData, setResult };
}
exports.default = useInfiniteFetch;
