"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectUserDataLoadingState = exports.selectCurrentUserDiscounts = exports.selectCurrentUserAddresses = exports.selectIsDirectAccount = exports.selectCurrentUserRole = exports.selectResponseError = exports.selectCurrentUserDistributorId = exports.selectCurrentUserDistributor = exports.selectCurrentUserEmail = exports.selectTeamRole = exports.selectCurrentUser = void 0;
const reselect_1 = require("reselect");
// eslint-disable-next-line import/prefer-default-export
exports.selectCurrentUser = (0, reselect_1.createSelector)((state) => state.user, (user) => user.user);
exports.selectTeamRole = (0, reselect_1.createSelector)((state) => state.user, (user) => { var _a; return (_a = user.user) === null || _a === void 0 ? void 0 : _a.roleId; });
exports.selectCurrentUserEmail = (0, reselect_1.createSelector)((state) => state.user, (user) => { var _a; return (_a = user.user) === null || _a === void 0 ? void 0 : _a.email; });
exports.selectCurrentUserDistributor = (0, reselect_1.createSelector)((state) => state.user, (user) => { var _a; return (_a = user.user) === null || _a === void 0 ? void 0 : _a.distributor; });
exports.selectCurrentUserDistributorId = (0, reselect_1.createSelector)((state) => state.user, (user) => { var _a; return (_a = user.user) === null || _a === void 0 ? void 0 : _a.distributorId; });
exports.selectResponseError = (0, reselect_1.createSelector)((state) => state.user, (user) => user.errors);
exports.selectCurrentUserRole = (0, reselect_1.createSelector)((state) => state.user, (user) => { var _a, _b; return (_b = (_a = user.user) === null || _a === void 0 ? void 0 : _a.userType) === null || _b === void 0 ? void 0 : _b.name; });
exports.selectIsDirectAccount = (0, reselect_1.createSelector)((state) => state.user, (user) => { var _a, _b; return (_b = (_a = user.user) === null || _a === void 0 ? void 0 : _a.distributor) === null || _b === void 0 ? void 0 : _b.isDirectAccount; });
exports.selectCurrentUserAddresses = (0, reselect_1.createSelector)((state) => state.user, (user) => { var _a; return (_a = user.user) === null || _a === void 0 ? void 0 : _a.shippingAddresses; });
exports.selectCurrentUserDiscounts = (0, reselect_1.createSelector)((state) => state.user, (user) => user.discounts);
exports.selectUserDataLoadingState = (0, reselect_1.createSelector)((state) => state.user, (user) => user.isLoading);
