"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateSnappingPoint = exports.cloneSlotsArray = exports.cloneSnappingPointsArray = exports.updateSlot = exports.cloneSlot = exports.cloneSnappingPoint = exports.calculateSlots = exports.calculateRecommendedFastenerPoints = exports.calculateInboundSnappingPoints = exports.calculateOutboundSnappingPoints = exports.calculateSnappingPoints = void 0;
const three_1 = require("three");
const Utils = __importStar(require("./utils.3d"));
const PartMetadata_1 = require("../classes/PartMetadata");
const consts_1 = require("../consts");
const tempRaycaster = new three_1.Raycaster();
const tempVector = new three_1.Vector3();
const tempVector2 = new three_1.Vector3();
const tempQuaternion = new three_1.Quaternion();
function computePointNormalByBBox(objectBBox, point) {
    const pointMaskWithMaxCorner = Utils.compareFilter(objectBBox.max, point);
    const pointMaskWithMinCorner = Utils.compareFilter(objectBBox.min, point).negate();
    return pointMaskWithMaxCorner.add(pointMaskWithMinCorner);
}
function computePointNormal(objectBBox, point, oppositePoint) {
    let normal = computePointNormalByBBox(objectBBox, point);
    if (Utils.areVectorsEqual(normal, Utils.zeroVector) && oppositePoint) {
        normal = computePointNormalByBBox(objectBBox, oppositePoint).negate();
    }
    return normal;
}
function calculateSnappingPoints(object, type) {
    const snappingPointsData = [];
    let snappingPointOriginsSource = null;
    let oppositePointOriginsSource = null;
    let snappingPointPredefinedNormals = null;
    switch (type) {
        case PartMetadata_1.SnappingPointType.Inbound:
            snappingPointOriginsSource = object.getInboundSnappingPointsRaw();
            oppositePointOriginsSource = object.getRecommendedFastenerPointsRaw();
            snappingPointPredefinedNormals = object.partMetadata.inboundSnappingPointsNormals;
            break;
        case PartMetadata_1.SnappingPointType.Outbound:
            snappingPointOriginsSource = object.getOutboundSnappingPointsRaw();
            snappingPointPredefinedNormals = object.partMetadata.outboundSnappingPointsNormals;
            break;
        case PartMetadata_1.SnappingPointType.RecommendedFastener:
            snappingPointOriginsSource = object.getRecommendedFastenerPointsRaw();
            oppositePointOriginsSource = object.getInboundSnappingPointsRaw();
            break;
        default:
            break;
    }
    if (snappingPointOriginsSource) {
        const objectBBox = Utils.getAABBox(object);
        snappingPointOriginsSource.forEach((origin, originIndex) => {
            var _a;
            const normal = snappingPointPredefinedNormals && originIndex < snappingPointPredefinedNormals.length
                ? snappingPointPredefinedNormals[originIndex].clone()
                : computePointNormal(objectBBox, origin, (_a = oppositePointOriginsSource === null || oppositePointOriginsSource === void 0 ? void 0 : oppositePointOriginsSource[originIndex]) !== null && _a !== void 0 ? _a : null);
            snappingPointsData.push({
                origin: origin.clone(),
                normal,
                objectUUID: object.uuid,
            });
        });
    }
    return snappingPointsData;
}
exports.calculateSnappingPoints = calculateSnappingPoints;
function calculateOutboundSnappingPoints(object) {
    return calculateSnappingPoints(object, PartMetadata_1.SnappingPointType.Outbound);
}
exports.calculateOutboundSnappingPoints = calculateOutboundSnappingPoints;
function calculateInboundSnappingPoints(object) {
    return calculateSnappingPoints(object, PartMetadata_1.SnappingPointType.Inbound);
}
exports.calculateInboundSnappingPoints = calculateInboundSnappingPoints;
function calculateRecommendedFastenerPoints(object) {
    return calculateSnappingPoints(object, PartMetadata_1.SnappingPointType.RecommendedFastener);
}
exports.calculateRecommendedFastenerPoints = calculateRecommendedFastenerPoints;
function calculateSlots(object) {
    const slotsData = [];
    const objectOBB = Utils.getOBB(object);
    const objectSlots = object.getRectangles();
    const { slotThickness, panelDepth } = object.partDetails;
    if (objectSlots !== null) {
        objectSlots.forEach((slot) => {
            const slotPoints = slot.points;
            const slotNumber = slot.number;
            const origin = new three_1.Vector3();
            slotPoints.forEach((point) => origin.add(point));
            origin.divideScalar(slotPoints.length);
            const side1 = slotPoints[0].clone().sub(slotPoints[1]);
            const side2 = slotPoints[2].clone().sub(slotPoints[1]);
            const normal = Utils.getSLotNormal(slot, objectOBB.center);
            tempRaycaster.set(origin, normal);
            const intersections = tempRaycaster.intersectObject(object.mesh);
            if (intersections.find((intersection) => !Utils.areVectorsEqual(intersection.point, origin, consts_1.consts.TWO_FLOAT_EPSILON))) {
                normal.negate();
            }
            const length = Math.max(side1.length(), side2.length());
            const width = Math.min(side1.length(), side2.length());
            const direction = consts_1.consts.DP_UP_DIR.clone().applyQuaternion(object.quaternion);
            const surfacePoint = origin
                .clone()
                .add(normal
                .clone()
                .multiplyScalar(objectOBB.halfSize.clone().applyQuaternion(object.quaternion).projectOnVector(normal).length())
                .sub(origin.clone().sub(objectOBB.center).projectOnVector(normal)));
            slotsData.push({
                points: slot.points.map((point) => point.clone()),
                origin: origin.clone(),
                surfacePoint: surfacePoint.clone(),
                normal: normal.clone(),
                direction: direction.clone(),
                length,
                width,
                slotNumber,
                objectUUID: object.uuid,
                thickness: slotThickness,
                depth: panelDepth !== null && panelDepth !== void 0 ? panelDepth : 0,
            });
        });
    }
    return slotsData;
}
exports.calculateSlots = calculateSlots;
function cloneSnappingPoint(point) {
    return {
        objectUUID: point.objectUUID,
        normal: point.normal.clone(),
        origin: point.origin.clone(),
    };
}
exports.cloneSnappingPoint = cloneSnappingPoint;
function cloneSlot(slot) {
    return {
        slotNumber: slot.slotNumber,
        surfacePoint: slot.surfacePoint.clone(),
        origin: slot.origin.clone(),
        direction: slot.direction.clone(),
        width: slot.width,
        length: slot.length,
        normal: slot.normal.clone(),
        points: slot.points.map((point) => point.clone()),
        objectUUID: slot.objectUUID,
        thickness: slot.thickness,
        depth: slot.depth,
    };
}
exports.cloneSlot = cloneSlot;
function updateSlot(initialSlot, matrix, objectLength) {
    const slotCopy = cloneSlot(initialSlot);
    matrix.decompose(tempVector, tempQuaternion, tempVector2);
    slotCopy.origin.applyMatrix4(matrix);
    slotCopy.surfacePoint.applyMatrix4(matrix);
    slotCopy.points.forEach((point) => point.applyMatrix4(matrix));
    slotCopy.length = objectLength;
    slotCopy.direction.applyQuaternion(tempQuaternion);
    Utils.roundVector(slotCopy.direction, 10);
    slotCopy.normal.applyQuaternion(tempQuaternion);
    Utils.roundVector(slotCopy.normal, 10);
    return slotCopy;
}
exports.updateSlot = updateSlot;
function cloneSnappingPointsArray(points) {
    return points && points.map(cloneSnappingPoint);
}
exports.cloneSnappingPointsArray = cloneSnappingPointsArray;
function cloneSlotsArray(slots) {
    return slots && slots.map(cloneSlot);
}
exports.cloneSlotsArray = cloneSlotsArray;
function updateSnappingPoint(initialSnappingPoint, matrix) {
    const snappingPointCopy = cloneSnappingPoint(initialSnappingPoint);
    matrix.decompose(tempVector, tempQuaternion, tempVector2);
    snappingPointCopy.normal.applyQuaternion(tempQuaternion);
    Utils.roundVector(snappingPointCopy.normal, 10);
    snappingPointCopy.origin.applyMatrix4(matrix);
    return snappingPointCopy;
}
exports.updateSnappingPoint = updateSnappingPoint;
