"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectIsProjectSharedWithAll = exports.selectExportQueue = exports.selectExportStepProgress = exports.selectExportStepLoading = exports.selectViewMode = exports.selectCurrentProjectLocked = exports.selectProjectDuplicatingState = exports.selectProjectLoadingState = exports.selectShowProjectDetailsPopover = exports.selectIsUpdatingCompleted = exports.selectProject = exports.selectDuplicatedProject = exports.selectCreatedProject = exports.selectProjectDefaultFasteners = exports.selectCurrentProjectStatus = exports.selectCurrentProject = exports.selectCurrentProjectId = void 0;
const reselect_1 = require("reselect");
exports.selectCurrentProjectId = (0, reselect_1.createSelector)((state) => state.project, (project) => { var _a; return (_a = project.currentProject) === null || _a === void 0 ? void 0 : _a.id; });
exports.selectCurrentProject = (0, reselect_1.createSelector)((state) => state.project, (project) => project.currentProject);
exports.selectCurrentProjectStatus = (0, reselect_1.createSelector)((state) => state.project, (project) => { var _a; return (_a = project.currentProject) === null || _a === void 0 ? void 0 : _a.status; });
exports.selectProjectDefaultFasteners = (0, reselect_1.createSelector)((state) => state.project, (project) => { var _a; return (_a = project.currentProject) === null || _a === void 0 ? void 0 : _a.defaultFasteners; });
exports.selectCreatedProject = (0, reselect_1.createSelector)((state) => state.project, (project) => project.createdProject);
exports.selectDuplicatedProject = (0, reselect_1.createSelector)((state) => state.project, (project) => project.duplicatedProject);
exports.selectProject = (0, reselect_1.createSelector)((state) => state.project, (project) => project.project);
exports.selectIsUpdatingCompleted = (0, reselect_1.createSelector)((state) => state.project, (project) => project.isUpdatingCompleted);
exports.selectShowProjectDetailsPopover = (0, reselect_1.createSelector)((state) => state.project, (account) => account.showProjectDetailsPopover);
exports.selectProjectLoadingState = (0, reselect_1.createSelector)((state) => state.project, (account) => account.isLoading);
exports.selectProjectDuplicatingState = (0, reselect_1.createSelector)((state) => state.project, (account) => account.isDuplicating);
exports.selectCurrentProjectLocked = (0, reselect_1.createSelector)((state) => state.project, (project) => { var _a; return (_a = project.currentProject) === null || _a === void 0 ? void 0 : _a.lockedById; });
exports.selectViewMode = (0, reselect_1.createSelector)((state) => state.project, (project) => project.viewMode);
exports.selectExportStepLoading = (0, reselect_1.createSelector)((state) => state.project, (project) => project.isExportToStepLoading);
exports.selectExportStepProgress = (0, reselect_1.createSelector)((state) => state.project, (project) => project.exportProgress);
exports.selectExportQueue = (0, reselect_1.createSelector)((state) => state.project, (project) => project.exportQueue);
exports.selectIsProjectSharedWithAll = (0, reselect_1.createSelector)((state) => state.project, (project) => { var _a, _b; return (_b = (_a = project.currentProject) === null || _a === void 0 ? void 0 : _a.sharedWithAll) !== null && _b !== void 0 ? _b : false; });
