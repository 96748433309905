"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const effects_1 = require("redux-saga/effects");
const events_1 = require("~/utils/events");
const actions_1 = require("../actions");
const api_1 = require("../api");
const consts_1 = require("./consts");
const selectors_1 = require("../selectors");
function* tryCreateAddress({ payload }) {
    try {
        const shippingAddress = yield (0, effects_1.call)(api_1.createAddressData, payload);
        const currentUser = yield (0, effects_1.select)(selectors_1.selectCurrentUser);
        const customers = yield (0, effects_1.select)(selectors_1.selectConnectedCustomers);
        const customersClone = structuredClone(customers);
        const currentCustomer = customersClone.find((customer) => customer.id === shippingAddress.user_id);
        const currentCustomerShippingAddress = currentCustomer === null || currentCustomer === void 0 ? void 0 : currentCustomer.shippingAddresses;
        if (currentCustomerShippingAddress && currentUser.id !== shippingAddress.userId) {
            document.body.dispatchEvent((0, events_1.dispatchCustomerShippingAddressChangedEvent)({
                addressId: shippingAddress.id,
                userId: currentCustomer.id,
            }));
            const idx = customersClone.findIndex((current) => current.id === currentCustomer.id);
            currentCustomer.shippingAddresses = [...currentCustomerShippingAddress, shippingAddress];
            customersClone.splice(idx, 1, currentCustomer);
            yield (0, effects_1.put)((0, actions_1.updateCustomerData)(customersClone));
        }
        yield (0, effects_1.put)((0, actions_1.setUserAddressSuccess)(shippingAddress));
        yield (0, effects_1.put)((0, actions_1.setUserDataAction)(true));
    }
    catch (error) {
        console.log(error);
    }
}
function* tryUpdateAddress({ payload }) {
    try {
        const shippingAddress = yield (0, effects_1.call)(api_1.updateAddressData, payload);
        const user = yield (0, effects_1.select)(selectors_1.selectCurrentUser);
        yield (0, effects_1.put)((0, actions_1.setUserDataAction)(true));
        const idx = user.shippingAddresses.findIndex((address) => address.id === shippingAddress.id);
        if (idx) {
            const shippingAddresses = structuredClone(user.shippingAddresses);
            shippingAddresses.splice(idx, 1, shippingAddress);
            yield (0, effects_1.put)((0, actions_1.setUserDataSuccess)(Object.assign(Object.assign({}, user), { shippingAddresses: [...shippingAddresses] })));
        }
    }
    catch (error) {
        console.log(error);
    }
}
function* tryDeleteAddress({ payload }) {
    try {
        yield (0, effects_1.call)(api_1.deleteAddressData, payload);
        yield (0, effects_1.put)((0, actions_1.setUserDataAction)(true));
    }
    catch (error) {
        console.log(error);
    }
}
function* rootSaga() {
    yield (0, effects_1.all)([
        (0, effects_1.takeLatest)(consts_1.CREATE_ADDRESS_DATA, tryCreateAddress),
        (0, effects_1.takeLatest)(consts_1.DELETE_ADDRESS_DATA, tryDeleteAddress),
        (0, effects_1.takeLatest)(consts_1.UPDATE_ADDRESS_DATA, tryUpdateAddress),
    ]);
}
exports.default = rootSaga;
