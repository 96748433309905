"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const effects_1 = require("redux-saga/effects");
const api_1 = require("~/store/api");
const events_1 = require("~/utils/events");
const utils_1 = require("~/utils/utils");
const consts_1 = require("../account/consts");
const actions_1 = require("./actions");
const consts_2 = require("./consts");
const getCurrentUserRole = (state) => { var _a, _b, _c; return (_c = (_b = (_a = state.user.user) === null || _a === void 0 ? void 0 : _a.userType) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : ''; };
const getCurrentUserDistributorId = (state) => { var _a, _b; return (_b = (_a = state.user.user) === null || _a === void 0 ? void 0 : _a.distributorId) !== null && _b !== void 0 ? _b : 0; };
function* getData({ payload }) {
    try {
        const userRole = yield (0, effects_1.select)(getCurrentUserRole);
        const countries = yield (0, effects_1.call)(api_1.fetchCountries);
        const countriesWithDistributors = yield (0, effects_1.call)(api_1.fetchCountries, true);
        const distributors = yield (0, effects_1.call)(api_1.fetchDistributors);
        const states = yield (0, effects_1.call)(api_1.fetchStates);
        const statesWithDistributors = yield (0, effects_1.call)(api_1.fetchStates, true);
        let customersConnectedToDistributor = [];
        if (userRole === consts_1.UserRoles.distributor) {
            const distributorId = yield (0, effects_1.select)(getCurrentUserDistributorId);
            customersConnectedToDistributor = yield (0, effects_1.call)(api_1.fetchDistributorCustomers, distributorId);
        }
        else if (userRole === consts_1.UserRoles.admin && payload) {
            customersConnectedToDistributor = yield (0, effects_1.call)(api_1.fetchDistributorCustomers, payload);
        }
        yield (0, effects_1.put)((0, actions_1.fetchDataSuccess)({
            countries,
            distributors,
            states,
            customersConnectedToDistributor,
            countriesWithDistributors,
            statesWithDistributors,
        }));
    }
    catch (error) {
        yield (0, effects_1.call)(events_1.showNotification, {
            message: (0, utils_1.errorify)(error),
            type: 'error',
        });
        yield (0, effects_1.put)((0, actions_1.fetchDataError)());
    }
}
function* getDistributorCustomers({ payload }) {
    try {
        const customersConnectedToDistributor = yield (0, effects_1.call)(api_1.fetchDistributorCustomers, payload);
        yield (0, effects_1.put)((0, actions_1.fetchCustomersConnectedToDistributorSuccess)(customersConnectedToDistributor));
    }
    catch (error) {
        yield (0, effects_1.call)(events_1.showNotification, {
            message: (0, utils_1.errorify)(error),
            type: 'error',
        });
    }
}
function* getBundleVersion() {
    try {
        const version = yield (0, effects_1.call)(api_1.fetchBundleVersion);
        yield (0, effects_1.put)((0, actions_1.fetchBundleVersionSuccess)(version));
    }
    catch (error) {
        yield (0, effects_1.call)(events_1.showNotification, {
            message: (0, utils_1.errorify)(error),
            type: 'error',
        });
    }
}
function* rootSaga() {
    yield (0, effects_1.all)([
        (0, effects_1.takeLatest)(consts_2.FETCH_DATA, getData),
        (0, effects_1.takeLatest)(consts_2.FETCH_CUSTOMERS_CONNECTED_TO_DISTRIBUTOR, getDistributorCustomers),
        (0, effects_1.takeLatest)(consts_2.FETCH_BUNDLE_VERSION, getBundleVersion),
    ]);
}
exports.default = rootSaga;
