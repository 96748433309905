"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchBundleVersion = exports.fetchStates = exports.fetchCountries = exports.fetchDistributors = void 0;
const axiosInstance_1 = require("~/store/api/axiosInstance");
const utils_1 = require("~/utils/utils");
const events_1 = require("~/utils/events");
const fetchDistributors = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axiosInstance_1.axiosInstance.get('/data/distributors');
        if (response.status !== 200) {
            throw new Error((0, utils_1.errorify)(response.data));
        }
        return response.data;
    }
    catch (error) {
        (0, events_1.showNotification)({
            message: (0, utils_1.errorify)(error),
            type: 'error',
        });
    }
    return null;
});
exports.fetchDistributors = fetchDistributors;
const fetchCountries = (hasDistributors = false) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axiosInstance_1.axiosInstance.get(`/data/countries?hasDistributors=${hasDistributors}`);
        if (response.status !== 200) {
            throw new Error((0, utils_1.errorify)(response.data));
        }
        return response.data;
    }
    catch (error) {
        (0, events_1.showNotification)({
            message: (0, utils_1.errorify)(error),
            type: 'error',
        });
    }
    return null;
});
exports.fetchCountries = fetchCountries;
const fetchStates = (hasDistributors = false) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axiosInstance_1.axiosInstance.get(`/data/states?hasDistributors=${hasDistributors}`);
        if (response.status !== 200) {
            throw new Error((0, utils_1.errorify)(response.data));
        }
        return response.data;
    }
    catch (error) {
        (0, events_1.showNotification)({
            message: (0, utils_1.errorify)(error),
            type: 'error',
        });
    }
    return null;
});
exports.fetchStates = fetchStates;
const fetchBundleVersion = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axiosInstance_1.axiosInstance.get('/version');
        if (response.status !== 200) {
            throw new Error((0, utils_1.errorify)(response.data));
        }
        return response.data;
    }
    catch (error) {
        (0, events_1.showNotification)({
            message: (0, utils_1.errorify)(error),
            type: 'error',
        });
    }
    return null;
});
exports.fetchBundleVersion = fetchBundleVersion;
