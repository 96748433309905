"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const three_1 = require("three");
const TextGeometry_1 = require("three/examples/jsm/geometries/TextGeometry");
const LINE_MATERIAL = new three_1.LineBasicMaterial({ color: 0x404040 });
const BALLOON_MATERIAL = new three_1.MeshBasicMaterial({ color: 0xffffff });
const TEXT_MATERIAL = new three_1.MeshBasicMaterial({ color: 0x404040, depthTest: false });
const BALLOON_BORDER_MATERIAL = new three_1.MeshBasicMaterial({ color: 0x404040 });
class BomBalloon {
    constructor(position, positionOfTarget, numberToDisplay, font, radius) {
        this.position = position;
        this.positionOfTarget = positionOfTarget;
        this.numberToDisplay = numberToDisplay;
        this.font = font;
        this.radius = radius;
        this.arrowParams = {
            width: radius / 5,
            height: radius,
        };
        this.group = new three_1.Group();
        this.update();
    }
    addToCamera(camera) {
        camera.add(this.group);
    }
    removeFromCamera() {
        this.group.removeFromParent();
    }
    update() {
        if (this.group.children.length) {
            this.group.clear();
        }
        // create line
        const line = new three_1.Line(new three_1.BufferGeometry().setFromPoints([this.position, this.positionOfTarget]), LINE_MATERIAL);
        this.group.add(line);
        // create arrow
        this.group.add(this.createArrowHead());
        // create Balloon
        const balloonGeometry = new three_1.CircleGeometry(this.radius, 50);
        const balloon = new three_1.Mesh(balloonGeometry, BALLOON_MATERIAL);
        balloon.position.copy(this.position);
        this.group.add(balloon);
        // create balloon border
        const borderGeometry = new three_1.RingGeometry(this.radius * 0.95, this.radius, 50);
        const border = new three_1.Mesh(borderGeometry, BALLOON_BORDER_MATERIAL);
        border.position.copy(this.position);
        border.translateZ(0.001);
        this.group.add(border);
        // create balloon text
        const textGeometry = new TextGeometry_1.TextGeometry(this.numberToDisplay.toString(), {
            font: this.font,
            size: this.radius,
            height: 0.001,
            curveSegments: 12,
        });
        textGeometry.computeBoundingBox();
        if (textGeometry.boundingBox) {
            const bboxCenter = textGeometry.boundingBox.getCenter(new three_1.Vector3());
            const text = new three_1.Mesh(textGeometry, TEXT_MATERIAL);
            text.position.add(this.position.clone().sub(bboxCenter));
            this.group.add(text);
        }
    }
    createArrowHead() {
        const vectorToTarget = this.positionOfTarget.clone().sub(this.position);
        const directionToTarget = vectorToTarget.clone().normalize();
        const x = this.positionOfTarget.clone().sub(directionToTarget.clone().multiplyScalar(this.arrowParams.height));
        const left = x.clone().add(directionToTarget
            .clone()
            .applyAxisAngle(new three_1.Vector3(0, 0, -1), 0.5 * Math.PI)
            .multiplyScalar(this.arrowParams.width));
        const right = x.clone().add(directionToTarget
            .clone()
            .applyAxisAngle(new three_1.Vector3(0, 0, -1), -0.5 * Math.PI)
            .multiplyScalar(this.arrowParams.width));
        const triangleGeometry = new three_1.BufferGeometry().setFromPoints([this.positionOfTarget, right, left]);
        return new three_1.Mesh(triangleGeometry, new three_1.MeshBasicMaterial({ color: 0x000000 }));
    }
}
exports.default = BomBalloon;
