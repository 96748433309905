"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_BOM_DISABLED = exports.SET_DISCOUNTED_PRICE_SHOWN = exports.TOGGLE_ASSEMBLY_CHARGE_FOR_ITEM = exports.ADD_ITEM_SIZE = exports.ADD_MANUALLY_ADDED_MACHINING_SERVICE_QUANTITY = exports.ADD_ITEM_QUANTITY = exports.MANUALLY_ADDED_ITEMS = exports.DELETE_MANUALLY_ADDED_MACHINING_SERVICE = exports.DELETE_MANUALLY_ADDED = exports.DELETE_MACHINING_SERVICE_MANUALLY = exports.DELETE_ITEM_MANUALLY = exports.ADD_ITEM_MANUALLY = exports.EDIT_MODE = exports.UPDATE_REVISION = exports.APPLY_MACHINING_SERVICE_MANUALLY = exports.GROUP_MANUALLY_ADDED_ITEMS = exports.SET_ASSEMBLY_CHARGE_INCLUDED_ITEM = exports.SET_ASSEMBLY_CHARGE_INCLUDED = exports.TOGGLE_QUOTE = exports.REQUEST_SOCKET_CLOSE_PROJECT = exports.REQUEST_SOCKET_UPDATE_SERVICES_TO_BOM = exports.REQUEST_SOCKET_REMOVE_SERVICES_FROM_BOM = exports.REQUEST_SOCKET_ADD_SERVICES_TO_BOM = exports.REQUEST_SOCKET_UPDATE_ITEMS_TO_BOM = exports.REQUEST_SOCKET_REMOVE_ITEMS_FROM_BOM = exports.REQUEST_SOCKET_UPDATE_FASTENERS_TO_BOM = exports.REQUEST_SOCKET_ADD_FASTENERS_TO_BOM = exports.REQUEST_SOCKET_ADD_ITEMS_TO_BOM = exports.REQUEST_BOM_FAIL = exports.REQUEST_SOCKET_BOM = exports.REQUEST_BOM_SUCCESS = exports.SET_BOM_LOADING = exports.SET_EMPTY_BOM = exports.SET_DEFAULT_BOM = void 0;
exports.SET_DEFAULT_BOM = 'SET_DEFAULT_BOM';
exports.SET_EMPTY_BOM = 'SET_EMPTY_BOM';
exports.SET_BOM_LOADING = 'SET_BOM_LOADING';
exports.REQUEST_BOM_SUCCESS = 'REQUEST_BOM_SUCCESS';
exports.REQUEST_SOCKET_BOM = 'socket/REQUEST_SOCKET_BOM';
exports.REQUEST_BOM_FAIL = 'REQUEST_BOM_FAIL';
exports.REQUEST_SOCKET_ADD_ITEMS_TO_BOM = 'socket/REQUEST_SOCKET_ADD_ITEMS_TO_BOM';
exports.REQUEST_SOCKET_ADD_FASTENERS_TO_BOM = 'socket/REQUEST_SOCKET_ADD_FASTENERS_TO_BOM';
exports.REQUEST_SOCKET_UPDATE_FASTENERS_TO_BOM = 'socket/REQUEST_SOCKET_UPDATE_FASTENERS_TO_BOM';
exports.REQUEST_SOCKET_REMOVE_ITEMS_FROM_BOM = 'socket/REQUEST_SOCKET_REMOVE_ITEMS_FROM_BOM';
exports.REQUEST_SOCKET_UPDATE_ITEMS_TO_BOM = 'socket/REQUEST_SOCKET_UPDATE_ITEMS_TO_BOM';
exports.REQUEST_SOCKET_ADD_SERVICES_TO_BOM = 'socket/REQUEST_SOCKET_ADD_SERVICES_TO_BOM';
exports.REQUEST_SOCKET_REMOVE_SERVICES_FROM_BOM = 'socket/REQUEST_SOCKET_REMOVE_SERVICES_FROM_BOM';
exports.REQUEST_SOCKET_UPDATE_SERVICES_TO_BOM = 'socket/REQUEST_SOCKET_UPDATE_SERVICES_TO_BOM';
exports.REQUEST_SOCKET_CLOSE_PROJECT = 'socket/CLOSE_PROJECT';
exports.TOGGLE_QUOTE = 'TOGGLE_QUOTE';
exports.SET_ASSEMBLY_CHARGE_INCLUDED = 'socket/SET_ASSEMBLY_CHARGE_INCLUDED';
exports.SET_ASSEMBLY_CHARGE_INCLUDED_ITEM = 'socket/SET_ASSEMBLY_CHARGE_INCLUDED_ITEM';
exports.GROUP_MANUALLY_ADDED_ITEMS = 'socket/GROUP_MANUALLY_ADDED_ITEMS';
exports.APPLY_MACHINING_SERVICE_MANUALLY = 'APPLY_MACHINING_SERVICE_MANUALLY';
exports.UPDATE_REVISION = 'socket/UPDATE_REVISION';
exports.EDIT_MODE = 'EDIT_MODE';
exports.ADD_ITEM_MANUALLY = 'ADD_ITEM_MANUALLY';
exports.DELETE_ITEM_MANUALLY = 'DELETE_ITEM_MANUALLY';
exports.DELETE_MACHINING_SERVICE_MANUALLY = 'DELETE_MACHINING_SERVICE_MANUALLY';
exports.DELETE_MANUALLY_ADDED = 'DELETE_MANUALLY_ADDED';
exports.DELETE_MANUALLY_ADDED_MACHINING_SERVICE = 'DELETE_MANUALLY_ADDED_MACHINING_SERVICE';
exports.MANUALLY_ADDED_ITEMS = 'Manually Added Items';
exports.ADD_ITEM_QUANTITY = 'ADD_ITEM_QUANTITY';
exports.ADD_MANUALLY_ADDED_MACHINING_SERVICE_QUANTITY = 'CHANGE_MANUALLY_ADDED_MACHINING_SERVICE_QUANTITY';
exports.ADD_ITEM_SIZE = 'ADD_ITEM_SIZE';
exports.TOGGLE_ASSEMBLY_CHARGE_FOR_ITEM = 'TOGGLE_ASSEMBLY_CHARGE_FOR_ITEM';
exports.SET_DISCOUNTED_PRICE_SHOWN = 'SET_DISCOUNTED_PRICE_SHOWN';
exports.SET_BOM_DISABLED = 'SET_BOM_DISABLED';
