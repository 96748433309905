"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HistoryService = void 0;
const historyEventEmitter_1 = require("./historyEventEmitter");
// eslint-disable-next-line import/no-cycle
const historyOperator_1 = require("./historyOperator");
// eslint-disable-next-line import/prefer-default-export
class HistoryService {
    constructor() {
        this.historyEventEmitter = new historyEventEmitter_1.HistoryEventEmitter();
        this.history = new historyOperator_1.HistoryOperator(this.historyEventEmitter);
        this.listenBaseHistoryEvent();
    }
    get activeHandlers() {
        return this.historyEventEmitter.activeHandlers;
    }
    clearLatest(n) {
        this.history.clearLatest(n);
    }
    emit(name, data) {
        this.historyEventEmitter.emit(name, data);
    }
    listen(name, handler) {
        return this.historyEventEmitter.listen(name, handler);
    }
    execute(cmd, optionalName) {
        this.history.execute(cmd, optionalName);
    }
    undo() {
        this.history.undo();
    }
    redo() {
        this.history.redo();
    }
    getUndos() {
        return this.history.getUndosList();
    }
    getRedos() {
        return this.history.getRedosList();
    }
    reset() {
        this.history.clear();
        this.historyEventEmitter.dispose();
        this.listenBaseHistoryEvent();
    }
    listenBaseHistoryEvent() {
        this.historyEventEmitter.listen('historyChanged', (data) => {
            /* do nothing */
        });
    }
}
exports.HistoryService = HistoryService;
