"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const consts_1 = require("../consts");
class Camera extends THREE.PerspectiveCamera {
    constructor(defaultPosition, lookAt) {
        super(consts_1.consts.DEFAULT_CAMERA_FOV, window.innerWidth / window.innerHeight, consts_1.consts.DEFAULT_CAMERA_NEAR, consts_1.consts.DEFAULT_CAMERA_FAR);
        this.position.set(defaultPosition.x, defaultPosition.y, defaultPosition.z);
        this.up.copy(new THREE.Vector3(0, 0, -1));
        this.lookAt(lookAt);
    }
    setZoom(newZoom) {
        if (newZoom <= consts_1.consts.DEFAULT_CAMERA_MIN_ZOOM) {
            this.zoom = consts_1.consts.DEFAULT_CAMERA_MIN_ZOOM;
        }
        else if (newZoom >= consts_1.consts.DEFAULT_CAMERA_MAX_ZOOM) {
            this.zoom = consts_1.consts.DEFAULT_CAMERA_MAX_ZOOM;
        }
        else {
            this.zoom = newZoom;
        }
        this.updateProjectionMatrix();
    }
}
exports.default = Camera;
