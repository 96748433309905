"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTooltipPosition = void 0;
const fns = {
    'bottom-center': (childRect, tooltipRect) => ({
        tooltipTop: childRect.top + childRect.height,
        tooltipLeft: childRect.left + (childRect.width - tooltipRect.width) / 2,
    }),
    'bottom-end': (childRect, tooltipRect) => ({
        tooltipTop: childRect.top + childRect.height,
        tooltipLeft: childRect.left + childRect.width - tooltipRect.width,
    }),
    'bottom-start': (childRect, tooltipRect) => ({
        tooltipTop: childRect.top + childRect.height,
        tooltipLeft: childRect.left,
    }),
    'left-center': (childRect, tooltipRect) => ({
        tooltipTop: childRect.top - (tooltipRect.height - childRect.height) / 2,
        tooltipLeft: childRect.left - tooltipRect.width,
    }),
    'left-end': (childRect, tooltipRect) => ({
        tooltipTop: childRect.top + childRect.height - tooltipRect.height,
        tooltipLeft: childRect.left - tooltipRect.width,
    }),
    'left-start': (childRect, tooltipRect) => ({
        tooltipTop: childRect.top,
        tooltipLeft: childRect.left - tooltipRect.width,
    }),
    'right-center': (childRect, tooltipRect) => ({
        tooltipTop: childRect.top - (tooltipRect.height - childRect.height) / 2,
        tooltipLeft: childRect.left + childRect.width,
    }),
    'right-end': (childRect, tooltipRect) => ({
        tooltipTop: childRect.top + childRect.height - tooltipRect.height,
        tooltipLeft: childRect.left + childRect.width,
    }),
    'right-start': (childRect, tooltipRect) => ({
        tooltipTop: childRect.top,
        tooltipLeft: childRect.left + childRect.width,
    }),
    'top-center': (childRect, tooltipRect) => ({
        tooltipTop: childRect.top - tooltipRect.height,
        tooltipLeft: childRect.left + (childRect.width - tooltipRect.width) / 2,
    }),
    'top-end': (childRect, tooltipRect) => ({
        tooltipTop: childRect.top - tooltipRect.height,
        tooltipLeft: childRect.left + childRect.width - tooltipRect.width,
    }),
    'top-start': (childRect, tooltipRect) => ({
        tooltipTop: childRect.top - tooltipRect.height,
        tooltipLeft: childRect.left,
    }),
};
// eslint-disable-next-line import/prefer-default-export
const calculateTooltipPosition = (childRect, tooltipRect, placement) => fns[placement](childRect, tooltipRect);
exports.calculateTooltipPosition = calculateTooltipPosition;
