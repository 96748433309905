"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectErrorStatusCode = exports.selectAuthData = exports.selectAction = exports.selectUserId = exports.selectStatusMessage = exports.selectSignupFailed = exports.selectAuthMode = exports.selectLoginFailed = exports.selectIsUserLogged = void 0;
const reselect_1 = require("reselect");
exports.selectIsUserLogged = (0, reselect_1.createSelector)((state) => state.authenticate, (authenticate) => authenticate.data.isUserLogged);
exports.selectLoginFailed = (0, reselect_1.createSelector)((state) => state.authenticate, (authenticate) => authenticate.loginFailed);
exports.selectAuthMode = (0, reselect_1.createSelector)((state) => state.authenticate, (authenticate) => authenticate.mode);
exports.selectSignupFailed = (0, reselect_1.createSelector)((state) => state.authenticate, (authenticate) => authenticate.signupFailed);
exports.selectStatusMessage = (0, reselect_1.createSelector)((state) => state.authenticate, (authenticate) => authenticate.statusMessage);
exports.selectUserId = (0, reselect_1.createSelector)((state) => state.authenticate, (authenticate) => { var _a, _b; return (_b = (_a = authenticate.data) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id; });
exports.selectAction = (0, reselect_1.createSelector)((state) => state.authenticate, (authenticate) => authenticate.action);
exports.selectAuthData = (0, reselect_1.createSelector)((state) => state.authenticate, (authenticate) => authenticate.data);
exports.selectErrorStatusCode = (0, reselect_1.createSelector)((state) => state.authenticate, (authenticate) => authenticate.errorStatusCode);
