"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const effects_1 = require("redux-saga/effects");
const api_1 = require("~/store/api");
const events_1 = require("~/utils/events");
const utils_1 = require("~/utils/utils");
const actions_1 = require("~/store/actions");
const consts_1 = require("~/consts/consts");
const action_1 = require("./action");
const consts_2 = require("./consts");
const getProjectId = (state) => { var _a; return (_a = state.project.currentProject) === null || _a === void 0 ? void 0 : _a.id; };
function* initialAPIRequest() {
    try {
        const sessionToken = yield (0, effects_1.call)(utils_1.getSessionToken);
        const response = yield (0, effects_1.call)(api_1.requestAPI, sessionToken);
        if (response.authData.deleted) {
            yield (0, effects_1.put)((0, action_1.handleAuthResponse)({ isUserLogged: false, deleted: true }));
            yield (0, effects_1.call)(events_1.showNotification, {
                message: 'Your session has expired. Please login to continue',
                type: 'error',
            });
        }
        yield (0, effects_1.put)((0, action_1.handleAuthResponse)(response.authData));
    }
    catch (error) {
        yield (0, effects_1.put)((0, action_1.handleAuthResponse)({ isUserLogged: false }));
        console.log(error);
    }
}
function* tryValidateToken({ payload }) {
    try {
        const sessionToken = yield (0, effects_1.call)(utils_1.getSessionToken);
        const response = yield (0, effects_1.call)(api_1.requestAPI, sessionToken);
        if (response.authData.deleted) {
            throw new Error('User is deleted');
        }
        yield (0, effects_1.put)((0, action_1.handleAuthResponse)(response.authData));
    }
    catch (error) {
        yield (0, effects_1.call)(utils_1.setIsUserLogged, false);
        yield (0, effects_1.put)((0, action_1.handleAuthResponse)({ isUserLogged: false }));
        yield (0, effects_1.call)(utils_1.removeToken);
        yield (0, effects_1.call)(utils_1.removeRefreshToken);
        yield (0, effects_1.call)(events_1.showNotification, {
            message: 'Your session has expired. Please login to continue',
            type: 'error',
        });
        if (payload) {
            yield (0, effects_1.call)(payload);
        }
    }
}
function* tryLoginUser({ payload }) {
    try {
        const response = yield (0, effects_1.call)(api_1.loginUser, payload);
        if (response.status === 401) {
            yield (0, effects_1.put)((0, action_1.setErrorStatusCode)(401));
            throw new Error('401');
        }
        else if (response.status === 410) {
            yield (0, effects_1.put)((0, action_1.setErrorStatusCode)(410));
            throw new Error('410');
        }
        yield (0, effects_1.put)((0, action_1.handleAuthResponse)(response));
        yield (0, effects_1.put)((0, action_1.loginSuccess)());
        yield (0, effects_1.call)(() => localStorage.setItem(consts_1.DISCOUNTED_PRICE_SHOWN_KEY, JSON.stringify(false)));
        yield (0, effects_1.put)((0, actions_1.setDiscountedPriceShown)(false));
    }
    catch (error) {
        yield (0, effects_1.put)((0, action_1.loginFailed)());
    }
}
function* tryLogoutUser() {
    const projectId = yield (0, effects_1.select)(getProjectId);
    if (projectId) {
        yield (0, effects_1.call)(api_1.closeProject, projectId);
    }
    yield (0, effects_1.call)(utils_1.removeToken);
    yield (0, effects_1.call)(utils_1.removeRefreshToken);
    yield (0, effects_1.put)((0, action_1.handleAuthResponse)({ isUserLogged: false }));
    yield (0, effects_1.put)((0, actions_1.toggleAccountDropDown)(false));
}
function* trySignupUser({ payload }) {
    try {
        const response = yield (0, effects_1.call)(api_1.signupUser, payload);
        yield (0, effects_1.put)((0, action_1.handleAuthResponse)(response));
        yield (0, effects_1.put)((0, action_1.loginSuccess)());
        yield (0, effects_1.call)(() => localStorage.setItem(consts_1.DISCOUNTED_PRICE_SHOWN_KEY, JSON.stringify(false)));
        yield (0, effects_1.put)((0, actions_1.setDiscountedPriceShown)(false));
        yield (0, effects_1.call)(events_1.showNotification, {
            message: 'Your account was created successfully',
            type: 'success',
        });
    }
    catch (error) {
        yield (0, effects_1.put)((0, action_1.signupFailed)());
    }
}
function* tryHandleAuthResponse({ payload }) {
    var _a;
    try {
        yield (0, effects_1.call)(utils_1.setIsUserLogged, payload.isUserLogged);
        yield (0, effects_1.put)((0, action_1.setAuthData)(payload));
        yield (0, effects_1.put)((0, actions_1.setUserDataAction)(payload.isUserLogged));
        yield (0, effects_1.call)(utils_1.saveToken, (_a = payload.token) !== null && _a !== void 0 ? _a : '');
        if (payload.refreshToken && payload.refreshToken.trim() !== '') {
            yield (0, effects_1.call)(utils_1.saveRefreshToken, payload.refreshToken);
        }
    }
    catch (error) {
        console.log(error);
    }
}
function* sentRestorationLink({ payload }) {
    try {
        yield (0, effects_1.call)(api_1.sendRestorationMail, payload);
        yield (0, effects_1.call)(events_1.showNotification, {
            message: `You will receive the link to restore the password if user with such email exists`,
            type: 'success',
        });
    }
    catch (error) {
        console.log(error);
    }
}
function* trySendChangeEmailMail({ payload }) {
    var _a;
    try {
        const response = yield (0, effects_1.call)(api_1.sendChangeUserEmailMail, payload.newEmail, payload.password);
        yield (0, effects_1.put)((0, action_1.setActionStatus)(response));
        if (response.status === 'success') {
            yield (0, effects_1.call)(events_1.showNotification, {
                message: `You have requested to change your email address. We sent an email to ${payload.newEmail} with a confirmation link. 
            We'll continue using your old email ${(_a = response.user) === null || _a === void 0 ? void 0 : _a.email} until you confirm the new one`,
                type: 'success',
            });
        }
    }
    catch (error) {
        console.log(error);
    }
}
function* rootSaga() {
    yield (0, effects_1.all)([
        (0, effects_1.takeLatest)(consts_2.INITIAL_REQUEST, initialAPIRequest),
        (0, effects_1.takeLatest)(consts_2.VALIDATE_TOKEN, tryValidateToken),
        (0, effects_1.takeLatest)(consts_2.LOGIN, tryLoginUser),
        (0, effects_1.takeLatest)(consts_2.SIGNUP, trySignupUser),
        (0, effects_1.takeLatest)(consts_2.LOGOUT, tryLogoutUser),
        (0, effects_1.takeLatest)(consts_2.HANDLE_AUTH_RESPONSE, tryHandleAuthResponse),
        (0, effects_1.takeLatest)(consts_2.SEND_CHANGE_EMAIL_LINK, trySendChangeEmailMail),
        (0, effects_1.takeLatest)(consts_2.SEND_RESTORE_PASSWORD_LINK, sentRestorationLink),
    ]);
}
exports.default = rootSaga;
