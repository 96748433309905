"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const actions_1 = require("~/store/actions");
const useCurrentAccountTabId = (tabId) => {
    const { dispatchSetAccountTabId } = (0, redux_1.bindActionCreators)({
        dispatchSetAccountTabId: actions_1.setCurrentTabId,
    }, (0, react_redux_1.useDispatch)());
    (0, react_1.useEffect)(() => {
        dispatchSetAccountTabId(tabId);
        return () => {
            dispatchSetAccountTabId(undefined);
        };
    }, [dispatchSetAccountTabId, tabId]);
};
exports.default = useCurrentAccountTabId;
