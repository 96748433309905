"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-continue */
const three_1 = require("three");
const OBB_1 = require("three/examples/jsm/math/OBB");
const consts_1 = require("../consts");
// eslint-disable-next-line import/no-cycle
const utils_1 = require("../utils");
/**
 * This class is used to display the Oriented Bounding Box of an object
 */
class SelectionBox extends three_1.Object3D {
    constructor(objectOBB = new OBB_1.OBB(), isTransparentLines = true, color = consts_1.consts.SELECTION_BOX_COLOR_VECTOR) {
        super();
        this.verticesNeedsUpdate = true;
        this.verticesCache = [];
        this.type = 'SelectionBox';
        this.objectOBB = objectOBB;
        this.color = color;
        this.isTransparentLines = isTransparentLines;
        this.initialize();
        // Move the box to the target center position
        this.position.copy(objectOBB.center);
        this.visible = true;
        // Then rotate it
        this.quaternion.copy(utils_1.Utils.quaternionFromMatrix3(objectOBB.rotation));
        this.updateMatrixWorld();
    }
    get vertices() {
        if (!this.verticesNeedsUpdate) {
            return this.verticesCache;
        }
        this.verticesNeedsUpdate = false;
        this.verticesCache = this.initialVertices.map((v) => new three_1.Vector3().copy(v).applyMatrix4(this.matrixWorld));
        return this.verticesCache;
    }
    cutWithPlanes(planes) {
        if (planes.length < 1) {
            return;
        }
        this.initialize();
        for (let i = 0; i < consts_1.consts.END_FACE_CORNERS_COUNT; i += 1) {
            const line = new three_1.Line3(this.initialVertices[i], this.initialVertices[i + consts_1.consts.END_FACE_CORNERS_COUNT]);
            for (let j = 0; j < planes.length; j += 1) {
                const linePlaneIntersection = planes[j].intersectLine(line, new three_1.Vector3());
                if (!linePlaneIntersection) {
                    continue;
                }
                if (planes[j].normal.dot(line.start) < 0) {
                    this.initialVertices[i] = linePlaneIntersection;
                }
                else {
                    this.initialVertices[i + consts_1.consts.END_FACE_CORNERS_COUNT] = linePlaneIntersection;
                }
            }
        }
        this.drawLines();
        this.updateMatrixWorld();
    }
    setName(name) {
        this.name = consts_1.consts.OBB_PREFIX + name;
    }
    updateMatrixWorld(force = false) {
        super.updateMatrixWorld(force);
        this.updateVertices();
        this.updateMinMax();
    }
    initialize() {
        const min = this.objectOBB.halfSize.clone().negate();
        const max = this.objectOBB.halfSize;
        /*
                Bounding box corners
                      5____4         y
                    1/___0/|         |__ x
                    | 6__|_7        /
                    2/___3/        z
        */
        const array = [
            new three_1.Vector3(max.x, max.y, max.z),
            new three_1.Vector3(min.x, max.y, max.z),
            new three_1.Vector3(min.x, min.y, max.z),
            new three_1.Vector3(max.x, min.y, max.z),
            new three_1.Vector3(max.x, max.y, min.z),
            new three_1.Vector3(min.x, max.y, min.z),
            new three_1.Vector3(min.x, min.y, min.z),
            new three_1.Vector3(max.x, min.y, min.z),
        ];
        this.initialVertices = array;
        this.drawLines();
    }
    updateVertices() {
        this.verticesNeedsUpdate = true;
    }
    updateMinMax() {
        this.min = this.vertices[0].clone();
        this.max = this.vertices[0].clone();
        this.vertices.forEach((vertex) => {
            this.min.setX(Math.min(this.min.x, vertex.x));
            this.min.setY(Math.min(this.min.y, vertex.y));
            this.min.setZ(Math.min(this.min.z, vertex.z));
            this.max.setX(Math.max(this.max.x, vertex.x));
            this.max.setY(Math.max(this.max.y, vertex.y));
            this.max.setZ(Math.max(this.max.z, vertex.z));
        });
    }
    drawLines() {
        const pairs = [
            [0, 1],
            [1, 2],
            [2, 3],
            [0, 3],
            [4, 5],
            [5, 6],
            [6, 7],
            [4, 7],
            [0, 4],
            [1, 5],
            [2, 6],
            [3, 7],
        ];
        this.children = [];
        const opacity = new three_1.BufferAttribute(new Float32Array([-1.0, 1.0]), 1);
        pairs.forEach((pair) => {
            const line = new three_1.Line(new three_1.BufferGeometry()
                .setFromPoints([this.initialVertices[pair[0]], this.initialVertices[pair[1]]])
                .setAttribute('opacity', opacity), new three_1.ShaderMaterial({
                vertexShader: `
            varying float v;
            attribute float opacity;

            void main() {
              v = opacity;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `,
                fragmentShader: `
            uniform vec3 baseColor;
            varying float v;

            void main() {
              gl_FragColor = vec4(baseColor.rgb, abs(v));              
            }
        `,
                uniforms: {
                    baseColor: { value: this.color },
                },
                visible: true,
                transparent: this.isTransparentLines,
            }));
            this.add(line);
        });
    }
    redrawLines() {
        this.clear();
        this.drawLines();
    }
    setColor(rgb) {
        if (utils_1.Utils.areVectorsNotEqual(this.color, rgb)) {
            this.color = rgb;
            this.redrawLines();
        }
    }
    copy(source, recursive = true) {
        super.copy(source, recursive);
        this.initialVertices = [];
        source.initialVertices.forEach((vertex) => {
            this.initialVertices.push(vertex.clone());
        });
        this.verticesCache = [];
        source.verticesCache.forEach((vertex) => {
            this.verticesCache.push(vertex.clone());
        });
        this.isTransparentLines = source.isTransparentLines;
        return this;
    }
}
exports.default = SelectionBox;
