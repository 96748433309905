"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useClickOutsideHandler(ref, elementRef, handler) {
    (0, react_1.useEffect)(() => {
        function handleClickOutside(event) {
            if (ref.current &&
                !ref.current.contains(event.target) &&
                elementRef.current &&
                !elementRef.current.contains(event.target)) {
                handler();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}
exports.default = useClickOutsideHandler;
