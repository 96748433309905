"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Polygon {
    static getNextIndex(index, length) {
        return index + 1 < length ? index + 1 : 0;
    }
    static getPrevIndex(index, length) {
        return index > 0 ? index - 1 : length - 1;
    }
    static getByStep(index, step, length) {
        return step > 0 ? Polygon.getNextIndex(index, length) : Polygon.getPrevIndex(index, length);
    }
}
exports.default = Polygon;
