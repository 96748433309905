"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const effects_1 = require("redux-saga/effects");
const actions_1 = require("~/store/actions");
const selectors_1 = require("~/store/selectors");
const consts_1 = require("./consts");
const getDefaultFasteners = (state) => { var _a; return (_a = state.project.currentProject) === null || _a === void 0 ? void 0 : _a.defaultFasteners; };
function* fetchBOM({ payload }) {
    var _a;
    const defaultFasteners = yield (0, effects_1.select)(getDefaultFasteners);
    if (!defaultFasteners || payload.appliedFromHistory) {
        return;
    }
    const connections = (payload.groups ? payload.groups[0].items : [])
        .map((item) => {
        var _a, _b;
        return [
            (_a = item.series) === null || _a === void 0 ? void 0 : _a.map((s) => `${s.id}`)[0],
            (_b = item.connections) === null || _b === void 0 ? void 0 : _b.map((c) => { var _a; return (_a = c === null || c === void 0 ? void 0 : c.connection.connectionType) === null || _a === void 0 ? void 0 : _a.name; })[0],
        ];
    })
        .filter((item) => !!item[0] && !!item[1])
        .reduce((acc, curr) => (Object.assign(Object.assign({}, acc), { [curr[0]]: Object.assign(Object.assign({}, acc[curr[0]]), { 
            // eslint-disable-next-line @typescript-eslint/prefer-as-const
            [curr[1]]: 'null' }) })), {});
    const newDefaultFasteners = {};
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const series in defaultFasteners) {
        // eslint-disable-next-line no-restricted-syntax
        for (const type in defaultFasteners[series]) {
            if (connections[series]) {
                newDefaultFasteners[series] = (_a = newDefaultFasteners[series]) !== null && _a !== void 0 ? _a : {};
                if (connections[series][type]) {
                    newDefaultFasteners[series][type] = defaultFasteners[series][type];
                }
            }
        }
    }
    yield (0, effects_1.put)((0, actions_1.setDefaultProjectFastener)(newDefaultFasteners));
}
const getCurrentCategoryGroup = (state) => state.catalogState.categoryGroup;
function* handleNonCatalogCategoryGroup({ payload }) {
    if (!payload) {
        const currentCategoryGroup = yield (0, effects_1.select)(getCurrentCategoryGroup);
        if (currentCategoryGroup) {
            const categoriesWithItems = yield (0, effects_1.select)((state) => (0, selectors_1.selectCatalogGroupsWithItems)(state));
            if (!categoriesWithItems.map((category) => category.name).includes(currentCategoryGroup.name)) {
                yield (0, effects_1.put)((0, actions_1.setCatalogCategoryGroup)(null));
            }
        }
    }
}
function* rootSaga() {
    yield (0, effects_1.all)([(0, effects_1.takeLatest)(consts_1.REQUEST_BOM_SUCCESS, fetchBOM), (0, effects_1.takeLatest)(consts_1.EDIT_MODE, handleNonCatalogCategoryGroup)]);
}
exports.default = rootSaga;
