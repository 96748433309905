"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const api_1 = require("~/store/api");
const events_1 = require("~/utils/events");
function useActionToken(token) {
    const [isTokenValid, setIsTokenValid] = (0, react_1.useState)(null);
    const [tokenData, setTokenData] = (0, react_1.useState)(token !== null && token !== void 0 ? token : '');
    const [decoded, setDecoded] = (0, react_1.useState)();
    const [userData, setUserData] = (0, react_1.useState)(null);
    const navigate = (0, react_router_dom_1.useNavigate)();
    (0, react_1.useEffect)(() => {
        if (!tokenData || tokenData.trim() === '') {
            setIsTokenValid(false);
            return undefined;
        }
        const tryValidateActionToken = () => __awaiter(this, void 0, void 0, function* () {
            return (0, api_1.validateActionToken)(tokenData);
        });
        tryValidateActionToken()
            .then((result) => {
            if (result.valid) {
                setIsTokenValid(true);
                setDecoded(result.decoded);
                setTokenData(tokenData);
                setUserData(result.userData);
            }
        })
            .catch((error) => {
            (0, events_1.showNotification)({ message: 'The link has expired', type: 'error' });
            setIsTokenValid(false);
            navigate('/');
        });
        return () => {
            setIsTokenValid(false);
        };
    }, [tokenData]);
    return [tokenData, isTokenValid, decoded, userData];
}
exports.default = useActionToken;
