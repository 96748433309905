"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.valueModalChanged = exports.customerIdChanged = exports.customerShippingAddressChanged = exports.wsConnectionStatusChanged = exports.requestFastenersExistence = exports.measurementUnitChanged = exports.dispatchHideModal = exports.dispatchShowModal = exports.selectedObjectsChanged = exports.takeIsometricScreenshot = exports.responseScreenshots = exports.requestScreenshots = exports.updateFasteners = exports.deleteFasteners = exports.addFasteners = exports.selectFastener = exports.resetTransformation = exports.dispatchNotification = exports.removeServicesFromBOM = exports.updateServicesToBOM = exports.addServicesToBOM = exports.updateItemsToBOM = exports.removeItemsFromBOM = exports.addItemsToBOM = exports.refreshScene = exports.createNewProject = exports.addCatalogItem = void 0;
exports.addCatalogItem = 'addCatalogItem';
exports.createNewProject = 'createNewProject';
exports.refreshScene = 'refreshScene';
exports.addItemsToBOM = 'addItemsToBOM';
exports.removeItemsFromBOM = 'removeItemsFromBOM';
exports.updateItemsToBOM = 'updateItemsToBOM';
exports.addServicesToBOM = 'addServicesToBOM';
exports.updateServicesToBOM = 'updateServicesToBOM';
exports.removeServicesFromBOM = 'removeServicesFromBOM';
exports.dispatchNotification = 'dispatchNotification';
exports.resetTransformation = 'resetTransformation';
exports.selectFastener = 'selectFastener';
exports.addFasteners = 'addFasteners';
exports.deleteFasteners = 'deleteFasteners';
exports.updateFasteners = 'updateFasteners';
exports.requestScreenshots = 'requestScreenshots';
exports.responseScreenshots = 'responseScreenshots';
exports.takeIsometricScreenshot = 'takeIsometricScreenshot';
exports.selectedObjectsChanged = 'selectedObjectsChanged';
exports.dispatchShowModal = 'dispatchShowModal';
exports.dispatchHideModal = 'dispatchHideModal';
exports.measurementUnitChanged = 'measurementUnitChanged';
exports.requestFastenersExistence = 'requestFastenersExistence';
exports.wsConnectionStatusChanged = 'wsConnectionStatusChanged';
exports.customerShippingAddressChanged = 'customerShippingAddressChanged';
exports.customerIdChanged = 'customerIdChanged';
exports.valueModalChanged = 'valueModalChanged';
