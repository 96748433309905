"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateDrawingAngled = exports.updateRedos = exports.updateUndos = exports.selectItem = exports.selectTool = void 0;
/* eslint-disable import/no-cycle */
const typesafe_actions_1 = require("typesafe-actions");
const consts_1 = require("./consts");
exports.selectTool = (0, typesafe_actions_1.createAction)(consts_1.SELECT_TOOL)();
exports.selectItem = (0, typesafe_actions_1.createAction)(consts_1.SELECTED_ITEM)();
exports.updateUndos = (0, typesafe_actions_1.createAction)(consts_1.UNDO)();
exports.updateRedos = (0, typesafe_actions_1.createAction)(consts_1.REDO)();
exports.updateDrawingAngled = (0, typesafe_actions_1.createAction)(consts_1.DRAWING_ANGLED)();
