"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.signupUser = exports.sendChangeUserEmailMail = exports.sendRestorationMail = exports.loginUser = exports.validateToken = exports.requestAPI = void 0;
const axiosInstance_1 = require("~/store/api/axiosInstance");
const generateAuthBasicHeader_1 = require("~/utils/generateAuthBasicHeader");
const requestAPI = (sessionToken) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .get(`/`, { headers: { 'x-session-token': sessionToken } })
        .then(({ data }) => data)
        .catch((error) => error.response.data);
});
exports.requestAPI = requestAPI;
const validateToken = () => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance.get(`/auth`).then(({ data }) => data);
});
exports.validateToken = validateToken;
const loginUser = (reqData) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .post(`/auth/login`, {}, { headers: Object.assign({}, (0, generateAuthBasicHeader_1.generateAuthBasicHeader)(reqData.email, reqData.password)) })
        .then(({ data }) => data)
        .catch((err) => {
        return { status: err.response.status };
    });
});
exports.loginUser = loginUser;
const sendRestorationMail = (userEmail) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosInstance_1.axiosInstance.post('/auth/restore', { userEmail });
    return response.data;
});
exports.sendRestorationMail = sendRestorationMail;
const sendChangeUserEmailMail = (newEmail, password) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .post('/auth/email', { newEmail, password })
        .then(({ data }) => data)
        .catch((error) => error.response.data);
});
exports.sendChangeUserEmailMail = sendChangeUserEmailMail;
const signupUser = (reqData) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .post(`/auth/signup`, {
        userData: {
            firstName: reqData.firstName,
            lastName: reqData.lastName,
            companyName: reqData.companyName,
            invitedBy: reqData.invitedBy,
        },
    }, {
        headers: Object.assign({}, (0, generateAuthBasicHeader_1.generateAuthBasicHeader)(reqData.email, reqData.password)),
    })
        .then(({ data }) => data);
});
exports.signupUser = signupUser;
