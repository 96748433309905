"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const three_1 = require("three");
const utils_1 = require("../utils");
class HoverHelper {
    constructor(viewer) {
        this.viewer = viewer;
    }
    testHoveredObjects(excludeIDs = new Set()) {
        const hoverTestResult = {
            point: new three_1.Vector3(0, 0, 0),
            normal: new three_1.Vector3(0, 0, -1),
            object: null,
        };
        let hoveredObjectIntersection = null;
        let minIntersectionDistance = Infinity;
        let hoveredGroupBoxIndex = 0;
        let hoveredObjectIndex = 0;
        const groupBoxes = this.viewer.collisionProcessor.getGroupBoxes();
        for (let groupBoxIndex = 0; groupBoxIndex < groupBoxes.length; groupBoxIndex += 1) {
            const groupBox = groupBoxes[groupBoxIndex];
            const [groupBoxIntersection] = this.viewer.getRaycaster().intersectObject(groupBox.getGroupBoxMesh(), true);
            // eslint-disable-next-line no-continue
            if (!groupBoxIntersection) {
                continue;
            }
            for (let objectIndex = 0; objectIndex < groupBox.getObjectsQty(); objectIndex += 1) {
                const outerBox = groupBox.getObjectOuterBox(objectIndex);
                if (!utils_1.Utils.isFastener(outerBox.parent)) {
                    const [objectIntersection] = this.viewer.getRaycaster().intersectObject(outerBox, true);
                    if ((objectIntersection === null || objectIntersection === void 0 ? void 0 : objectIntersection.distance) < minIntersectionDistance &&
                        !excludeIDs.has(groupBox.getUUID(objectIndex))) {
                        minIntersectionDistance = objectIntersection.distance;
                        hoveredObjectIntersection = objectIntersection;
                        hoveredObjectIndex = objectIndex;
                        hoveredGroupBoxIndex = groupBoxIndex;
                    }
                }
            }
        }
        if (hoveredObjectIntersection === null || hoveredObjectIntersection === void 0 ? void 0 : hoveredObjectIntersection.face) {
            const hoveredFaceNormal = hoveredObjectIntersection.face.normal;
            const hoveredObjectParent = hoveredObjectIntersection.object.parent;
            if (hoveredObjectParent) {
                hoveredFaceNormal.applyQuaternion(hoveredObjectParent.quaternion);
            }
            hoverTestResult.normal = hoveredFaceNormal;
            hoverTestResult.point = hoveredObjectIntersection.point;
            hoverTestResult.object = groupBoxes[hoveredGroupBoxIndex].getObject(hoveredObjectIndex);
        }
        else {
            const [backgroundPlaneIntersection] = this.viewer
                .getRaycaster()
                .intersectObject(this.viewer.getBackgroundPlane(), true);
            if (backgroundPlaneIntersection === null || backgroundPlaneIntersection === void 0 ? void 0 : backgroundPlaneIntersection.face) {
                hoverTestResult.normal = backgroundPlaneIntersection.face.normal;
                hoverTestResult.point = backgroundPlaneIntersection.point;
            }
        }
        return hoverTestResult;
    }
}
exports.default = HoverHelper;
