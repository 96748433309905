"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_INITIAL_VALUES = exports.TOGGLE_VISIBLE_FRAME = exports.SET_INPUT_FIELD_VALUE = exports.SET_SELECT_FIELD_VALUE = exports.STATE_KEY_BY_VISIBLE_FRAME = exports.FIELD_DEFINITIONS_BY_VISIBLE_FRAME = void 0;
const types_1 = require("./types");
exports.FIELD_DEFINITIONS_BY_VISIBLE_FRAME = {
    [types_1.IVisibleFrameType.BOX_ITEM]: [
        types_1.FieldName.seriesType,
        types_1.FieldName.series,
        types_1.FieldName.profileType,
        types_1.FieldName.extrusion,
        types_1.FieldName.width,
        types_1.FieldName.length,
        types_1.FieldName.height,
    ],
    [types_1.IVisibleFrameType.FRAME_ITEM]: [
        types_1.FieldName.seriesType,
        types_1.FieldName.series,
        types_1.FieldName.profileType,
        types_1.FieldName.extrusion,
        types_1.FieldName.panel,
        types_1.FieldName.width,
        types_1.FieldName.height,
    ],
    [types_1.IVisibleFrameType.DOOR_ITEM]: [
        types_1.FieldName.seriesType,
        types_1.FieldName.series,
        types_1.FieldName.profileType,
        types_1.FieldName.extrusion,
        types_1.FieldName.handle,
        types_1.FieldName.hinges,
        types_1.FieldName.latch,
        types_1.FieldName.panel,
        types_1.FieldName.width,
        types_1.FieldName.height,
    ],
    [types_1.IVisibleFrameType.DEFAULT]: [],
};
exports.STATE_KEY_BY_VISIBLE_FRAME = {
    [types_1.IVisibleFrameType.BOX_ITEM]: 'boxState',
    [types_1.IVisibleFrameType.DOOR_ITEM]: 'doorState',
    [types_1.IVisibleFrameType.FRAME_ITEM]: 'frameState',
    [types_1.IVisibleFrameType.DEFAULT]: '',
};
exports.SET_SELECT_FIELD_VALUE = 'SET_SELECT_FIELD_VALUE';
exports.SET_INPUT_FIELD_VALUE = 'SET_INPUT_FIELD_VALUE';
exports.TOGGLE_VISIBLE_FRAME = 'TOGGLE_VISIBLE_FRAME';
exports.SET_INITIAL_VALUES = 'SET_INITIAL_VALUES';
