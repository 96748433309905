"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const consts_1 = require("../consts");
// eslint-disable-next-line import/no-cycle
const utils_3d_1 = require("../utils/utils.3d");
class GridControlsHelper extends THREE.Group {
    constructor(viewer, size, cellSize = consts_1.consts.DEFAULT_GRID_CELL_SIZE, divisionsPerCell = consts_1.consts.DEFAULT_GRID_DIVISIONS_PER_CELL) {
        super();
        this.geometry = null;
        this.material = null;
        this.size = null;
        this.divisions = null;
        this.viewer = viewer;
        this.cellSize = cellSize;
        this.divisionsPerCell = divisionsPerCell;
        this.subcellSize = cellSize / divisionsPerCell;
        this.buildGrid(size);
        this.rotateX(Math.PI / 2);
    }
    dispose() {
        this.geometry.dispose();
        this.material.dispose();
    }
    buildGrid(gridSize) {
        const color1 = consts_1.consts.DEFAULT_GRID_MAJOR_COLOR;
        const color2 = consts_1.consts.DEFAULT_GRID_MINOR_COLOR;
        const bigDivisions = Math.ceil(gridSize / (2 * this.cellSize));
        this.size = bigDivisions * this.cellSize * 2;
        this.divisions = Math.ceil(this.size / (2 * this.subcellSize));
        const opacity = 0.3;
        this.geometry = new THREE.BufferGeometry();
        this.material = new THREE.LineBasicMaterial({
            vertexColors: true,
        });
        this.setPositionAttribute();
        this.setColorAttribute(color2, color1, this.size);
        this.material.transparent = true;
        this.material.opacity = opacity;
        this.add(new THREE.LineSegments(this.geometry, this.material));
        this.mainGrid = new THREE.GridHelper(this.size, 2, consts_1.consts.DEFAULT_SUBGRID_COLOR, 'lightgrey');
        const geometry = new THREE.PlaneGeometry(this.size, this.size);
        const planeMaterial = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            side: THREE.DoubleSide,
        });
        planeMaterial.transparent = true;
        planeMaterial.opacity = 0.1;
        this.gridPlane = new THREE.Mesh(geometry, planeMaterial);
        this.gridPlane.rotateX(Math.PI / 2);
        this.add(this.gridPlane);
        this.add(this.mainGrid);
        // Buildplane must be rendered last; otherwise, the parts displayed may overlap if the camera is on the back of the Buildplane.
        this.renderOrder = consts_1.consts.BUILD_PLANE_RENDER_ORDER;
    }
    setColorAttribute(minorLineColor, majorLineColor, gridSize) {
        const minorColor = new THREE.Color(minorLineColor);
        const majorColor = new THREE.Color(majorLineColor);
        const step = this.size / this.divisions;
        const halfSize = gridSize / 2;
        const colors = [];
        const subcellsCount = this.size / this.subcellSize;
        for (let i = 0, j = 0, k = -halfSize; i <= subcellsCount; i += 1, k += step) {
            let color = i % this.divisionsPerCell ? minorColor : majorColor;
            if (i % (subcellsCount / 2) === 0) {
                color = new THREE.Color(consts_1.consts.DEFAULT_SUBGRID_COLOR);
            }
            color.toArray(colors, j);
            j += 3;
            color.toArray(colors, j);
            j += 3;
            color.toArray(colors, j);
            j += 3;
            color.toArray(colors, j);
            j += 3;
        }
        this.geometry.deleteAttribute('color');
        this.geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    }
    setPositionAttribute() {
        const step = this.subcellSize;
        const halfSize = this.size / 2;
        const vertices = [];
        for (let i = 0, k = -halfSize; i <= this.size / this.subcellSize; i += 1, k += step) {
            vertices.push(-halfSize, 0, k, halfSize, 0, k);
            vertices.push(k, 0, -halfSize, k, 0, halfSize);
        }
        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    }
    setBuildPlaneSize(offset) {
        if (offset <= consts_1.consts.DEFAULT_GRID_SIZE / 2 - consts_1.consts.DEFAULT_GRID_CELL_SIZE) {
            return;
        }
        this.dispose();
        [...this.children].forEach((child) => this.remove(child));
        this.remove(this.gridPlane);
        this.buildGrid(offset * 2);
    }
    setBuildPlaneVerticalPosition(coordinateZ) {
        if ((0, utils_3d_1.areEqual)(this.position.z, coordinateZ)) {
            return;
        }
        this.position.setZ(coordinateZ);
    }
    getGridCurrentPosition() {
        return this.position;
    }
}
exports.default = GridControlsHelper;
