"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HistoryEventEmitter = void 0;
const rxjs_1 = require("rxjs");
// eslint-disable-next-line import/prefer-default-export
class HistoryEventEmitter {
    constructor() {
        this.activeHandlers = new rxjs_1.BehaviorSubject(0);
        this.subjects = {};
    }
    // eslint-disable-next-line class-methods-use-this
    createName(name) {
        return `$ ${name}`;
    }
    emit(name, data) {
        const fnName = this.createName(name);
        if (!this.subjects[fnName]) {
            this.subjects[fnName] = new rxjs_1.Subject();
        }
        this.subjects[fnName].next(data);
    }
    listen(name, handler) {
        const fnName = this.createName(name);
        if (!this.subjects[fnName]) {
            this.subjects[fnName] = new rxjs_1.Subject();
        }
        let next;
        if (name !== 'historyChanged') {
            next = (value) => {
                this.activeHandlers.next(this.activeHandlers.value + 1);
                handler(value);
                setTimeout(() => this.activeHandlers.next(this.activeHandlers.value - 1), 100);
            };
        }
        else {
            next = handler;
        }
        return this.subjects[fnName].subscribe(next);
    }
    dispose() {
        this.activeHandlers.next(0);
        // eslint-disable-next-line no-restricted-syntax
        for (const prop in this.subjects) {
            if (Object.hasOwnProperty.call(this.subjects, prop)) {
                this.subjects[prop].complete();
            }
        }
        this.subjects = {};
    }
}
exports.HistoryEventEmitter = HistoryEventEmitter;
