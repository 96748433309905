"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExportQueue = exports.duplicateProject = exports.closeProject = exports.deleteProject = exports.updateProject = exports.createProject = exports.getFullProject = exports.getProject = exports.postScreenshot = void 0;
const axiosInstance_1 = require("~/store/api/axiosInstance");
const postScreenshot = (data, projectId) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance.post(`/projects/${projectId}/screenshot`, { data });
});
exports.postScreenshot = postScreenshot;
const getProject = (projectId) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance.get(`/projects/${projectId}`).then(({ data }) => data);
});
exports.getProject = getProject;
const getFullProject = (projectId) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance.get(`/projects/${projectId}/full`).then(({ data }) => data);
});
exports.getFullProject = getFullProject;
const createProject = (projectData) => __awaiter(void 0, void 0, void 0, function* () {
    const { id } = projectData, createProjectData = __rest(projectData, ["id"]);
    return axiosInstance_1.axiosInstance.post(`/projects/`, createProjectData).then(({ data }) => data);
});
exports.createProject = createProject;
const updateProject = (projectData) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance.put(`/projects/${projectData.id}`, projectData);
});
exports.updateProject = updateProject;
const deleteProject = (projectId) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance.delete(`/projects/${projectId}`);
});
exports.deleteProject = deleteProject;
const closeProject = (projectId) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance.get(`/projects/${projectId}/close`);
});
exports.closeProject = closeProject;
const duplicateProject = (projectDuplicationData) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .post(`/projects/${projectDuplicationData.id}/duplicate`, projectDuplicationData)
        .then(({ data }) => data);
});
exports.duplicateProject = duplicateProject;
const getExportQueue = () => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance.get(`/projects/exportQueue`).then(({ data }) => data);
});
exports.getExportQueue = getExportQueue;
exports.default = exports.postScreenshot;
