"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SnappingPointType = void 0;
const three_1 = require("three");
// eslint-disable-next-line no-shadow
var SnappingPointType;
(function (SnappingPointType) {
    SnappingPointType[SnappingPointType["Inbound"] = 0] = "Inbound";
    SnappingPointType[SnappingPointType["Outbound"] = 1] = "Outbound";
    SnappingPointType[SnappingPointType["RecommendedFastener"] = 2] = "RecommendedFastener";
})(SnappingPointType = exports.SnappingPointType || (exports.SnappingPointType = {}));
class PartMetadata {
    constructor() {
        this.boundingBoxSize = null;
        this.alignmentAxis = null;
        this.masterSnappingPoints = null;
        this.inboundSnappingPoints = null;
        this.inboundSnappingPointsNormals = null;
        this.outboundSnappingPoints = null;
        this.outboundSnappingPointsNormals = null;
        this.recommendedFastenerPoints = null;
        this.rectangles = null;
        this.panelWireRadius = null;
        this.panelWireHorizontalStep = null;
        this.panelWireVerticalStep = null;
    }
    clone() {
        return new PartMetadata().copy(this);
    }
    copy(source) {
        var _a, _b, _c;
        this.boundingBoxSize = PartMetadata.makeVector3Copy(source.boundingBoxSize);
        this.masterSnappingPoints = PartMetadata.makePointArrayCopy(source.masterSnappingPoints);
        this.alignmentAxis = PartMetadata.makeVector3Copy(source.alignmentAxis);
        this.inboundSnappingPoints = PartMetadata.makePointArrayCopy(source.inboundSnappingPoints);
        this.inboundSnappingPointsNormals = PartMetadata.makePointArrayCopy(source.inboundSnappingPointsNormals);
        this.outboundSnappingPoints = PartMetadata.makePointArrayCopy(source.outboundSnappingPoints);
        this.outboundSnappingPointsNormals = PartMetadata.makePointArrayCopy(source.outboundSnappingPointsNormals);
        this.recommendedFastenerPoints = PartMetadata.makePointArrayCopy(source.recommendedFastenerPoints);
        this.rectangles = PartMetadata.makeRectanglesCopy(source.rectangles);
        this.panelWireRadius = (_a = source.panelWireRadius) !== null && _a !== void 0 ? _a : null;
        this.panelWireHorizontalStep = (_b = source.panelWireHorizontalStep) !== null && _b !== void 0 ? _b : null;
        this.panelWireVerticalStep = (_c = source.panelWireVerticalStep) !== null && _c !== void 0 ? _c : null;
        return this;
    }
    static makeVector3Copy(source) {
        if (!source) {
            return null;
        }
        return source.isVector3 ? source.clone() : new three_1.Vector3().fromArray(source);
    }
    static makePointArrayCopy(source) {
        if (!source) {
            return null;
        }
        return source.reduce((copiedPoints, point) => {
            copiedPoints.push(PartMetadata.makeVector3Copy(point));
            return copiedPoints;
        }, []);
    }
    static makeRectanglesCopy(source) {
        if (!source) {
            return null;
        }
        return source.reduce((copiedRectangles, rect) => {
            copiedRectangles.push({
                number: rect.number,
                points: PartMetadata.makePointArrayCopy(rect.points),
            });
            return copiedRectangles;
        }, []);
    }
}
exports.default = PartMetadata;
