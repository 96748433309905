"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const consts_1 = require("~/consts/consts");
class FpsCounter {
    constructor(onFpsUpdated, updateOnEveryNthFrame = 5) {
        this.cycleStart = performance.now();
        this.ticksInCycle = 0;
        this.onFpsUpdated = onFpsUpdated;
        this.updateOnEveryNthFrame = updateOnEveryNthFrame;
    }
    get fps() {
        // eslint-disable-next-line no-underscore-dangle
        return this._fps;
    }
    tick() {
        if (this.ticksInCycle % this.updateOnEveryNthFrame === 0) {
            const time = performance.now();
            const timeForCycle = time - this.cycleStart;
            // eslint-disable-next-line no-underscore-dangle
            this._fps = this.updateOnEveryNthFrame / (timeForCycle / consts_1.MS_IN_SECOND);
            this.cycleStart = time;
            // eslint-disable-next-line no-underscore-dangle
            this.onFpsUpdated(this._fps);
            this.ticksInCycle = 0;
        }
        this.ticksInCycle += 1;
    }
}
exports.default = FpsCounter;
