"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROUTE_NOT_FOUND = exports.ROUTE_ADD_CUSTOMER = exports.ROUTE_EDIT_CUSTOMER = exports.ROUTE_CUSTOMER = exports.ROUTE_CUSTOMERS = exports.ROUTE_EDIT_PROJECT = exports.ROUTE_CREATE_PROJECT = exports.ROUTE_PROJECT = exports.ROUTE_PROJECTS = exports.CHANGE_PASSWORD = exports.CHANGE_EMAIL = exports.INVITE_TEAM_MEMBER = exports.ROUTE_TEAM = exports.ROUTE_PROFILE = exports.ROUTE_SIGNUP = exports.ROUTE_LOGIN = exports.ROUTE_SIGNOUT = exports.RESTORE_PASSWORD = exports.ROUTE_VIEWER = exports.ROUTE_HOME = void 0;
exports.ROUTE_HOME = '/';
exports.ROUTE_VIEWER = '/cad3d';
exports.RESTORE_PASSWORD = '/restore';
exports.ROUTE_SIGNOUT = '/signout';
exports.ROUTE_LOGIN = '/login';
exports.ROUTE_SIGNUP = '/signup';
exports.ROUTE_PROFILE = '/user/profile';
exports.ROUTE_TEAM = '/user/team';
exports.INVITE_TEAM_MEMBER = '/user/team/invite';
exports.CHANGE_EMAIL = '/user/email';
exports.CHANGE_PASSWORD = '/user/password';
exports.ROUTE_PROJECTS = '/projects';
exports.ROUTE_PROJECT = '/projects/:projectId';
exports.ROUTE_CREATE_PROJECT = '/projects/new';
exports.ROUTE_EDIT_PROJECT = '/projects/edit/:projectId';
exports.ROUTE_CUSTOMERS = '/customers';
exports.ROUTE_CUSTOMER = '/customers/:customerId';
exports.ROUTE_EDIT_CUSTOMER = '/customers/edit/:customerId';
exports.ROUTE_ADD_CUSTOMER = '/customers/new';
exports.ROUTE_NOT_FOUND = '*';
