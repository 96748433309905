"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateAuthBasicHeader = void 0;
const buffer_1 = require("buffer");
const generateAuthBasicHeader = (email, password) => {
    const token = buffer_1.Buffer.from(`${email}:${password}`, 'utf8').toString('base64');
    return { Authorization: `Basic ${token}` };
};
exports.generateAuthBasicHeader = generateAuthBasicHeader;
exports.default = generateAuthBasicHeader;
