"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_CUSTOMER_DATA = exports.FETCH_BUNDLE_VERSION_SUCCESS = exports.FETCH_BUNDLE_VERSION = exports.FETCH_CUSTOMERS_CONNECTED_TO_DISTRIBUTOR_SUCCESS = exports.FETCH_CUSTOMERS_CONNECTED_TO_DISTRIBUTOR = exports.FETCH_DATA_ERROR = exports.FETCH_DATA_SUCCESS = exports.FETCH_DATA = void 0;
exports.FETCH_DATA = 'FETCH_DATA';
exports.FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
exports.FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';
exports.FETCH_CUSTOMERS_CONNECTED_TO_DISTRIBUTOR = 'FETCH_CUSTOMERS_CONNECTED_TO_DISTRIBUTOR';
exports.FETCH_CUSTOMERS_CONNECTED_TO_DISTRIBUTOR_SUCCESS = 'FETCH_CUSTOMERS_CONNECTED_TO_DISTRIBUTOR_SUCCESS';
exports.FETCH_BUNDLE_VERSION = 'FETCH_BUNDLE_VERSION';
exports.FETCH_BUNDLE_VERSION_SUCCESS = 'FETCH_BUNDLE_VERSION_SUCCESS';
exports.UPDATE_CUSTOMER_DATA = 'UPDATE_CUSTOMER_DATA';
