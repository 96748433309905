"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class FacesFromEdgesGenerator {
    facesFromEdges(edges) {
        const chains = this.joinEdges(edges).filter(this.validFace.bind(this));
        return chains.map((chain) => {
            return chain.map((edge) => {
                return edge[0];
            });
        });
    }
    joinEdges(edges) {
        let changes = true;
        let chains = edges.map((edge) => {
            return [edge];
        });
        while (changes) {
            changes = this.connectChains(chains);
        }
        chains = chains.filter((chain) => chain);
        return chains;
    }
    connectChains(chains) {
        let result = false;
        for (let i = 0; i < chains.length; i += 1) {
            const chainI = chains[i];
            for (let j = 0; j < chains.length; j += 1) {
                const chainJ = chains[j];
                if (chainI && chainJ) {
                    if (this.mergeChains(chainI, chainJ)) {
                        // eslint-disable-next-line no-param-reassign
                        chains[j] = null;
                        result = true;
                    }
                }
            }
        }
        return result;
    }
    mergeChains(chainA, chainB) {
        if (chainA === chainB) {
            return false;
        }
        if (this.chainStart(chainA) === this.chainEnd(chainB)) {
            // eslint-disable-next-line prefer-spread
            chainA.unshift.apply(chainA, chainB);
            return true;
        }
        if (this.chainStart(chainA) === this.chainStart(chainB)) {
            this.reverseChain(chainB);
            // eslint-disable-next-line prefer-spread
            chainA.unshift.apply(chainA, chainB);
            return true;
        }
        if (this.chainEnd(chainA) === this.chainStart(chainB)) {
            // eslint-disable-next-line prefer-spread
            chainA.push.apply(chainA, chainB);
            return true;
        }
        if (this.chainEnd(chainA) === this.chainEnd(chainB)) {
            this.reverseChain(chainB);
            // eslint-disable-next-line prefer-spread
            chainA.push.apply(chainA, chainB);
            return true;
        }
        return false;
    }
    // eslint-disable-next-line class-methods-use-this
    chainStart(chain) {
        return chain[0][0];
    }
    // eslint-disable-next-line class-methods-use-this
    chainEnd(chain) {
        return chain[chain.length - 1][1];
    }
    // eslint-disable-next-line class-methods-use-this
    reverseChain(chain) {
        chain.reverse();
        chain.forEach((edge) => {
            edge.reverse();
        });
    }
    validFace(chain) {
        return this.chainStart(chain) === this.chainEnd(chain) ? 1 : 0;
    }
}
exports.default = FacesFromEdgesGenerator;
