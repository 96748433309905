"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateCustomer = exports.addCustomer = exports.createAndAddCustomer = exports.getCustomerData = void 0;
const axiosInstance_1 = require("~/store/api/axiosInstance");
const getCustomerData = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .get(`/customers/${id}`)
        .then(({ data }) => data)
        .catch((error) => error.response.data);
});
exports.getCustomerData = getCustomerData;
const createAndAddCustomer = (customerData) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .post(`/customers/`, { customerData })
        .then(({ data }) => data)
        .catch((error) => error.response.data);
});
exports.createAndAddCustomer = createAndAddCustomer;
const addCustomer = (customerData) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .post(`/customers/add`, { customerData })
        .then(({ data }) => data)
        .catch((error) => error.response.data);
});
exports.addCustomer = addCustomer;
const updateCustomer = (customerData) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .put(`/customers/${customerData.id}`, { customerData })
        .then(({ data }) => data)
        .catch((error) => error.response.data);
});
exports.updateCustomer = updateCustomer;
