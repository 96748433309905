"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PERSISTENT_WIDGETS = exports.WIDGETS = exports.SET_SELECTED_CATEGORY_GROUP = exports.SAVE_WIDGET_SHAPE = exports.DEFAULT_WIDGET_POSITION = exports.WIDGET_POSITION = exports.WIDGET_SIZE = exports.TOGGLE_WIDGET = exports.LIFT_UP_WIDGET = exports.INIT_PERSISTENT_WIDGETS = void 0;
// action consts
exports.INIT_PERSISTENT_WIDGETS = 'INIT_PERSISTENT_WIDGETS';
exports.LIFT_UP_WIDGET = 'LIFT_UP_WIDGET';
exports.TOGGLE_WIDGET = 'TOGGLE_WIDGET';
exports.WIDGET_SIZE = 'WIDGET_SIZE';
exports.WIDGET_POSITION = 'WIDGET_POSITION';
exports.DEFAULT_WIDGET_POSITION = 'DEFAULT_WIDGET_POSITION';
exports.SAVE_WIDGET_SHAPE = 'SAVE_WIDGET_SHAPE';
exports.SET_SELECTED_CATEGORY_GROUP = 'SET_SELECTED_CATEGORY_GROUP';
// widget names
// eslint-disable-next-line no-shadow
var WIDGETS;
(function (WIDGETS) {
    WIDGETS["CATALOG_WIDGET"] = "CATALOG_WIDGET";
    WIDGETS["CATALOG_WIDGET_EDIT_MODE"] = "CATALOG_WIDGET_EDIT_MODE";
    WIDGETS["BOM_WIDGET"] = "BOM_WIDGET";
    WIDGETS["ITEM_PROPERTIES_WIDGET"] = "ITEM_PROPERTIES_WIDGET";
    WIDGETS["RECOMMENDED_FASTENERS_WIDGET"] = "RECOMMENDED_FASTENERS_WIDGET";
    WIDGETS["DEFAULT_FASTENER_WIDGET"] = "DEFAULT_FASTENER_WIDGET";
    WIDGETS["CURRENT_FASTENER_WIDGET"] = "CURRENT_FASTENER_WIDGET";
    WIDGETS["SHORTCUTS_WIDGET"] = "SHORTCUTS_WIDGET";
    WIDGETS["PRECONFIGURED_FRAMES_WIDGET"] = "PRECONFIGURED_FRAMES_WIDGET";
})(WIDGETS = exports.WIDGETS || (exports.WIDGETS = {}));
// eslint-disable-next-line no-shadow
var PERSISTENT_WIDGETS;
(function (PERSISTENT_WIDGETS) {
    PERSISTENT_WIDGETS["CATALOG_WIDGET"] = "CATALOG_WIDGET";
    PERSISTENT_WIDGETS["BOM_WIDGET"] = "BOM_WIDGET";
})(PERSISTENT_WIDGETS = exports.PERSISTENT_WIDGETS || (exports.PERSISTENT_WIDGETS = {}));
