"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HistoryOperator = void 0;
// eslint-disable-next-line import/prefer-default-export
class HistoryOperator {
    constructor(historyEventEmitter) {
        this.undos = [];
        this.redos = [];
        this.lastCmdTime = new Date();
        this.idCounter = 0;
        this.historyDisabled = false;
        this.historyEventEmitter = historyEventEmitter;
    }
    execute(cmd, optionalName) {
        const lastCmd = this.undos[this.undos.length - 1];
        const timeDifference = new Date().getTime() - this.lastCmdTime.getTime();
        const isUpdatableCmd = lastCmd && lastCmd.updatable && cmd.updatable && lastCmd.objects === cmd.objects && lastCmd.type === cmd.type;
        if (isUpdatableCmd && timeDifference < 500) {
            lastCmd.update(cmd);
            // eslint-disable-next-line no-param-reassign
            cmd = lastCmd;
        }
        else {
            // the command is not updatable and is added as a new part of the history
            this.undos.push(cmd);
            this.idCounter += 1;
            // eslint-disable-next-line no-param-reassign
            cmd.id = this.idCounter;
        }
        // eslint-disable-next-line no-param-reassign
        cmd.name = optionalName !== undefined ? optionalName : cmd.name;
        cmd.execute(false);
        // eslint-disable-next-line no-param-reassign
        cmd.inMemory = true;
        // eslint-disable-next-line no-param-reassign
        cmd.json = cmd.toJSON();
        this.lastCmdTime = new Date();
        this.redos = [];
        this.historyEventEmitter.emit('historyChanged', cmd);
    }
    undo() {
        if (this.historyDisabled) {
            console.error('Undo/Redo is disabled.');
            return null;
        }
        if (this.historyEventEmitter.activeHandlers.value > 0) {
            return null;
        }
        let cmd = null;
        if (this.undos.length > 0) {
            cmd = this.undos.pop();
            if (cmd.inMemory === false) {
                cmd.fromJSON(cmd.json);
            }
        }
        if (cmd !== null) {
            cmd.undo();
            this.redos.push(cmd);
            this.historyEventEmitter.emit('historyChanged', cmd);
        }
        return cmd;
    }
    redo() {
        if (this.historyDisabled) {
            console.error('Undo/Redo is disabled.');
            return null;
        }
        if (this.historyEventEmitter.activeHandlers.value > 0) {
            return null;
        }
        let cmd = null;
        if (this.redos.length > 0) {
            cmd = this.redos.pop();
            if (cmd.inMemory === false) {
                cmd.fromJSON(cmd.json);
            }
        }
        if (cmd !== null) {
            cmd.execute(true);
            this.undos.push(cmd);
            this.historyEventEmitter.emit('historyChanged', cmd);
        }
        return cmd;
    }
    clear() {
        this.undos = [];
        this.redos = [];
        this.idCounter = 0;
        this.historyEventEmitter.emit('historyChanged', null);
    }
    clearLatest(n) {
        const { length } = this.redos;
        for (let i = 0; i < length; i += 1) {
            this.redo();
        }
        for (let i = 0; i < n + length; i += 1) {
            this.undo();
            this.redos.pop();
        }
        this.historyEventEmitter.emit('historyChanged', null);
    }
    getUndosList() {
        return this.undos;
    }
    getRedosList() {
        return this.redos;
    }
}
exports.HistoryOperator = HistoryOperator;
