"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectIncludedCompatibleFastenersLoadingState = exports.selectIncludedCompatibleFasteners = exports.selectAllSeries = exports.selectCatalogItemByItemNumber = exports.selectCatalogItems = exports.selectCategoriesWithItems = exports.selectCatalogGroupsWithItems = exports.selectCatalog = void 0;
const reselect_1 = require("reselect");
exports.selectCatalog = (0, reselect_1.createSelector)((state) => state.catalog, (catalog) => catalog);
exports.selectCatalogGroupsWithItems = (0, reselect_1.createSelector)((state) => ({
    catalog: state.catalog,
    catalogMeta: state.catalogMeta,
    catalogState: state.catalogState,
    editMode: state.bom.editMode,
}), ({ catalog, catalogMeta, catalogState, editMode, }) => {
    var _a;
    if (!window.data || !catalogMeta.data || !catalogState.seriesType || !catalogState.series) {
        return [];
    }
    const itemsForCurrentSeries = window.data.filter((item) => {
        var _a;
        return (!editMode ? item.show_in_catalog && ((_a = item.componentType) === null || _a === void 0 ? void 0 : _a.name) !== 'Fastener' : true) &&
            item.series.some((series) => { var _a, _b, _c; return ((_a = series.seriesType) === null || _a === void 0 ? void 0 : _a.id) === ((_b = catalogState.seriesType) === null || _b === void 0 ? void 0 : _b.id) && series.id === ((_c = catalogState.series) === null || _c === void 0 ? void 0 : _c.id); }) &&
            item.deletedAt === null;
    });
    const msForCurrentSeries = (_a = window.dataMS) === null || _a === void 0 ? void 0 : _a.filter((machiningService) => editMode &&
        machiningService.series.some((series) => { var _a, _b, _c; return ((_a = series.seriesType) === null || _a === void 0 ? void 0 : _a.id) === ((_b = catalogState.seriesType) === null || _b === void 0 ? void 0 : _b.id) && series.id === ((_c = catalogState.series) === null || _c === void 0 ? void 0 : _c.id); }));
    return catalogState.series.categoryGroups.filter((categoryGroup) => {
        return (itemsForCurrentSeries.some((item) => item.categories.some((category) => { var _a; return ((_a = category.categoryGroup) === null || _a === void 0 ? void 0 : _a.id) === categoryGroup.id; }) &&
            categoryGroup.categories.some((category) => item.categories.some((itemCategory) => itemCategory.id === category.id))) ||
            (msForCurrentSeries &&
                msForCurrentSeries.some((ms) => ms.category.some((category) => { var _a; return ((_a = category.categoryGroup) === null || _a === void 0 ? void 0 : _a.id) === categoryGroup.id; }) &&
                    categoryGroup.categories.some((category) => ms.category.some((msCategory) => msCategory.id === category.id)))));
    });
});
exports.selectCategoriesWithItems = (0, reselect_1.createSelector)((state) => ({
    catalog: state.catalog,
    catalogMeta: state.catalogMeta,
    catalogState: state.catalogState,
    editMode: state.bom.editMode,
}), ({ catalog, catalogMeta, catalogState, editMode, }) => {
    var _a;
    if (!window.data ||
        !catalogMeta.data ||
        !catalogState.seriesType ||
        !catalogState.series ||
        !catalogState.categoryGroup) {
        return [];
    }
    const itemsForCurrentCategoryGroup = window.data.filter((item) => {
        var _a;
        return (!editMode ? item.show_in_catalog && ((_a = item.componentType) === null || _a === void 0 ? void 0 : _a.name) !== 'Fastener' : true) &&
            item.series.some((series) => { var _a, _b, _c; return series.id === ((_a = catalogState.series) === null || _a === void 0 ? void 0 : _a.id) && ((_b = series.seriesType) === null || _b === void 0 ? void 0 : _b.id) === ((_c = catalogState.seriesType) === null || _c === void 0 ? void 0 : _c.id); }) &&
            item.categories.some((category) => { var _a, _b; return ((_a = category.categoryGroup) === null || _a === void 0 ? void 0 : _a.id) === ((_b = catalogState.categoryGroup) === null || _b === void 0 ? void 0 : _b.id); }) &&
            item.deletedAt === null;
    });
    const msForCurrentCategoryGroup = (_a = window.dataMS) === null || _a === void 0 ? void 0 : _a.filter((machiningService) => editMode &&
        machiningService.series.some((series) => { var _a, _b, _c; return series.id === ((_a = catalogState.series) === null || _a === void 0 ? void 0 : _a.id) && ((_b = series.seriesType) === null || _b === void 0 ? void 0 : _b.id) === ((_c = catalogState.seriesType) === null || _c === void 0 ? void 0 : _c.id); }) &&
        machiningService.category.some((category) => { var _a, _b; return ((_a = category.categoryGroup) === null || _a === void 0 ? void 0 : _a.id) === ((_b = catalogState.categoryGroup) === null || _b === void 0 ? void 0 : _b.id); }));
    return catalogState.categoryGroup.categories.filter((category) => {
        return (itemsForCurrentCategoryGroup.some((item) => item.categories.some((itemCategory) => itemCategory.id === category.id)) ||
            (msForCurrentCategoryGroup &&
                msForCurrentCategoryGroup.some((ms) => ms.category.some((msCategory) => msCategory.id === category.id))));
    });
});
exports.selectCatalogItems = (0, reselect_1.createSelector)((state) => ({
    catalog: state.catalog,
    catalogState: state.catalogState,
    editMode: state.bom.editMode,
}), ({ catalog, catalogState, editMode, }) => {
    var _a;
    if (!window.data ||
        !catalogState.category ||
        !catalogState.categoryGroup ||
        !catalogState.series ||
        !catalogState.seriesType) {
        return [];
    }
    const items = window.data.filter((item) => {
        var _a;
        return (!editMode ? item.show_in_catalog && ((_a = item.componentType) === null || _a === void 0 ? void 0 : _a.name) !== 'Fastener' : true) &&
            item.series.some((series) => { var _a, _b, _c; return series.id === ((_a = catalogState.series) === null || _a === void 0 ? void 0 : _a.id) && ((_b = series.seriesType) === null || _b === void 0 ? void 0 : _b.id) === ((_c = catalogState.seriesType) === null || _c === void 0 ? void 0 : _c.id); }) &&
            item.categories.some((category) => { var _a, _b; return ((_a = category.categoryGroup) === null || _a === void 0 ? void 0 : _a.id) === ((_b = catalogState.categoryGroup) === null || _b === void 0 ? void 0 : _b.id); }) &&
            item.categories.some((category) => { var _a; return category.id === ((_a = catalogState.category) === null || _a === void 0 ? void 0 : _a.id); }) &&
            item.deletedAt === null;
    });
    const machiningServices = (_a = window.dataMS) === null || _a === void 0 ? void 0 : _a.filter((ms) => editMode &&
        ms.series.some((series) => { var _a, _b, _c; return series.id === ((_a = catalogState.series) === null || _a === void 0 ? void 0 : _a.id) && ((_b = series.seriesType) === null || _b === void 0 ? void 0 : _b.id) === ((_c = catalogState.seriesType) === null || _c === void 0 ? void 0 : _c.id); }) &&
        ms.category.some((category) => { var _a, _b; return ((_a = category.categoryGroup) === null || _a === void 0 ? void 0 : _a.id) === ((_b = catalogState.categoryGroup) === null || _b === void 0 ? void 0 : _b.id); }) &&
        ms.category.some((category) => { var _a; return category.id === ((_a = catalogState.category) === null || _a === void 0 ? void 0 : _a.id); }));
    let result = [];
    result = result.concat(items);
    if (machiningServices) {
        result = result.concat(machiningServices);
    }
    return result;
});
const selectCatalogItemByItemNumber = (itemNumber) => (0, reselect_1.createSelector)((state) => ({
    catalog: state.catalog,
    editMode: state.bom.editMode,
}), ({ catalog, editMode }) => {
    var _a, _b;
    if (!window.data || !itemNumber) {
        return null;
    }
    const foundItem = window.data.find((item) => {
        var _a;
        return item.item_number === itemNumber &&
            (!editMode ? ((_a = item.componentType) === null || _a === void 0 ? void 0 : _a.name) !== 'Fastener' : true) &&
            item.deletedAt === null;
    });
    if (foundItem) {
        return foundItem;
    }
    return (_b = (_a = window.dataMS) === null || _a === void 0 ? void 0 : _a.find((ms) => ms.itemNumber === itemNumber && editMode)) !== null && _b !== void 0 ? _b : null;
});
exports.selectCatalogItemByItemNumber = selectCatalogItemByItemNumber;
exports.selectAllSeries = (0, reselect_1.createSelector)((state) => window.data, (data) => {
    const result = new Map();
    if (data) {
        data.forEach((item) => {
            var _a;
            (_a = item.series) === null || _a === void 0 ? void 0 : _a.forEach((series) => {
                result.set(series.id, series.name);
            });
        });
    }
    return result;
});
exports.selectIncludedCompatibleFasteners = (0, reselect_1.createSelector)((state) => state.catalog, (catalog) => catalog.includedFastenersMountTo);
exports.selectIncludedCompatibleFastenersLoadingState = (0, reselect_1.createSelector)((state) => state.catalog, (catalog) => catalog.isIncludedCompatibleFastenerLoading);
