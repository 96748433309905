"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCurrentTabId = exports.changeEmailAction = exports.changePasswordAction = exports.toggleAccountDropDown = void 0;
/* eslint-disable import/no-cycle */
const typesafe_actions_1 = require("typesafe-actions");
const consts_1 = require("./consts");
exports.toggleAccountDropDown = (0, typesafe_actions_1.createAction)(consts_1.TOGGLE_ACCOUNT_DROPDOWN)();
exports.changePasswordAction = (0, typesafe_actions_1.createAction)(consts_1.CHANGE_PASSWORD)();
exports.changeEmailAction = (0, typesafe_actions_1.createAction)(consts_1.CHANGE_EMAIL)();
exports.setCurrentTabId = (0, typesafe_actions_1.createAction)(consts_1.CURRENT_TAB)();
