"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const effects_1 = require("redux-saga/effects");
const actions_1 = require("~/store/actions");
const selectors_1 = require("~/store/selectors");
const actions_2 = require("./actions");
const consts_1 = require("./consts");
function* disableDrawingAngle() {
    yield (0, effects_1.put)((0, actions_1.updateDrawingAngled)(false));
}
function* setCategoryWithItems() {
    const categories = yield (0, effects_1.select)(selectors_1.selectCategoriesWithItems);
    yield (0, effects_1.put)((0, actions_2.setCatalogCategory)(categories[0]));
}
function* rootSaga() {
    yield (0, effects_1.all)([
        (0, effects_1.takeLatest)([consts_1.SET_CATALOG_CATEGORY_GROUP, consts_1.SET_CATALOG_SERIES, consts_1.SET_CATALOG_SERIES_TYPE], disableDrawingAngle),
        (0, effects_1.takeLatest)([consts_1.SET_CATALOG_CATEGORY_GROUP], setCategoryWithItems),
    ]);
}
exports.default = rootSaga;
