"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAddressData = exports.deleteAddressData = exports.updateAddressData = void 0;
const axiosInstance_1 = require("~/store/api/axiosInstance");
const updateAddressData = (addressData) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .put(`/address/${addressData.id}/`, { addressData })
        .then(({ data }) => data)
        .catch((error) => error.response.data);
});
exports.updateAddressData = updateAddressData;
const deleteAddressData = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .delete(`/address/${id}/`)
        .then(({ data }) => data)
        .catch((error) => {
        return error.response.data;
    });
});
exports.deleteAddressData = deleteAddressData;
const createAddressData = (addressData) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance
        .post('/address/', { addressData })
        .then(({ data }) => data)
        .catch((error) => error.response.data);
});
exports.createAddressData = createAddressData;
