"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REQUEST_INCLUDED_FASTENERS_FAIL = exports.REQUEST_INCLUDED_FASTENERS_SUCCESS = exports.REQUEST_INCLUDED_FASTENERS = exports.REQUEST_CATALOG_MS_FAIL = exports.REQUEST_CATALOG_FAIL = exports.REQUEST_CATALOG_MS_SUCCESS = exports.REQUEST_CATALOG_SUCCESS = exports.REQUEST_CATALOG = void 0;
exports.REQUEST_CATALOG = 'REQUEST_CATALOG';
exports.REQUEST_CATALOG_SUCCESS = 'REQUEST_CATALOG_SUCCESS';
exports.REQUEST_CATALOG_MS_SUCCESS = 'REQUEST_CATALOG_MS_SUCCESS';
exports.REQUEST_CATALOG_FAIL = 'REQUEST_CATALOG_FAIL';
exports.REQUEST_CATALOG_MS_FAIL = 'REQUEST_CATALOG_MS_FAIL';
exports.REQUEST_INCLUDED_FASTENERS = 'REQUEST_INCLUDED_FASTENERS';
exports.REQUEST_INCLUDED_FASTENERS_SUCCESS = 'REQUEST_INCLUDED_FASTENERS_SUCCESS';
exports.REQUEST_INCLUDED_FASTENERS_FAIL = 'REQUEST_INCLUDED_FASTENERS_FAIL';
