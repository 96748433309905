"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_USER_DISCOUNTS = exports.GET_USER_DISCOUNTS = exports.SET_USER_ADDRESS = exports.RESPONSE_ERRORS = exports.RESEND_INVITATION = exports.INVITE_TEAM_MEMBER = exports.SET_USER_DATA_SUCCESS = exports.UPDATE_DISTRIBUTOR_INFO = exports.TRANSFER_OWNERSHIP = exports.DELETE_USER_SUCCESS = exports.DELETE_USER = exports.UPDATE_USER_INFO = exports.SET_USER_DATA = void 0;
exports.SET_USER_DATA = 'SET_USER_DATA';
exports.UPDATE_USER_INFO = 'UPDATE_USER_INFO';
exports.DELETE_USER = 'DELETE_USER';
exports.DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
exports.TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP';
exports.UPDATE_DISTRIBUTOR_INFO = 'UPDATE_DISTRIBUTOR_INFO';
exports.SET_USER_DATA_SUCCESS = 'SET_USER_DATA_SUCCESS';
exports.INVITE_TEAM_MEMBER = 'INVITE_TEAM_MEMBER';
exports.RESEND_INVITATION = 'RESEND_INVITATION';
exports.RESPONSE_ERRORS = 'RESPONSE_ERRORS';
exports.SET_USER_ADDRESS = 'SET_USER_ADDRESS';
exports.GET_USER_DISCOUNTS = 'GET_USER_DISCOUNTS';
exports.SET_USER_DISCOUNTS = 'SET_USER_DISCOUNTS';
