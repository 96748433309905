"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetRotationCommand = void 0;
/* eslint-disable no-param-reassign */
const three_1 = require("three");
// eslint-disable-next-line import/no-cycle
const types_1 = require("~/store/types");
// eslint-disable-next-line import/no-cycle
const events_1 = require("~/utils/events");
// eslint-disable-next-line import/no-cycle
const Command_1 = require("./Command");
const utils_3d_1 = require("../utils/utils.3d");
// eslint-disable-next-line import/prefer-default-export
class SetRotationCommand extends Command_1.ViewerCommand {
    constructor(objects, viewer, historyService) {
        super();
        this.newPositions = [];
        this.oldPositions = [];
        this.newRotations = [];
        this.oldRotations = [];
        this.type = 'SetRotationCommand';
        this.name = 'Set Rotation';
        this.updatable = true;
        this.objects = objects;
        this.viewer = viewer;
        this.historyService = historyService;
        this.objects.forEach((object) => {
            this.newPositions.push(object.position.clone());
            this.oldPositions.push(object.positionOnDown.clone());
            this.newRotations.push(object.quaternion.clone());
            this.oldRotations.push(object.quaternionOnDown.clone());
        });
    }
    rotateObjects(newRotations, newPositions, oldRotations, oldPositions, appliedFromHistory) {
        this.historyService.emit(`${this.objects[0].type}Rotated`, this.objects);
        this.objects.forEach((rotatingObject, index) => {
            rotatingObject.quaternionOnDown = oldRotations[index].clone();
            rotatingObject.positionOnDown = oldPositions[index].clone();
            rotatingObject.setRotation(newRotations[index]);
            rotatingObject.setPosition(newPositions[index]);
            rotatingObject.updateMatrixWorld(true);
            this.viewer.collisionProcessor.notifyObjectUpdated(rotatingObject);
        });
        const connectionsPromises = this.viewer.getDesignProMain().updateConnectionsAndShowNotifications(...this.objects);
        Promise.all(connectionsPromises).then((connectionObjects) => {
            this.viewer.collisionProcessor.updateCollisionsForObjectsAndConnections(this.objects, connectionObjects.filter((connectionObject) => connectionObject !== null));
            const events = [];
            this.objects.forEach((object) => {
                var _a, _b, _c;
                const connections = this.viewer.getDesignProMain().connectionManager.getRelatedConnections(object);
                events.push({
                    actionType: types_1.ACTION_TYPE.ROTATE,
                    size: object.getLength(),
                    geomkernelPosition: object.position,
                    position: (_a = object.originalPosition) !== null && _a !== void 0 ? _a : object.position,
                    itemNumber: (_b = object.partDetails) === null || _b === void 0 ? void 0 : _b.itemNumber,
                    itemType: object.type,
                    connections,
                    uuid: object.uuid,
                    slicePlanes: object.slicePlanes,
                    geomkernelPanelSize: object.getSize(),
                    panelSize: (_c = object.originalPanelSize) !== null && _c !== void 0 ? _c : object.getSize(),
                    rotation: {
                        x: object.quaternion.x,
                        y: object.quaternion.y,
                        z: object.quaternion.z,
                        w: object.quaternion.w,
                    },
                    appliedFromHistory,
                });
            });
            // TODO: remove later
            if ((0, utils_3d_1.isFastener)(this.objects[0])) {
                return;
            }
            document.body.dispatchEvent((0, events_1.updateItemsToBOMEvent)(events));
        });
    }
    execute(appliedFromHistory) {
        this.rotateObjects(this.newRotations, this.newPositions, this.oldRotations, this.oldPositions, appliedFromHistory);
    }
    undo() {
        this.rotateObjects(this.oldRotations, this.oldPositions, this.newRotations, this.newPositions, false);
    }
    update(command) {
        this.objects = [...command.objects];
        this.newPositions = [...command.newPositions];
        this.oldPositions = [...command.oldPositions];
        this.newRotations = [...command.newRotations];
        this.oldRotations = [...command.oldRotations];
    }
    toJSON() {
        const rotationsData = [];
        this.objects.forEach((object, index) => {
            rotationsData.push({
                objectUuid: object.uuid,
                newPosition: this.newPositions[index].toArray(),
                oldPosition: this.oldPositions[index].toArray(),
                newRotation: this.newRotations[index].toArray(),
                oldRotation: this.oldRotations[index].toArray(),
            });
        });
        return {
            type: this.type,
            id: this.id,
            name: this.name,
            rotationsData,
        };
    }
    fromJSON(json) {
        this.getFromJSON(json);
        this.objects = [];
        this.newPositions = [];
        this.oldPositions = [];
        this.newRotations = [];
        this.oldRotations = [];
        json.rotationsData.forEach((rotationData) => {
            this.objects.push(this.viewer.getObjectById(rotationData.objectUuid));
            this.newPositions.push(new three_1.Vector3().fromArray(rotationData.newPosition));
            this.oldPositions.push(new three_1.Vector3().fromArray(rotationData.oldPosition));
            this.newRotations.push(new three_1.Quaternion().fromArray(rotationData.newRotation));
            this.oldRotations.push(new three_1.Quaternion().fromArray(rotationData.oldRotation));
        });
    }
}
exports.SetRotationCommand = SetRotationCommand;
