"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotchType = exports.fastenerBoxExpand = exports.notchDistance = void 0;
exports.notchDistance = 1 / 16;
exports.fastenerBoxExpand = 0.05;
exports.NotchType = {
    Extrusion: 'extrusion',
    Fastener: 'fastener',
    Corner: 'corner',
};
