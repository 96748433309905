"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DragControls = void 0;
const three_1 = require("three");
// eslint-disable-next-line import/no-cycle
const utils_1 = require("../../utils");
// eslint-disable-next-line import/no-cycle
const utils_3d_1 = require("../../utils/utils.3d");
const consts_1 = require("../../consts");
const consts_2 = require("../../consts/consts");
const tempQuaternion = new three_1.Quaternion();
const tempVector = new three_1.Vector3();
// eslint-disable-next-line import/prefer-default-export
class DragControls extends three_1.EventDispatcher {
    constructor(camera, htmlElement, surfaceMeshes, viewer, mainTool) {
        super();
        this.camera = camera;
        this.htmlElement = htmlElement;
        this.surfaceMeshes = surfaceMeshes;
        this.mouse = new three_1.Vector2();
        this.offset = new three_1.Vector3();
        this.intersection = new three_1.Vector3();
        this.worldPosition = new three_1.Vector3();
        this.inverseMatrix = new three_1.Matrix4();
        this.intersections = [];
        this.plane = new three_1.Plane();
        this.raycaster = new three_1.Raycaster();
        this.meshRaycaster = new three_1.Raycaster();
        this.enabled = true;
        this.endDraggingOnMouseCancel = true;
        this.objects = [];
        this.activate();
        this.viewer = viewer;
        this.mainTool = mainTool;
        this.plane.normal.set(0, 0, 1);
    }
    get masterObject() {
        let masterObject = this.objects.find((object) => { var _a; return (_a = object.copyInfo) === null || _a === void 0 ? void 0 : _a.isMasterPart; });
        if (!masterObject) {
            masterObject = this.mainTool.selectionTool.getMasterPart();
            if (!masterObject && this.objects.length >= 1) {
                return this.objects[0];
            }
        }
        return masterObject;
    }
    get notMasterObjects() {
        return this.objects.filter((object) => {
            return object.uuid !== this.masterObject.uuid;
        });
    }
    onDocumentMouseMove(event) {
        var _a, _b, _c;
        event.preventDefault();
        if (this.masterObject && this.masterObject.type === 'Panel' && this.masterObject.cutContour.length) {
            return;
        }
        if (this.mainTool.copyInProgress && this.masterObject) {
            if (((_a = this.masterObject.copyInfo) === null || _a === void 0 ? void 0 : _a.originQuaternion) &&
                utils_1.Utils.areQuaternionsNotEqual(this.masterObject.quaternion, this.masterObject.copyInfo.originQuaternion)) {
                this.masterObject.setRotationFromQuaternion((_b = this.masterObject) === null || _b === void 0 ? void 0 : _b.copyInfo.originQuaternion);
            }
            if (((_c = this.masterObject.copyInfo) === null || _c === void 0 ? void 0 : _c.originZ) !== undefined &&
                utils_1.Utils.areNotEqual(this.masterObject.position.z, this.masterObject.copyInfo.originZ)) {
                this.masterObject.position.z = this.masterObject.copyInfo.originZ;
            }
        }
        const rect = this.htmlElement.getBoundingClientRect();
        this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, this.camera);
        if (this.objects.length && this.mainTool.draggingInProgress && this.masterObject && this.enabled) {
            this.mainTool.snappingTool.wrongConnectionErrorType = null;
            this.mainTool.snappingTool.wrongConnectionMessage = null;
            this.objects.forEach((object) => {
                // eslint-disable-next-line no-param-reassign
                object.snappedToAngledExtrusion = false;
            });
            if ((event.altKey || event.ctrlKey) && event.buttons === 4) {
                return;
            }
            const { masterObject: masterPart } = this;
            const oldPos = masterPart.position.clone();
            const oldRotation = masterPart.rotation.clone();
            if (this.raycaster.ray.intersectPlane(this.plane, this.intersection)) {
                let needToHide = false;
                let collided = false;
                if (this.surfaceMeshes) {
                    this.meshRaycaster.setFromCamera(this.mouse, this.camera);
                    let meshIntersections = [];
                    for (let i = 0; i < this.surfaceMeshes.length; i += 1) {
                        const singleMeshIntersection = this.meshRaycaster.intersectObject(this.surfaceMeshes[i], true);
                        if (singleMeshIntersection.length > 0) {
                            meshIntersections = [...meshIntersections, ...singleMeshIntersection];
                        }
                    }
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    meshIntersections.sort((a, b) => (a === null || a === void 0 ? void 0 : a.distance) - (b === null || b === void 0 ? void 0 : b.distance));
                    const closestIntersection = meshIntersections[0];
                    if (meshIntersections.length > 0) {
                        if (!this.mainTool.copyInProgress) {
                            masterPart.setRotation(utils_1.Utils.identityQuaternion);
                            masterPart.rotate(tempQuaternion.setFromAxisAngle(masterPart.directionNormal, -masterPart.additionalRotationAngle));
                        }
                        if (this.positionShift) {
                            const adjustment = new three_1.Vector3(0, 0, -1).multiplyScalar(this.positionShift);
                            masterPart.setPosition(closestIntersection.point.add(adjustment));
                            collided = this.mainTool.snapHelper.try2SnapObjByBoxCenter(masterPart, masterPart.directionNormal).collided;
                        }
                        else {
                            if (masterPart.partDetails.scaleAxes === consts_2.SCALE_AXES.XY) {
                                masterPart.setPosition(utils_1.Utils.roundVectorTo3(closestIntersection.point.clone()).add(masterPart.getSize().clone().divideScalar(2).multiply(new three_1.Vector3(1, 1, 0))));
                            }
                            else {
                                const position = closestIntersection.point;
                                if (this.mainTool.copyInProgress) {
                                    const camera = this.viewer.getCamera();
                                    const mousePosition = utils_1.Utils.screenToScenePosition(this.mouse, camera);
                                    if (mousePosition) {
                                        const dir = mousePosition.sub(camera.position).normalize();
                                        const dirAngle = dir.angleTo(dir.clone().projectOnPlane(new three_1.Vector3(0, 0, -1)));
                                        const moveDistance = -Math.abs(masterPart.position.z - this.viewer.slidePlane.position.z) / Math.sin(dirAngle);
                                        const adjustment = dir.multiplyScalar(moveDistance);
                                        if (position.z + adjustment.z < 0) {
                                            position.add(adjustment);
                                        }
                                    }
                                }
                                masterPart.setPosition(position);
                            }
                            this.roundPosition2Step();
                            this.mainTool.snapHelper.snapObjectByBoundingBox(masterPart, tempVector.copy(masterPart.position).sub(oldPos));
                            collided = this.mainTool.snapHelper.try2SnapObjByBoxCenter(masterPart, masterPart.directionNormal).collided;
                        }
                        const bbox = utils_1.Utils.getAABBox(masterPart);
                        const bboxSize = new three_1.Vector3();
                        bbox.getSize(bboxSize);
                        if ((0, utils_3d_1.areNotEqual)(masterPart.directionNormal.y, 0)) {
                            if (!this.mainTool.copyInProgress) {
                                masterPart.setPositionX(masterPart.position.x - bboxSize.x / 2);
                                masterPart.setPositionZ(masterPart.position.z + bboxSize.z / 2);
                            }
                            collided = this.mainTool.snapHelper.try2SnapObjByBoxCenter(masterPart, masterPart.directionNormal).collided;
                        }
                    }
                    else {
                        needToHide = true;
                    }
                }
                else {
                    masterPart.setPosition(this.intersection.sub(this.offset).applyMatrix4(this.inverseMatrix));
                    collided = this.mainTool.snapHelper.try2SnapObjByBoxCenter(masterPart, masterPart.directionNormal).collided;
                }
                if (collided && this.mainTool.copyInProgress) {
                    collided = this.mainTool.isOnlyOneCopyingPart();
                }
                if (collided) {
                    masterPart.setPosition(oldPos);
                    masterPart.setRotationFromEuler(oldRotation);
                }
                else if (needToHide) {
                    // eslint-disable-next-line no-param-reassign
                    masterPart.visible = false;
                }
                else {
                    // eslint-disable-next-line no-param-reassign
                    masterPart.neighborFaceDirection = consts_1.consts.AXES.negativeZ;
                    // eslint-disable-next-line no-param-reassign
                    masterPart.visible = true;
                }
            }
            if (this.notMasterObjects.length) {
                this.notMasterObjects.forEach((object) => {
                    var _a;
                    if (object.copyInfo) {
                        object.setPosition(masterPart.position.clone().sub((_a = object.copyInfo) === null || _a === void 0 ? void 0 : _a.shiftToMasterPart));
                    }
                });
            }
            if (this.masterObject) {
                this.mainTool.snappingTool.snappedRotation = this.masterObject.rotation.clone();
            }
            return;
        }
        this.intersections.length = 0;
        if (this.objects.length) {
            this.raycaster.setFromCamera(this.mouse, this.camera);
            this.raycaster.intersectObjects(this.objects, true, this.intersections);
        }
        if (this.intersections.length > 0) {
            let meshIntersections = [];
            for (let i = 0; i < this.surfaceMeshes.length; i += 1) {
                meshIntersections = this.meshRaycaster.intersectObject(this.surfaceMeshes[i], true);
            }
            const [meshIntersection] = meshIntersections;
            const distanceToMesh = meshIntersection && meshIntersection.distance;
            const distanceToPoint = this.intersections[0].distance;
            if (distanceToMesh - distanceToPoint < 0) {
                this.dispatchHoverOff();
                return;
            }
            const { object } = this.intersections[0];
            this.plane.setFromNormalAndCoplanarPoint(this.camera.getWorldDirection(this.plane.normal), this.worldPosition.setFromMatrixPosition(object.matrixWorld));
            if (this.hovered !== object) {
                this.dispatchEvent({
                    type: 'hoveron',
                    object,
                    mouse: event,
                });
                const currentState = this.viewer.getControls().getCurrentState();
                if (currentState !== 0 && currentState !== 2) {
                    // Do not change on rotate and pan
                    this.htmlElement.style.cursor = 'pointer';
                }
                this.hovered = object;
            }
        }
        else {
            this.dispatchHoverOff();
        }
    }
    roundPosition2Step() {
        const step = this.viewer.isImperialMeasurementUsed ? consts_1.consts.DEFAULT_EXTRUDE_STEP : consts_1.consts.DEFAULT_EXTRUDE_STEP_SI;
        this.objects.forEach((object) => {
            object.setPosition(object.position.clone().divideScalar(step).round().multiplyScalar(step));
        });
    }
    dispatchHoverOff() {
        if (this.hovered) {
            this.dispatchEvent({ type: 'hoveroff', object: this.hovered });
            const currentState = this.viewer.getControls().getCurrentState();
            if (currentState !== 0 && currentState !== 2) {
                // Do not change on rotate and pan
                this.htmlElement.style.cursor = 'auto';
            }
            this.hovered = null;
        }
    }
    onDocumentMouseDown(event) {
        event.preventDefault();
        if (event.button !== 0) {
            return;
        }
        const snappingObject = this.viewer.getDesignProMain().snappingTool.getObject();
        if (snappingObject) {
            if (this.raycaster.ray.intersectPlane(this.plane, this.intersection)) {
                this.inverseMatrix.copy(snappingObject.matrixWorld);
                this.inverseMatrix.invert();
                this.offset.copy(this.intersection).sub(this.worldPosition.setFromMatrixPosition(snappingObject.matrixWorld));
            }
            this.htmlElement.style.cursor = 'move';
            this.dispatchEvent({
                type: 'dragstart',
                object: snappingObject,
                mouse: event,
            });
        }
        else {
            this.objects = [];
        }
    }
    onDocumentMouseCancel(event) {
        event.preventDefault();
        if (!this.endDraggingOnMouseCancel) {
            return;
        }
        if (event.button !== 0) {
            return;
        }
        if (this.objects.length) {
            this.objects.forEach((object) => {
                // eslint-disable-next-line no-param-reassign
                object.children[0].material.transparent = false;
                // eslint-disable-next-line no-param-reassign
                object.children[0].material.opacity = 1;
            });
            this.dispatchEvent({ type: 'dragend', objects: this.objects });
            this.objects = [];
        }
        this.htmlElement.style.cursor = this.hovered ? 'pointer' : 'auto';
    }
    onDocumentTouchMove(event) {
        event.preventDefault();
        const currentEvent = event.changedTouches[0];
        const rect = this.htmlElement.getBoundingClientRect();
        this.mouse.x = ((currentEvent.clientX - rect.left) / rect.width) * 2 - 1;
        this.mouse.y = -((currentEvent.clientY - rect.top) / rect.height) * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, this.camera);
        if (this.objects.length && this.masterObject) {
            if (this.raycaster.ray.intersectPlane(this.plane, this.intersection)) {
                this.masterObject.setPosition(this.intersection.sub(this.offset).applyMatrix4(this.inverseMatrix));
            }
            this.notMasterObjects.forEach((object) => {
                var _a;
                if (object.copyInfo) {
                    object.setPosition(this.masterObject.position.clone().sub((_a = object.copyInfo) === null || _a === void 0 ? void 0 : _a.shiftToMasterPart));
                }
            });
            this.dispatchEvent({ type: 'drag', objects: this.objects });
        }
    }
    onDocumentTouchStart(event) {
        event.preventDefault();
        const currentEvent = event.changedTouches[0];
        const rect = this.htmlElement.getBoundingClientRect();
        this.mouse.x = ((currentEvent.clientX - rect.left) / rect.width) * 2 - 1;
        this.mouse.y = -((currentEvent.clientY - rect.top) / rect.height) * 2 + 1;
        this.intersections.length = 0;
        this.raycaster.setFromCamera(this.mouse, this.camera);
        this.raycaster.intersectObjects(this.objects, true, this.intersections);
        if (this.intersections.length && this.masterObject) {
            this.plane.setFromNormalAndCoplanarPoint(this.camera.getWorldDirection(this.plane.normal), this.worldPosition.setFromMatrixPosition(this.masterObject.matrixWorld));
            if (this.raycaster.ray.intersectPlane(this.plane, this.intersection)) {
                this.inverseMatrix.copy(this.masterObject.parent.matrixWorld);
                this.inverseMatrix.invert();
                this.offset
                    .copy(this.intersection)
                    .sub(this.worldPosition.setFromMatrixPosition(this.masterObject.matrixWorld));
            }
            this.htmlElement.style.cursor = 'move';
            this.dispatchEvent({ type: 'dragstart', objects: this.objects });
        }
    }
    onDocumentTouchEnd(event) {
        event.preventDefault();
        if (this.objects.length) {
            this.dispatchEvent({ type: 'dragend', objects: this.objects });
            this.objects = [];
        }
        this.htmlElement.style.cursor = 'auto';
    }
    activate() {
        this.htmlElement.addEventListener('mousemove', this.onDocumentMouseMove.bind(this), false);
        this.htmlElement.addEventListener('mousedown', this.onDocumentMouseDown.bind(this), false);
        this.htmlElement.addEventListener('mouseup', this.onDocumentMouseCancel.bind(this), false);
        this.htmlElement.addEventListener('mouseleave', this.onDocumentMouseCancel.bind(this), false);
        this.htmlElement.addEventListener('touchmove', this.onDocumentTouchMove.bind(this), false);
        this.htmlElement.addEventListener('touchstart', this.onDocumentTouchStart.bind(this), false);
        this.htmlElement.addEventListener('touchend', this.onDocumentTouchEnd.bind(this), false);
    }
    deactivate() {
        this.htmlElement.removeEventListener('mousemove', this.onDocumentMouseMove, false);
        this.htmlElement.removeEventListener('mousedown', this.onDocumentMouseDown, false);
        this.htmlElement.removeEventListener('mouseup', this.onDocumentMouseCancel, false);
        this.htmlElement.removeEventListener('mouseleave', this.onDocumentMouseCancel, false);
        this.htmlElement.removeEventListener('touchmove', this.onDocumentTouchMove, false);
        this.htmlElement.removeEventListener('touchstart', this.onDocumentTouchStart, false);
        this.htmlElement.removeEventListener('touchend', this.onDocumentTouchEnd, false);
    }
    setObjects(objects) {
        this.objects = objects;
        // (this.selected.children[0] as any).material.transparent = true;
        // (this.selected.children[0] as any).material.opacity = 0.75;
        // will be fixed later
        this.dispatchEvent({
            type: 'dragstart',
            objects,
        });
    }
    getObjects() {
        return this.objects;
    }
    dispose() {
        this.deactivate();
        this.objects = [];
    }
    setPositionShift(positionShift) {
        this.positionShift = positionShift;
    }
    isDraggingObject(object) {
        return this.objects.indexOf(object) !== -1;
    }
}
exports.DragControls = DragControls;
