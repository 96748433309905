"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const effects_1 = require("redux-saga/effects");
const consts_1 = require("./consts");
const selectWidgets = (state, widgetName) => state.widgets.widgets[widgetName];
function* persistWidgetState({ payload: { widgetName } }) {
    if (widgetName in consts_1.PERSISTENT_WIDGETS) {
        yield (0, effects_1.delay)(1000);
        const widgetShape = yield (0, effects_1.select)(selectWidgets, widgetName);
        yield localStorage.setItem(`${widgetName}-shape`, JSON.stringify(widgetShape));
    }
}
function* rootSaga() {
    yield (0, effects_1.all)([(0, effects_1.takeLatest)([consts_1.WIDGET_SIZE, consts_1.WIDGET_POSITION], persistWidgetState)]);
}
exports.default = rootSaga;
