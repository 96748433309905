"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable import/no-cycle */
const three_1 = require("three");
const utils_3d_1 = require("../utils/utils.3d");
class SlicingTool {
    constructor(object) {
        this.object = object;
        this.cutPlanes = [];
    }
    get clipMesh() {
        return this.slicedMesh;
    }
    /**
     * @description Cut an object with planes
     *
     * @param planes Cutting planes (in world space)
     */
    cutWithPlanes(planes) {
        this.cutPlanes = planes.map((plane) => plane.clone());
        this.object.slicePlanes = this.cutPlanes.map((plane) => {
            return Object.assign({}, plane);
        });
        this.cutObjectMesh();
        this.cutObjectOuterAndCollissionBoxes();
        this.cutObjectSelectionBox();
    }
    resetClipMesh() {
        this.slicedMesh = undefined;
    }
    cutObjectMesh() {
        if (!this.cutPlanes.length) {
            return;
        }
        const worldToLocalMatrix = this.object.matrixWorld.clone().invert();
        let newGeometry = this.object.mesh.geometry;
        this.cutPlanes.forEach((cutPlane) => {
            const cutPlaneInLocalSpace = cutPlane.clone().applyMatrix4(worldToLocalMatrix);
            newGeometry = (0, utils_3d_1.sliceGeometryByPlane)(cutPlaneInLocalSpace, newGeometry);
        });
        this.object.remove(...this.object.children.filter((child) => { var _a; return ((_a = this.slicedMesh) === null || _a === void 0 ? void 0 : _a.uuid) === child.uuid; }));
        const mesh = new three_1.Mesh(newGeometry, this.object.mesh.material);
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        mesh.name = `TempSliced|${this.object.uuid}`;
        mesh.position.set(0, 0, 0);
        mesh.rotation.set(0, 0, 0);
        this.object.add(mesh);
        this.object.mesh.visible = false;
        this.slicedMesh = mesh;
    }
    cutObjectOuterAndCollissionBoxes() {
        if (!this.cutPlanes.length) {
            return;
        }
        const outerBox = (0, utils_3d_1.createOuterBox)(this.object);
        outerBox.updateMatrixWorld();
        const collisionBox = (0, utils_3d_1.createCollisionBox)(this.object);
        collisionBox.updateMatrixWorld();
        const worldToLocalMatrix = this.object.outerBox.matrixWorld.clone().invert();
        this.object.outerBox.geometry = outerBox.geometry;
        this.object.collisionBox.geometry = collisionBox.geometry;
        this.cutPlanes.forEach((cutPlane) => {
            const cutPlaneInLocalSpace = cutPlane.clone().applyMatrix4(worldToLocalMatrix);
            this.object.outerBox.geometry = (0, utils_3d_1.sliceGeometryByPlane)(cutPlaneInLocalSpace, this.object.outerBox.geometry);
            this.object.collisionBox.geometry = (0, utils_3d_1.sliceGeometryByPlane)(cutPlaneInLocalSpace, this.object.collisionBox.geometry);
        });
    }
    cutObjectSelectionBox() {
        const worldToLocalMatrix = this.object.selectionBox.matrixWorld.clone().invert();
        const localCutPlanes = this.cutPlanes.map((cutPlane) => cutPlane.clone().applyMatrix4(worldToLocalMatrix));
        this.object.selectionBox.cutWithPlanes(localCutPlanes);
    }
}
exports.default = SlicingTool;
