"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INCLUDED = exports.UPDATE_SELECTED_RECOMMENDED_FASTENER = exports.REQUEST_DELETE_RECOMMENDED_FASTENERS_SUCCESS = exports.REQUEST_DELETE_RECOMMENDED_FASTENERS_FAIL = exports.REQUEST_DELETE_RECOMMENDED_FASTENERS = exports.REQUEST_UPDATE_RECOMMENDED_FASTENERS_SUCCESS = exports.REQUEST_UPDATE_RECOMMENDED_FASTENERS_FAIL = exports.REQUEST_UPDATE_RECOMMENDED_FASTENERS = exports.REQUEST_RECOMMENDED_FASTENERS_FAIL = exports.REQUEST_RECOMMENDED_FASTENERS_SUCCESS = exports.REQUEST_RECOMMENDED_FASTENERS = void 0;
exports.REQUEST_RECOMMENDED_FASTENERS = 'REQUEST_RECOMMENDED_FASTENERS';
exports.REQUEST_RECOMMENDED_FASTENERS_SUCCESS = 'REQUEST_RECOMMENDED_FASTENERS_SUCCESS';
exports.REQUEST_RECOMMENDED_FASTENERS_FAIL = 'REQUEST_RECOMMENDED_FASTENERS_FAIL';
exports.REQUEST_UPDATE_RECOMMENDED_FASTENERS = 'REQUEST_UPDATE_RECOMMENDED_FASTENERS';
exports.REQUEST_UPDATE_RECOMMENDED_FASTENERS_FAIL = 'REQUEST_UPDATE_RECOMMENDED_FASTENERS_FAIL';
exports.REQUEST_UPDATE_RECOMMENDED_FASTENERS_SUCCESS = 'REQUEST_UPDATE_RECOMMENDED_FASTENERS_SUCCESS';
exports.REQUEST_DELETE_RECOMMENDED_FASTENERS = 'REQUEST_DELETE_RECOMMENDED_FASTENERS';
exports.REQUEST_DELETE_RECOMMENDED_FASTENERS_FAIL = 'REQUEST_DELETE_RECOMMENDED_FASTENERS_FAIL';
exports.REQUEST_DELETE_RECOMMENDED_FASTENERS_SUCCESS = 'REQUEST_DELETE_RECOMMENDED_FASTENERS_SUCCESS';
exports.UPDATE_SELECTED_RECOMMENDED_FASTENER = 'UPDATE_SELECTED_RECOMMENDED_FASTENER';
exports.INCLUDED = 'Included';
