"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const consts_1 = require("~/consts/consts");
const types_1 = require("~/store/types");
function isMachiningServiceApplicableToBOMItem(machiningService, item) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (!item.manuallyAdded) {
        return false;
    }
    const alreadyAppliedMS = (_a = item.services) === null || _a === void 0 ? void 0 : _a.filter((service) => service.id === machiningService.id);
    if (alreadyAppliedMS && alreadyAppliedMS.length > 0) {
        return false;
    }
    switch (machiningService.machiningServiceType.name) {
        case consts_1.MACHINING_SERVICE.MISCELLANEOUS_MACHINING: {
            return item.type === types_1.ITEM_TYPE.EXTRUSION;
        }
        case consts_1.MACHINING_SERVICE.DRILL_ACCESS_HOLE:
        case consts_1.MACHINING_SERVICE.COUNTER_BORE_WITH_THROUGH_HOLE:
        case consts_1.MACHINING_SERVICE.FURNITURE_STYLE_DOOR_HANDLE_MILLING_SERVICE:
        case consts_1.MACHINING_SERVICE.DELUXE_DOOR_HANDLE_MILLING_SERVICE:
        case consts_1.MACHINING_SERVICE.ANCHOR_FASTENER_COUNTERBORE:
        case consts_1.MACHINING_SERVICE.BUTT_FASTENER_COUNTERBORE:
        case consts_1.MACHINING_SERVICE.BOLT_CONNECTOR_MACHINING:
        case consts_1.MACHINING_SERVICE.CENTRAL_CONNECTOR_MACHINING: {
            if (item.type !== types_1.ITEM_TYPE.EXTRUSION) {
                return false;
            }
            const itemSeriesIds = (_b = item.series) === null || _b === void 0 ? void 0 : _b.map((s) => s.id);
            const msSeriesIds = (_c = machiningService.series) === null || _c === void 0 ? void 0 : _c.map((s) => s.id);
            const seriesIntersection = itemSeriesIds === null || itemSeriesIds === void 0 ? void 0 : itemSeriesIds.filter((itemSeries) => msSeriesIds === null || msSeriesIds === void 0 ? void 0 : msSeriesIds.includes(itemSeries));
            return seriesIntersection ? seriesIntersection.length > 0 : false;
        }
        case consts_1.MACHINING_SERVICE.TAP_PROFILE_END:
        case consts_1.MACHINING_SERVICE.MITER_SAW_CUT:
        case consts_1.MACHINING_SERVICE.MITER_SAW_CUTS_AND_COUNTERBORE: {
            if (item.type !== types_1.ITEM_TYPE.EXTRUSION) {
                return false;
            }
            const itemExtrusionProfileTypeId = (_d = item.extrusionType) === null || _d === void 0 ? void 0 : _d.extrusionProfileTypeId;
            const foundProfileType = (_e = machiningService.extrusionProfileTypes) === null || _e === void 0 ? void 0 : _e.find((profileType) => profileType.id === itemExtrusionProfileTypeId);
            return !!foundProfileType;
        }
        case consts_1.MACHINING_SERVICE.PANEL_NOTCHING: {
            return ((_f = item.componentType) === null || _f === void 0 ? void 0 : _f.name) === types_1.ITEM_TYPE.PANEL;
        }
        case consts_1.MACHINING_SERVICE.PLASTIC_PANEL_MACHINING_CLEARANCE_HOLE:
        case consts_1.MACHINING_SERVICE.PLASTIC_PANEL_MACHINING_COUNTERSINK_HOLE: {
            if (((_g = item.componentType) === null || _g === void 0 ? void 0 : _g.name) !== types_1.ITEM_TYPE.PANEL) {
                return false;
            }
            const plasticPanelCategory = (_h = item.categories) === null || _h === void 0 ? void 0 : _h.filter((category) => category.name === consts_1.PLASTIC_PANELS);
            return plasticPanelCategory ? plasticPanelCategory.length > 0 : false;
        }
        default: {
            return false;
        }
    }
}
exports.default = isMachiningServiceApplicableToBOMItem;
