"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCompatibleExtrusionsForTapMachiningService = exports.getIncludedCompatibleFasteners = exports.getCatalogMeta = exports.getCatalogMachiningServices = exports.getCatalog = void 0;
const axiosInstance_1 = require("./axiosInstance");
const getCatalog = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axiosInstance_1.axiosInstance.get(`/catalog/full`);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
    return null;
});
exports.getCatalog = getCatalog;
const getCatalogMachiningServices = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axiosInstance_1.axiosInstance.get(`/catalog/machiningServices`);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
    return null;
});
exports.getCatalogMachiningServices = getCatalogMachiningServices;
const getCatalogMeta = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axiosInstance_1.axiosInstance.get(`/catalog/meta`);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
    return null;
});
exports.getCatalogMeta = getCatalogMeta;
const getIncludedCompatibleFasteners = (itemId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axiosInstance_1.axiosInstance.post(`/catalog/includedCompatibleFasteners`, { itemId });
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
    return null;
});
exports.getIncludedCompatibleFasteners = getIncludedCompatibleFasteners;
const getCompatibleExtrusionsForTapMachiningService = (size) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axiosInstance_1.axiosInstance.post(`/catalog/compatibleExtrusions`, {
            size,
        });
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
    return [];
});
exports.getCompatibleExtrusionsForTapMachiningService = getCompatibleExtrusionsForTapMachiningService;
