"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const three_1 = require("three");
class ScaleDependentObject extends three_1.Object3D {
    constructor(mesh, camera, objectSizeMultiplier = 1) {
        super();
        this.camera = camera;
        this.objectSizeMultiplier = objectSizeMultiplier;
        this.add(mesh);
    }
    calcScaleMultiplier() {
        let factor;
        const objectWorldPosition = this.getWorldPosition(new three_1.Vector3());
        // Copy pasted code from TransformControlInitial.updateMatrixWorld(1121), need to refactor
        if (this.camera.isPerspectiveCamera) {
            factor =
                objectWorldPosition.distanceTo(this.camera.getWorldPosition(new three_1.Vector3())) *
                    Math.min((1.9 * Math.tan((Math.PI * this.camera.fov) / 360)) / this.camera.zoom, 7);
        }
        return factor ? (factor * this.objectSizeMultiplier) / 4 : null;
    }
    updateMatrixWorld(force = false) {
        const scaleMultiplier = this.calcScaleMultiplier();
        if (scaleMultiplier) {
            this.scale.set(1, 1, 1).multiplyScalar(scaleMultiplier);
        }
        super.updateMatrixWorld(force);
    }
}
exports.default = ScaleDependentObject;
