"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resendInvitation = exports.inviteTeamMember = exports.setUserDiscounts = exports.getUserDiscounts = exports.updateDistributorInfoAction = exports.updateUserInfoAction = exports.responseErrors = exports.transferOwnership = exports.deleteUser = exports.setUserDataSuccess = exports.setUserAddressSuccess = exports.setDeleteUserSuccess = exports.setUserDataAction = void 0;
const typesafe_actions_1 = require("typesafe-actions");
const consts_1 = require("./consts");
exports.setUserDataAction = (0, typesafe_actions_1.createAction)(consts_1.SET_USER_DATA)();
exports.setDeleteUserSuccess = (0, typesafe_actions_1.createAction)(consts_1.DELETE_USER_SUCCESS)();
exports.setUserAddressSuccess = (0, typesafe_actions_1.createAction)(consts_1.SET_USER_ADDRESS)();
exports.setUserDataSuccess = (0, typesafe_actions_1.createAction)(consts_1.SET_USER_DATA_SUCCESS)();
exports.deleteUser = (0, typesafe_actions_1.createAction)(consts_1.DELETE_USER)();
exports.transferOwnership = (0, typesafe_actions_1.createAction)(consts_1.TRANSFER_OWNERSHIP)();
exports.responseErrors = (0, typesafe_actions_1.createAction)(consts_1.RESPONSE_ERRORS)();
exports.updateUserInfoAction = (0, typesafe_actions_1.createAction)(consts_1.UPDATE_USER_INFO)();
exports.updateDistributorInfoAction = (0, typesafe_actions_1.createAction)(consts_1.UPDATE_DISTRIBUTOR_INFO)();
exports.getUserDiscounts = (0, typesafe_actions_1.createAction)(consts_1.GET_USER_DISCOUNTS)();
exports.setUserDiscounts = (0, typesafe_actions_1.createAction)(consts_1.SET_USER_DISCOUNTS)();
exports.inviteTeamMember = (0, typesafe_actions_1.createAction)(consts_1.INVITE_TEAM_MEMBER)();
exports.resendInvitation = (0, typesafe_actions_1.createAction)(consts_1.RESEND_INVITATION)();
