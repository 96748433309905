import { Vector3, Color } from 'three';

/**
 * @author mrdoob / http://mrdoob.com/
 * @author alteredq / http://alteredqualia.com/
 */

function Face3(a, b, c, normal, color, materialIndex) {
  this.a = a;
  this.b = b;
  this.c = c;

  this.normal = normal && normal.isVector3 ? normal : new Vector3();
  this.vertexNormals = Array.isArray(normal) ? normal : [];

  this.color = color && color.isColor ? color : new Color();
  this.vertexColors = Array.isArray(color) ? color : [];

  this.materialIndex = materialIndex !== undefined ? materialIndex : 0;
}

Object.assign(Face3.prototype, {
  clone() {
    return new this.constructor().copy(this);
  },

  copy(source) {
    this.a = source.a;
    this.b = source.b;
    this.c = source.c;

    this.normal.copy(source.normal);
    this.color.copy(source.color);

    this.materialIndex = source.materialIndex;

    for (let i = 0, il = source.vertexNormals.length; i < il; i += 1) {
      this.vertexNormals[i] = source.vertexNormals[i].clone();
    }

    for (let i = 0, il = source.vertexColors.length; i < il; i += 1) {
      this.vertexColors[i] = source.vertexColors[i].clone();
    }

    return this;
  },
});

// eslint-disable-next-line import/prefer-default-export
export { Face3 };
