"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetConnectionFasteners = exports.requestFastenersExistenceSuccess = exports.setCurrentConnectionFasteners = exports.requestDefaultFastenersFail = exports.requestDefaultFastenersSuccess = void 0;
const typesafe_actions_1 = require("typesafe-actions");
const consts_1 = require("./consts");
exports.requestDefaultFastenersSuccess = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_DEFAULT_FASTENERS_SUCCESS)();
exports.requestDefaultFastenersFail = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_DEFAULT_FASTENERS_FAIL)();
exports.setCurrentConnectionFasteners = (0, typesafe_actions_1.createAction)(consts_1.SET_CURRENT_CONNECTION_FASTENERS)();
exports.requestFastenersExistenceSuccess = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_FASTENERS_EXISTENCE_SUCCESS)();
exports.resetConnectionFasteners = (0, typesafe_actions_1.createAction)(consts_1.RESET_CONNECTION_FASTENERS)();
