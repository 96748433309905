"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getProjectId() {
    const path = window.location.pathname;
    const progectIdRegex = /\/projects\/(\d+)/;
    const match = path.match(progectIdRegex);
    if (match) {
        const id = match[1];
        return Number(id);
    }
}
exports.default = getProjectId;
