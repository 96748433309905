"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setEnabled = exports.setDisabled = exports.resetQuote = exports.updateQuoteError = exports.updateQuoteValue = exports.submitQuoteError = exports.submitQuoteSuccess = exports.submitQuote = void 0;
const typesafe_actions_1 = require("typesafe-actions");
const consts_1 = require("./consts");
exports.submitQuote = (0, typesafe_actions_1.createAction)(consts_1.SUBMIT_QUOTE)();
exports.submitQuoteSuccess = (0, typesafe_actions_1.createAction)(consts_1.SUBMIT_QUOTE_SUCCESS)();
exports.submitQuoteError = (0, typesafe_actions_1.createAction)(consts_1.SUBMIT_QUOTE_ERROR)();
exports.updateQuoteValue = (0, typesafe_actions_1.createAction)(consts_1.UPDATE_QUOTE_VALUE)();
exports.updateQuoteError = (0, typesafe_actions_1.createAction)(consts_1.UPDATE_QUOTE_ERROR)();
exports.resetQuote = (0, typesafe_actions_1.createAction)(consts_1.RESET_QUOTE)();
exports.setDisabled = (0, typesafe_actions_1.createAction)(consts_1.SET_DIASBLED)();
exports.setEnabled = (0, typesafe_actions_1.createAction)(consts_1.SET_UNABLED)();
