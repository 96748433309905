"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setErrorStatusCode = exports.sendChangeEmailLink = exports.sendRestorePasswordLink = exports.handleAuthResponse = exports.setAuthData = exports.validateTokenAction = exports.initialRequest = exports.signupFailed = exports.setActionStatus = exports.loginFailed = exports.loginSuccess = exports.setMode = exports.signup = exports.logout = exports.login = void 0;
const typesafe_actions_1 = require("typesafe-actions");
const consts_1 = require("./consts");
exports.login = (0, typesafe_actions_1.createAction)(consts_1.LOGIN)();
exports.logout = (0, typesafe_actions_1.createAction)(consts_1.LOGOUT)();
exports.signup = (0, typesafe_actions_1.createAction)(consts_1.SIGNUP)();
exports.setMode = (0, typesafe_actions_1.createAction)(consts_1.SETMODE)();
exports.loginSuccess = (0, typesafe_actions_1.createAction)(consts_1.LOGIN_SUCCESS)();
exports.loginFailed = (0, typesafe_actions_1.createAction)(consts_1.LOGIN_FAILED)();
exports.setActionStatus = (0, typesafe_actions_1.createAction)(consts_1.ACTION_FAILED)();
exports.signupFailed = (0, typesafe_actions_1.createAction)(consts_1.SIGNUP_FAILED)();
exports.initialRequest = (0, typesafe_actions_1.createAction)(consts_1.INITIAL_REQUEST)();
exports.validateTokenAction = (0, typesafe_actions_1.createAction)(consts_1.VALIDATE_TOKEN)();
exports.setAuthData = (0, typesafe_actions_1.createAction)(consts_1.SET_AUTH_DATA)();
exports.handleAuthResponse = (0, typesafe_actions_1.createAction)(consts_1.HANDLE_AUTH_RESPONSE)();
exports.sendRestorePasswordLink = (0, typesafe_actions_1.createAction)(consts_1.SEND_RESTORE_PASSWORD_LINK)();
exports.sendChangeEmailLink = (0, typesafe_actions_1.createAction)(consts_1.SEND_CHANGE_EMAIL_LINK)();
exports.setErrorStatusCode = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_FAILED_STATUS_CODE)();
