"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const effects_1 = require("redux-saga/effects");
const events_1 = require("~/utils/events");
const utils_1 = require("~/utils/utils");
const api_1 = require("../api");
const actions_1 = require("./actions");
const consts_1 = require("./consts");
const selectors_1 = require("../selectors");
const actions_2 = require("../actions");
const consts_2 = require("../account/consts");
function* inviteCustomer({ payload }) {
    var _a;
    try {
        const response = yield (0, effects_1.call)(api_1.createAndAddCustomer, payload);
        const user = yield (0, effects_1.select)(selectors_1.selectCurrentUser);
        if (response.status === 'success') {
            yield (0, effects_1.call)(events_1.showNotification, {
                message: `${payload.firstName} ${payload.lastName} customer has been successfully invited and added to your list`,
                type: 'success',
            });
            if (user.distributorId || ((_a = user.userType) === null || _a === void 0 ? void 0 : _a.name) === consts_2.UserRoles.admin) {
                const currentConnectedCustomersToUser = yield (0, effects_1.select)(selectors_1.selectConnectedCustomers);
                yield (0, effects_1.put)((0, actions_2.updateCustomerData)([...currentConnectedCustomersToUser, response.customer]));
                yield (0, effects_1.call)(events_1.dispatchCustomerIdChangeEvent, { customer: response.customer });
            }
            yield (0, effects_1.put)((0, actions_1.setCustomerRequestSuccess)(true));
        }
        else if (response.status === 'userExist') {
            yield (0, effects_1.put)((0, actions_1.setCustomerExist)(true));
            yield (0, effects_1.put)((0, actions_1.setCustomerData)(response.data.customer));
            yield (0, effects_1.put)((0, actions_1.setCustomerRequestSuccess)(false));
        }
        else {
            yield (0, effects_1.put)((0, actions_1.setCustomerRequestSuccess)(false));
            yield (0, effects_1.call)(events_1.showNotification, {
                message: response.message || (0, utils_1.errorify)(response.error),
                type: 'error',
            });
        }
    }
    catch (error) {
        yield (0, effects_1.call)(events_1.showNotification, {
            message: (0, utils_1.errorify)(error),
            type: 'error',
        });
    }
}
function* addCustomerToDistributor({ payload }) {
    var _a;
    try {
        const response = yield (0, effects_1.call)(api_1.addCustomer, payload);
        const user = yield (0, effects_1.select)(selectors_1.selectCurrentUser);
        if (response.status === 'success') {
            yield (0, effects_1.call)(events_1.showNotification, {
                message: `${payload.firstName} ${payload.lastName} customer has been successfully added to your list`,
                type: 'success',
            });
            yield (0, effects_1.put)((0, actions_1.setCustomerRequestSuccess)(true));
            if (user.distributorId || ((_a = user.userType) === null || _a === void 0 ? void 0 : _a.name) === consts_2.UserRoles.admin) {
                const currentConnectedCustomersToUser = yield (0, effects_1.select)(selectors_1.selectConnectedCustomers);
                yield (0, effects_1.put)((0, actions_2.updateCustomerData)([...currentConnectedCustomersToUser, response.customer]));
                yield (0, effects_1.call)(events_1.dispatchCustomerIdChangeEvent, { customer: response.customer });
            }
        }
        else if (response.status === 'alreadyAdded') {
            yield (0, effects_1.call)(events_1.showNotification, {
                message: `Customer with ${payload.email} email is already in your list`,
                type: 'error',
            });
            yield (0, effects_1.put)((0, actions_1.setCustomerRequestSuccess)(false));
        }
        else {
            yield (0, effects_1.call)(events_1.showNotification, {
                message: response.message || (0, utils_1.errorify)(response.error),
                type: 'error',
            });
            yield (0, effects_1.put)((0, actions_1.setCustomerRequestSuccess)(false));
        }
    }
    catch (error) {
        yield (0, effects_1.call)(events_1.showNotification, {
            message: (0, utils_1.errorify)(error),
            type: 'error',
        });
    }
}
function* getCustomer({ payload }) {
    try {
        const response = yield (0, effects_1.call)(api_1.getCustomerData, payload);
        if (response.status === 'success') {
            const { customer } = response.data;
            const customerData = {
                id: payload,
                firstName: customer.firstName,
                lastName: customer.lastName,
                email: customer.email,
                phoneNumber: customer.phoneNumber,
                companyName: customer.companyName,
                shippingAddresses: [],
                discounts: customer.discounts.map((discountData) => {
                    return {
                        id: discountData.id,
                        extrusionsDiscount: discountData.extrusionsDiscount,
                        componentsDiscount: discountData.componentsDiscount,
                        machiningDiscount: discountData.machiningDiscount,
                        startDate: new Date(discountData.startDate),
                        expirationDate: discountData.expirationDate && new Date(discountData.expirationDate),
                    };
                }),
            };
            yield (0, effects_1.put)((0, actions_1.setCustomerData)(customerData));
        }
        else {
            yield (0, effects_1.call)(events_1.showNotification, {
                message: response.message || (0, utils_1.errorify)(response.error),
                type: 'error',
            });
        }
    }
    catch (error) {
        yield (0, effects_1.call)(events_1.showNotification, {
            message: (0, utils_1.errorify)(error),
            type: 'error',
        });
    }
}
function* updateCustomerData({ payload }) {
    try {
        const response = yield (0, effects_1.call)(api_1.updateCustomer, payload);
        if (response.status === 'success') {
            yield (0, effects_1.put)((0, actions_1.setCustomerRequestSuccess)(true));
            yield (0, effects_1.call)(events_1.showNotification, {
                message: 'Changes to discounts have been successfully saved',
                type: 'success',
            });
        }
        else {
            yield (0, effects_1.put)((0, actions_1.setCustomerRequestSuccess)(false));
            yield (0, effects_1.call)(events_1.showNotification, {
                message: response.message || (0, utils_1.errorify)(response.error),
                type: 'error',
            });
        }
    }
    catch (error) {
        yield (0, effects_1.call)(events_1.showNotification, {
            message: (0, utils_1.errorify)(error),
            type: 'error',
        });
    }
}
function* rootSaga() {
    yield (0, effects_1.all)([(0, effects_1.takeLatest)(consts_1.GET_CUSTOMER, getCustomer)]);
    yield (0, effects_1.all)([(0, effects_1.takeLatest)(consts_1.INVITE_CUSTOMER, inviteCustomer)]);
    yield (0, effects_1.all)([(0, effects_1.takeLatest)(consts_1.ADD_CUSTOMER, addCustomerToDistributor)]);
    yield (0, effects_1.all)([(0, effects_1.takeLatest)(consts_1.UPDATE_CUSTOMER, updateCustomerData)]);
}
exports.default = rootSaga;
