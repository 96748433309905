"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_redux_1 = require("react-redux");
const selectors_1 = require("~/store/selectors");
const types_1 = require("~/store/types");
function useProjectStatus() {
    const currentProject = (0, react_redux_1.useSelector)(selectors_1.selectCurrentProject);
    const currentProjectStatus = currentProject === null || currentProject === void 0 ? void 0 : currentProject.status;
    const viewMode = (0, react_redux_1.useSelector)(selectors_1.selectViewMode);
    const isOrderCompleted = currentProjectStatus === types_1.PROJECT_STATUS.ORDER_ENTERED;
    const isProjectArchived = currentProjectStatus === types_1.PROJECT_STATUS.ARCHIVED;
    const isProjectViewOnly = viewMode === 'view';
    return { isOrderCompleted, isProjectArchived, isProjectViewOnly };
}
exports.default = useProjectStatus;
