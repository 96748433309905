"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadEXCEL = exports.downloadPDF = exports.deleteFasteners = exports.updateFasteners = exports.getFasteners = exports.submitQuote = void 0;
const axiosInstance_1 = require("~/store/api/axiosInstance");
const submitQuote = (quote) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosInstance_1.axiosInstance.post('/quote', quote);
    return response.data;
});
exports.submitQuote = submitQuote;
const getFasteners = (fastener) => {
    return axiosInstance_1.axiosInstance
        .post('/quote/fasteners', { connectionUuid: fastener.uuid, projectId: fastener.projectId })
        .then(({ data }) => data);
};
exports.getFasteners = getFasteners;
const updateFasteners = (updateFastenersData) => {
    return axiosInstance_1.axiosInstance.put('/quote/fasteners', updateFastenersData).then(({ data }) => data);
};
exports.updateFasteners = updateFasteners;
const deleteFasteners = (deleteFastenersData) => {
    return axiosInstance_1.axiosInstance
        .post('/quote/deleteFasteners/', deleteFastenersData)
        .then(({ data }) => data);
};
exports.deleteFasteners = deleteFasteners;
const downloadPDF = (projectId, isometricImage, frontImage, isometricWithBalloonsImage, isImperialMeasurement, exportAsCustomer, includeDiscount) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance.post('/quote/pdf', {
        projectId,
        isometricImage,
        frontImage,
        isometricWithBalloonsImage,
        isImperialMeasurement,
        exportAsCustomer,
        includeDiscount,
    }, { responseType: 'arraybuffer' });
});
exports.downloadPDF = downloadPDF;
const downloadEXCEL = (projectId, isImperialMeasurement, exportAsCustomer, includeDiscount) => __awaiter(void 0, void 0, void 0, function* () {
    return axiosInstance_1.axiosInstance.post('/quote/excel', { projectId, isImperialMeasurement, exportAsCustomer, includeDiscount }, { responseType: 'arraybuffer' });
});
exports.downloadEXCEL = downloadEXCEL;
