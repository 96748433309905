"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const three_1 = require("three");
const consts_1 = require("../consts");
class PanelCube extends three_1.Mesh {
    constructor(cubeSize, panelThickness, shouldHighlight = false) {
        const geometry = new three_1.BoxBufferGeometry(cubeSize, cubeSize, 1.1 * panelThickness);
        geometry.translate(0, 0, -panelThickness / 2);
        super(geometry, new three_1.MeshBasicMaterial());
        this.highlight(shouldHighlight);
    }
    setPosition(newPosition) {
        this.position.copy(newPosition);
    }
    highlight(shouldHighlight) {
        this.material.color.setHex(shouldHighlight ? consts_1.consts.HIGHLIGHTED_PANEL_CUBE_COLOR : consts_1.consts.PANEL_CUBE_COLOR);
    }
}
exports.default = PanelCube;
