"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const three_1 = require("three");
const consts_1 = require("../consts");
const ColoredMetalBaseMaterial = (color) => {
    const rgbColor = new three_1.Color(`rgb(${color})`);
    return new three_1.MeshPhongMaterial({
        color: rgbColor,
        specular: consts_1.consts.WHITE_COLOR,
        shininess: 30,
        side: three_1.DoubleSide,
    });
};
exports.default = ColoredMetalBaseMaterial;
