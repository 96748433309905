"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateSelectedRecommendedFastener = exports.requestDeleteItemProjectFail = exports.requestDeleteItemProjectSuccess = exports.requestDeleteItemProject = exports.requestUpdateItemProjectFail = exports.requestUpdateItemProjectSuccess = exports.requestUpdateItemProject = exports.requestRecommendedFastenersFail = exports.requestRecommendedFastenersSuccess = exports.requestRecommendedFasteners = void 0;
const typesafe_actions_1 = require("typesafe-actions");
const consts_1 = require("./consts");
exports.requestRecommendedFasteners = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_RECOMMENDED_FASTENERS)();
exports.requestRecommendedFastenersSuccess = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_RECOMMENDED_FASTENERS_SUCCESS)();
exports.requestRecommendedFastenersFail = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_RECOMMENDED_FASTENERS_FAIL)();
exports.requestUpdateItemProject = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_UPDATE_RECOMMENDED_FASTENERS)();
exports.requestUpdateItemProjectSuccess = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_UPDATE_RECOMMENDED_FASTENERS_SUCCESS)();
exports.requestUpdateItemProjectFail = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_UPDATE_RECOMMENDED_FASTENERS_FAIL)();
exports.requestDeleteItemProject = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_DELETE_RECOMMENDED_FASTENERS)();
exports.requestDeleteItemProjectSuccess = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_DELETE_RECOMMENDED_FASTENERS_SUCCESS)();
exports.requestDeleteItemProjectFail = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_DELETE_RECOMMENDED_FASTENERS_FAIL)();
exports.updateSelectedRecommendedFastener = (0, typesafe_actions_1.createAction)(consts_1.UPDATE_SELECTED_RECOMMENDED_FASTENER)();
