"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HasDefinedProperty = exports.isDefined = exports.isNotNull = void 0;
function isNotNull(value) {
    return value !== null;
}
exports.isNotNull = isNotNull;
function isDefined(value) {
    return value !== undefined;
}
exports.isDefined = isDefined;
function HasDefinedProperty(value, key) {
    return value[key] !== undefined;
}
exports.HasDefinedProperty = HasDefinedProperty;
